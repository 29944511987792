.identity-verification {
  .identity-verification-container {
    margin: 32px 0;
  }
  .header {
    display: block;
    font-size: $font-size-b1-desktop;
    font-weight: $semi-bold;
    line-height: $line-height;
    @media screen and (max-width: map-get($breakpoints, medium)) {
      font-size: $font-size-b1-tablet;
    }
  }
  .body {
    display: flex;
    flex-direction: column;

    .body-container {
      display: flex;
      @media screen and (max-width: map-get($breakpoints, medium)) {
        flex-direction: column;
      }
    }

    .copy-container {
      width: 554px;
      margin-right: 32px;

      .copy {
        font-size: $font-size-b1-desktop;
        font-weight: $regular;
        line-height: $line-height;
        color: #4e5156;
        @media screen and (max-width: map-get($breakpoints, medium)) {
          line-height: $line-height;
          font-size: $font-size-b1-mobile;
        }
      }
      @media screen and (max-width: map-get($breakpoints, medium)) {
        width: auto;
        margin: 0;
      }
    }

    .identity-link-container {
      min-width: 320px;
      border-radius: 8px;
      background: rgba(255, 255, 255, 0.86);
      box-shadow: 0px 0px 10px rgba(14, 22, 36, 0.08),
        inset 0px 0px 0px 1px rgba(14, 22, 36, 0.08);
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 24px;

      .identity-link {
        cursor: pointer;
        width: fit-content;
        font-size: $font-size-b1-desktop;
        font-weight: $semi-bold;
        display: block;
        color: #077187;
        line-height: $line-height;
        text-align: left;
        text-decoration: underline;
        &:last-child {
          margin-top: 24px;
        }
        @media screen and (max-width: map-get($breakpoints, medium)) {
          font-size: $font-size-b1-mobile;
          text-decoration: none;
          &:last-child {
            margin: 16px 0;
          }
        }
      }
      @media screen and (max-width: map-get($breakpoints, medium)) {
        box-shadow: none;
        background-color: #fff;
        border-radius: 0;
        margin: 8px 0;
        padding: 0;
      }
    }

    .verification-btn {
      display: flex;
      align-items: center;
      & > span {
        font-size: $font-size-h4-desktop;
        font-weight: $semi-bold;
        line-height: $small-line-height;
        @media screen and (max-width: map-get($breakpoints, medium)) {
          font-size: $font-size-h4-mobile;
        }
      }
      & > img {
        margin: 0 16px 0 0;
      }
    }
  }
}

.verification-btn-container {
  margin: 16px 0;
  width: 40%;
  & > button {
    width: 100%;
  }
  @media screen and (max-width: map-get($breakpoints, medium)) {
    width: 100%;
    margin: 0;
    & > button {
      margin: 0;
    }
  }
}
