.order-confirmed {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 4em;
  margin: 50px auto;
  width: 80%;
  img {
    margin: 2rem;
    height: 70px;
    width: 70px;
  }

  .header {
    margin: 1rem;
    font-size: $font-size-h3-desktop;
    font-weight: $bold;
    text-align: center;
    line-height: $line-height;
    @media screen and (max-width: map-get($breakpoints,medium)) {
      font-size: $font-size-h3-tablet;
    }
    @media screen and (max-width: map-get($breakpoints,small)) {
      font-size: $font-size-h3-mobile;
    }

    &.margin {
      margin-bottom: 0rem;
    }
  }
  .info {
    font-size: $font-size-b2-desktop;
    color: map-get(map-get($palette, secondary), 3);
    margin: 1rem 0;
  }

  .status {
    margin: 1rem;
    max-width: 50vw;
    color: map-get(map-get($palette, primary), 1);
    font-weight: $semi-bold;
    text-align: center;
    font-size: $font-size-b1-desktop;
    @media screen and (max-width: map-get($breakpoints,medium)) {
      font-size: $font-size-b1-tablet;
    }
    @media screen and (max-width: map-get($breakpoints,small)) {
      font-size: $font-size-b1-mobile;
    }
  }

  .order {
    color: map-get(map-get($palette, secondary), 3);
    font-weight: $semi-bold;
    font-size: $font-size-b1-desktop;
    cursor: pointer;
    text-decoration: underline;
    margin: 10px 0 20px;
    @media screen and (max-width: map-get($breakpoints,medium)) {
      font-size: $font-size-b1-tablet;
    }
    @media screen and (max-width: map-get($breakpoints,small)) {
      font-size: $font-size-b1-mobile;
    }
  }
}

.order-accounts {
  margin-top: 50px;
  .header {
    font-size: $font-size-h3-desktop;
    text-align: center;
    font-weight: $semi-bold;
    line-height: $title-line-height;
    padding-bottom: 30px;
    @media screen and (max-width: map-get($breakpoints,medium)) {
      font-size: $font-size-h3-tablet;
    }
    @media screen and (max-width: map-get($breakpoints,small)) {
      font-size: $font-size-h3-mobile;
    }
  }
}
