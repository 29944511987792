.contact-container {
  display: flex;
  flex-direction: column;
  min-height: 90vh;
  .info-group {
    margin-top: 30px;
    width: 100%;
    display: flex;
    flex-direction: row;
    @media screen and (max-width: map-get($breakpoints,small)) {
      flex-direction: column;
      padding: 0;
      margin-top: 10px;
    }
    .info {
      width: 50%;
      padding: 20px 0;
      color: map-get(map-get($palette, primary), 2);
      .location {
        font-size: $font-size-b1-desktop;
        line-height: $line-height;
        font-weight: $semi-bold;
        margin-bottom: 20px;
        @media screen and (max-width: map-get($breakpoints,medium)) {
          font-size: $font-size-b1-tablet;
        }
        @media screen and (max-width: map-get($breakpoints,small)) {
          font-size: $font-size-b1-mobile;
        }
      }
      .sub-text {
        font-size: $font-size-b1-desktop;
        font-weight: $regular;
        color: map-get(map-get($palette, secondary), 2);
        line-height: $line-height;
        @media screen and (max-width: map-get($breakpoints,medium)) {
          font-size: $font-size-b1-tablet;
        }
        @media screen and (max-width: map-get($breakpoints,small)) {
          font-size: $font-size-b1-mobile;
        }
      }
      @media screen and (max-width: map-get($breakpoints,small)) {
        width: 100%;
        padding: 0;
        .location {
          margin-bottom: 10px;
        }
      }
    }
  }
  .contact-form {
    padding: 10px 0;
    .ui.radio.checkbox > label {
      cursor: pointer;
    }
  }
}

.contact-header {
  h1 {
    font-weight: $semi-bold;
    font-size: $font-size-h1-desktop;
    line-height: $title-line-height;
  }
  span {
    font-weight: $regular;
    font-size: $font-size-b1-desktop;
    line-height: $line-height;
    color: map-get(map-get($palette, secondary), 2);
  }
  .underline {
    text-decoration: underline;
    cursor: pointer;
  }
  flex-direction: row;
  @media screen and (max-width: map-get($breakpoints,small)) {
    h1 {
      font-size: $font-size-h1-desktop;
    }
    span {
      font-size: $font-size-b1-mobile;
    }
  }
}
