.end-balance {
  border-top: 1px solid map-get(map-get($palette, secondary), 4);
  min-height: 30px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  &.text {
    font-weight: $semi-bold;
    font-size: $font-size-b2-desktop;
    border: none;
  }
  &.limit {
    border: none;
    font-size: $font-size-input-desktop;
    font-weight: $bold;
  }
}

.err-msg {
  font-size: $font-size-input-desktop;
  font-weight: $semi-bold;
  color: $err-red;
  text-align: left;
}

.select-label {
  font-size: $font-size-b2-desktop;
  font-weight: $semi-bold;
  color: map-get(map-get($palette, secondary), 2);
}

.selector {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  &:hover {
    background: map-get(map-get($palette, secondary), 4);
    cursor: pointer;
  }
  @media screen and (max-width: map-get($breakpoints,medium)) {
    padding: 1rem;
  }
  @media screen and (max-width: map-get($breakpoints,small)) {
    padding: 0.5rem;
  }
  img {
    height: 30px;
  }
}

.option {
  min-height: 10px;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  &-label {
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: $font-size-input-desktop;
    font-weight: $bold;
  }
  &-mask {
    color: darkgray;
    font-size: $font-size-b2-desktop;
    font-weight: $semi-bold;
  }
  @media screen and (max-width: map-get($breakpoints,small)) and (max-width: map-get($breakpoints,medium)) {
    min-height: 30px;
    &-label {
      font-size: $font-size-b3-desktop;
    }
    &-mask {
      font-size: 10px;
    }
  }
}

.balance {
  font-size: $font-size-b3-desktop;
  font-weight: $bold;
  text-align: end;
}

.transfer-modal-field {
  padding: 0.5rem 0;
}

.items-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.verification {
  display: flex;
  flex-direction: column;
  padding: 1.5rem 0 0 0;
  margin: 0;
  .auth-title {
    font-weight: $semi-bold;
  }
  &.review {
    margin-bottom: 1rem;
    padding: 0;
  }

  .subtitle {
    margin-top: 0.1rem;
    margin-bottom: 0.3rem;
    color: map-get(map-get($palette, secondary), 3);
    &.red {
      color: map-get(map-get($palette, error), 1);
    }
  }
}
