.origination-container {
  position: relative;
  .page-header {
    height: 300px;
    border-bottom: 0px;
    width: 100%;
    position: absolute;
    top: 0px;
    @media screen and (max-width: map-get($breakpoints, small)) {
      height: 150px;
    }
    .unicorn-backgeound {
      @media screen and (max-width: map-get($breakpoints, small)) {
        display: block;
        transform: translate(-11%, 0px) scale(0.6);
      }
    }
  }

  .page-header-company-info .page-header-company-logo {
    margin-top: 10px;
    width: 100%;
    @media screen and (max-width: map-get($breakpoints, medium)) {
      flex-direction: row;
      margin-top: 50px;
    }
    @media screen and (max-width: map-get($breakpoints, small)) {
      margin-top: 0px;
    }
    .page-header-company-logo-wrapper {
      @media screen and (max-width: map-get($breakpoints, medium)) {
        justify-content: flex-start;
        min-width: 180px;
        height: 180px;
      }
      @media screen and (max-width: map-get($breakpoints, small)) {
        min-width: 112px;
        height: 112px;
        margin-left: -10px;
        margin-right: -20px;
      }
      .page-header-company-logo-bg {
        @media screen and (max-width: map-get($breakpoints, medium)) {
          width: 150px;
          height: 150px;
        }
        @media screen and (max-width: map-get($breakpoints, small)) {
          width: 78px;
          height: 78px;
        }
        img {
          @media screen and (max-width: map-get($breakpoints, small)) {
            width: 70%;
          }
        }
      }
    }
    .page-header-company-title-container {
      .page-header-company-title {
        @media screen and (max-width: map-get($breakpoints, medium)) {
          text-align: left;
          padding-left: 0px;
        }
        @media screen and (max-width: map-get($breakpoints, small)) {
          text-align: left;
          padding-left: 0px;
          margin-top: 0;
          font-size: $font-size-h2-mobile;
        }
      }
    }
  }

  .sections-container {
    display: flex;
    flex-wrap: wrap;

    .section-wide {
      width: 65%;
      display: flex;
      @media screen and (max-width: map-get($breakpoints, medium)) {
        width: 60%;
      }
      @media screen and (max-width: map-get($breakpoints, small)) {
        width: 100%;
      }
    }

    .section-full {
      width: 100%;
      display: flex;
    }

    .section-nar {
      width: 35%;
      display: flex;
      @media screen and (max-width: map-get($breakpoints, medium)) {
        width: 40%;
      }
      @media screen and (max-width: map-get($breakpoints, small)) {
        width: 100%;
        justify-content: center;
      }
    }
    .origination-detail-logo {
      order: 1;
      min-height: 300px;
      @media screen and (max-width: map-get($breakpoints, small)) {
        min-height: 150px;
        margin-top: 15px;
      }
    }
    .origination-detail-cta {
      order: 4;
      flex-direction: column;

      @media screen and (max-width: map-get($breakpoints, small)) {
        margin: -12px 0px 16px;
      }
      &.detail-not-logged {
        margin-top: 32px;
        @media screen and (max-width: map-get($breakpoints, small)) {
          margin-top: 0px;
        }
      }
      .origination-detail-confirm {
        span {
          color: map-get(map-get($palette, secondary), 2);
          font-size: $font-size-b1-desktop;
          line-height: $line-height;
          margin-top: -6px;
          margin-bottom: 16px;
          display: inline-block;
          @media screen and (max-width: map-get($breakpoints, small)) {
            font-size: $font-size-b1-mobile;
          }
        }
      }
    }
    .origination-detail-offer {
      order: 2;
      @media screen and (max-width: map-get($breakpoints, small)) {
        order: 3;
      }
      &.origination-offer-offer {
        @media screen and (max-width: map-get($breakpoints, small)) {
          order: 2;
        }
        .orginiation-order-box.not-logged-in {
          @media screen and (max-width: map-get($breakpoints, small)) {
            margin-top: 0px;
            margin-bottom: 16px;
          }
        }
      }
    }
    .origination-detail-copy {
      padding: 40px 32px 20px 0;
      order: 3;
      flex-direction: column;
      @media screen and (max-width: map-get($breakpoints, small)) {
        order: 2;
        padding: 16px 0px 0px 0;
      }

      a {
        font-weight: $regular;
        text-decoration: underline;
        color: map-get(map-get($palette, secondary), 2);
      }
      &.copy-not-logged {
        margin-top: -160px;
        @media screen and (max-width: map-get($breakpoints, small)) {
          margin-top: 0px;
        }
      }
      &.copy-confirmed {
        margin-bottom: 150px;
      }
      .content-section-title {
        font-weight: $semi-bold;
        margin-bottom: 24px;
        color: map-get(map-get($palette, primary), 2);
        font-size: $font-size-h2-desktop;
        @media screen and (max-width: map-get($breakpoints, small)) {
          font-size: $font-size-h2-mobile;
        }
      }
      .content-section {
        margin-bottom: 24px;
      }
    }
    .origination-detail-upload-conatiner {
      order: 3;
      flex-direction: column;
      margin-top: 24px;
      @media screen and (max-width: map-get($breakpoints, small)) {
        margin-bottom: 16px;
      }
      .origination-detail-upload {
        display: flex;
        flex-direction: row;
        margin-top: 32px;
        @media screen and (max-width: map-get($breakpoints, small)) {
          flex-direction: column;
          margin-top: 24px;
        }
        .origination-detail-upload-input {
          width: 50%;
          padding-left: 16px;
          @media screen and (max-width: map-get($breakpoints, small)) {
            width: 100%;
            padding-left: 0;
          }
          @media screen and (max-width: map-get($breakpoints, medium)) {
            .dropzone-new {
              width: 100%;
            }
          }
        }

        .origination-detail-upload-copy {
          padding-right: 16px;
          width: 50%;
          @media screen and (max-width: map-get($breakpoints, small)) {
            padding-right: 0;
            margin-bottom: 32px;
            width: 100%;
          }
        }
        .content {
          margin-bottom: 24px;
          &:last-of-type {
            margin-bottom: 0px;
          }
        }
      }
    }
    .origination-detail-documents {
      padding: 0px 32px 20px 0px;
      order: 4;
      @media screen and (max-width: map-get($breakpoints, small)) {
        order: 4;
        padding: 0px;
      }
      a {
        font-weight: $regular;
      }
      .agreements {
        margin-bottom: 0px;
        @media screen and (max-width: map-get($breakpoints, medium)) {
          justify-content: flex-start;
          width: 70%;
        }
        @media screen and (max-width: map-get($breakpoints, small)) {
          margin: 0;
          width: 100%;
        }
        .box {
          box-shadow: 0px 0px 10px rgba(14, 22, 36, 0.08),
            inset 0px 0px 0px 1px rgba(14, 22, 36, 0.08);
          margin-bottom: 16px;
        }
      }
    }
  }

  .page-header-company-sub-title {
    font-size: $font-size-h4-desktop;
    font-weight: $semi-bold;
    color: map-get(map-get($palette, secondary), 2);
    @media screen and (max-width: map-get($breakpoints, small)) {
      font-size: $font-size-h4-mobile;
    }
  }

  &.origination-details-container {
    .page-container {
      min-height: auto;
      padding-bottom: 0px;
      padding-top: 0px;
    }
  }
}

.orginiation-order-box {
  background-color: map-get(map-get($palette, primary), 3);
  box-shadow: 0px 0px 10px rgba(14, 22, 36, 0.08),
    inset 0px 0px 0px 1px rgba(14, 22, 36, 0.08);
  border-radius: 16px;
  width: 320px;
  padding: 24px;
  @media screen and (max-width: map-get($breakpoints, small)) {
    width: 100%;
  }

  .offer {
    font-weight: $semi-bold;
    font-size: $font-size-b1-tablet;
    margin-bottom: 16px;
    line-height: $line-height;
    display: block;
    text-align: center;
  }
  .share {
    font-size: $font-size-b1-desktop;
    display: block;
    text-align: center;
    span {
      font-weight: $semi-bold;
      font-size: $font-size-b1-desktop;
    }
  }
  .price {
    font-weight: $semi-bold;
    font-size: $font-size-h2-desktop;
  }
  .price-total {
    font-weight: $semi-bold;
    font-size: $font-size-h1-mobile;
  }
  .share-total {
    font-size: $font-size-b1-desktop;
    display: block;
    text-align: center;
    margin-top: 16px;
    line-height: $line-height;
  }

  &.not-logged-in {
    max-height: 185px;
    margin-top: 54px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media screen and (max-width: map-get($breakpoints, small)) {
      margin-top: 32px;
    }
  }
  &.offer {
    max-height: 196px;
  }
  &.orginiation-order-slider-box {
    margin-top: 52px;
    @media screen and (max-width: map-get($breakpoints, small)) {
      margin-top: 32px;
    }
  }
}
.box-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &.slider-row {
    margin: 24px 0;
    .slider-btn {
      &.minus {
        margin-right: 15px;
      }
      &.plus {
        margin-left: 15px;
      }
    }
  }
  &.shares-row {
    span {
      font-size: $font-size-b1-desktop;
      line-height: $line-height;
      @media screen and (max-width: map-get($breakpoints, medium)) {
        font-size: $font-size-b1-tablet;
      }
    }
  }
  &.selling-row {
    margin-top: 4px;
    span {
      line-height: $line-height;
      font-size: $font-size-b1-desktop;
      color: map-get(map-get($palette, secondary), 2);
      @media screen and (max-width: map-get($breakpoints, medium)) {
        font-size: $font-size-b1-tablet;
      }
    }
  }
  &.total-row {
    margin-top: 16px;
    align-items: flex-end;
    .total-proceeds {
      line-height: 1.1;
      font-weight: $semi-bold;
      font-size: $font-size-b1-desktop;
      @media screen and (max-width: map-get($breakpoints, medium)) {
        font-size: $font-size-b1-tablet;
      }
    }
    .total-price {
      font-weight: $bold;
      font-size: 28px;
      @media screen and (max-width: map-get($breakpoints, medium)) {
        font-size: 20px;
      }
      @media screen and (max-width: map-get($breakpoints, small)) {
        font-size: 28px;
      }
    }
  }
  &.select-row {
    select {
      width: 100%;
      margin-top: 30px;
      option {
        text-transform: capitalize;
      }
    }
  }
}

.origination-modal {
  .content:first-child {
    margin-bottom: 24px;
  }
  .btn.md {
    width: 402px;
    margin-top: 24px;
    @media screen and (max-width: map-get($breakpoints, small)) {
      width: 100%;
    }
  }
}
