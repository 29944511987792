.page-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: map-get($breakpoints,small)) {
    min-height: 228px;
  }

  .page-header-title {
    font-size: $font-size-h1-desktop;
    font-weight: $semi-bold;
    text-align: left;
    line-height: $title-line-height;
    margin-bottom: 2.5rem;
    @media screen and (max-width: map-get($breakpoints,medium)) {
      font-size: $font-size-h1-tablet;
      margin-top: 10px;
    }
    @media screen and (max-width: map-get($breakpoints,small)) {
      font-size: $font-size-h1-mobile;
      margin-top: 10px;
    }
  }

  .page-header-facts {
    display: flex;
    flex-direction: column;
    width: 100%;

    & > div {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (max-width: map-get($breakpoints,small)) {
      flex-direction: row;
      & > div {
        display: flex;
        flex-direction: column;
      }
    }
  }
  .page-header-fact-item {
    text-align: center;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: map-get($breakpoints,small)) {
      height: 46px;
    }
    .page-header-fact-number {
      font-size: $font-size-h3-desktop;
      font-weight: $bold;
      @media screen and (max-width: map-get($breakpoints,medium)) {
        font-size: $font-size-h3-tablet;
      }
      @media screen and (max-width: map-get($breakpoints,small)) {
        font-size: $font-size-h4-desktop;
        margin: 0 0.5rem;
        align-items: center;
        text-align: right;
      }
    }
    .page-header-fact-desc {
      font-size: $font-size-b1-desktop;
      margin-top: 5px;
      @media screen and (max-width: map-get($breakpoints,medium)) {
        padding: 0 13px;
        font-size: $font-size-b1-tablet;
      }
      @media screen and (max-width: map-get($breakpoints,small)) {
        padding: 0 5px;
        font-size: $font-size-b1-mobile;
        text-align: left;
      }
    }
  }
  @media screen and (max-width: map-get($breakpoints,small)) {
    .ui.grid > .row > .column.page-header-fact-item {
      display: flex;
      flex-direction: row;
      padding: 10px 0px 10px 0px !important;
    }
  }
}
.linqto-portfolio-container {
  .page-container {
    padding-top: 40px;
    @media screen and (max-width: map-get($breakpoints,small)) {
      padding-top: 20px;
    }
  }
  .card-container-OPEN {
    a {
      cursor: pointer;
    }
  }

  .card {
    width: 100%;
    height: 130px;
    border-radius: 8px;
    background-color: map-get(map-get($palette, primary), 3);
    padding: 10px;
    margin: 1rem 0;
    transition: 0.3s box-shadow ease;
    display: flex;
    overflow: hidden;
    box-shadow: 0 0 10px 0px rgba(map-get(map-get($palette, primary), 2), 0.08),
      inset 0 0 0 1px rgba(map-get(map-get($palette, primary), 2), 0.08);
    @media screen and (max-width: map-get($breakpoints,medium)) {
      width: 100%;
      height: 110px;
    }
    @media screen and (max-width: map-get($breakpoints,small)) {
      box-shadow: 0 0 10px 5px
        rgba(map-get(map-get($palette, primary), 2), 0.08);
      height: 80px;
      margin: 14px 0;
    }
    &:hover {
      box-shadow: 0 0 30px 0px rgba(map-get(map-get($palette, primary), 2), 0.1),
        inset 0 0 0 1px rgba(map-get(map-get($palette, primary), 2), 0.08);
    }
    &:active {
      transform: scale(0.97);
    }

    img {
      width: 100%;
      height: 100%;
      max-height: 87px;
      max-width: 175px;
      object-fit: contain;
      @media screen and (max-width: map-get($breakpoints,medium)) {
        max-height: 60px;
      }
      @media screen and (max-width: map-get($breakpoints,small)) {
        max-height: 45px;
        max-width: 100%;
      }
    }
    &.CARD-OPEN {
      img {
        margin: auto;
      }
    }
    &.CARD-EXITED,
    &.CARD-EXITING,
    &.CARD-CLOSED {
      flex-direction: column;
      align-items: center;
      @media screen and (max-width: map-get($breakpoints,medium)) {
        justify-content: center;
      }
      img {
        margin: 0px auto;
      }
    }

    .card-status {
      display: flex;
      color: rgba(map-get(map-get($palette, primary), 2), 0.4);
      font-weight: $semi-bold;
      font-size: $font-size-b3-desktop;
      line-height: $line-height;
    }
  }
  .ui.grid > .row {
    padding-top: 0px;
    @media screen and (max-width: map-get($breakpoints,medium)) {
      .column {
        width: 33.3% !important;
      }
    }
    @media screen and (max-width: map-get($breakpoints,small)) {
      .column {
        width: 100% !important;

        &.column.two {
          width: 50% !important;
        }
      }
    }
  }
}
