.accounts-container {
  display: flex;
  flex-direction: column;
  .portfolio {
    display: flex;
    flex-direction: column;
    .account-group {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 5px 0px;
      .account-card {
        box-shadow: 0 0 10px 0px
            rgba(map-get(map-get($palette, primary), 2), 0.08),
          inset 0 0 0 2px rgba(map-get(map-get($palette, primary), 2), 0.05);
        &:hover {
          box-shadow: 0 0 30px 0px
              rgba(map-get(map-get($palette, primary), 2), 0.1),
            inset 0 0 0 2px rgba(map-get(map-get($palette, primary), 2), 0.05);
        }
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background-color: map-get(map-get($palette, primary), 3);
        padding: 20px;
        border-radius: 8px;
        width: 100%;
        height: 60px;

        .left-group {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          .inner-group {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            img {
              border-radius: 50%;
            }
            .name {
              font-weight: $semi-bold;
              font-size: $font-size-b1-desktop;
              margin: 0 1.5rem;
              @media screen and (max-width: map-get($breakpoints,medium)) {
                font-size: $font-size-b1-tablet;
              }
              @media screen and (max-width: map-get($breakpoints,small)) {
                font-size: $font-size-b1-mobile;
              }
            }
          }
          .add-btn-mobile {
            display: none;
            @media screen and (max-width: map-get($breakpoints,small)) {
              display: block;
              align-self: flex-end;
              justify-self: flex-end;
            }
          }
        }
        .right-group {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
        .purchased {
          font-size: $font-size-b1-mobile;
          margin-right: 1rem;
          &.exited {
            color: map-get(map-get($palette, secondary), 2);
            margin-right: 0;
            font-weight: $semi-bold;
            font-size: $font-size-b1-mobile;
          }
        }
        @media screen and (max-width: map-get($breakpoints,medium)) {
          padding: 10px;
          .left-group {
            .inner-group {
              .name {
                margin: 0 1rem;
              }
            }
          }
        }
        @media screen and (max-width: map-get($breakpoints,small)) {
          height: 85px;
          justify-content: space-between;
          align-items: center;
          padding: 25px;
          max-width: 100%;
          .left-group {
            justify-content: space-between;
            .name {
              font-weight: $semi-bold;
              margin-left: 2rem;
              font-size: $font-size-b1-mobile;
              width: 40%;
            }
          }
          .purchased {
            font-size: $font-size-b1-mobile;
            font-weight: $regular;
            width: 100%;
            text-align: right;
          }
        }
      }
      .add-btn {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0 auto;
        cursor: pointer;
      }
    }
  }
}

.order-list-container {
  display: flex;
  flex-direction: column;
  min-height: 500px;
  @media screen and (max-width: map-get($breakpoints,medium)) {
    margin: 30px 5vw;
    min-height: 600px;
  }

  &.recent-orders {
    margin: 60px 0;
  }

  &.company {
    @media screen and (max-width: map-get($breakpoints,medium)) {
      margin: 0;
    }
  }

  .order-list-title {
    font-size: $font-size-h3-desktop;
    font-weight: $bold;
    line-height: $title-line-height;
    border-left: 8px solid map-get(map-get($palette, primary), 1);
    padding: 5px 12px;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    a {
      color: map-get(map-get($palette, primary), 2);
      font-size: $font-size-h3-desktop;
      @media screen and (max-width: map-get($breakpoints,medium)) {
        font-size: $font-size-h3-tablet;
      }
      @media screen and (max-width: map-get($breakpoints,small)) {
        font-size: $font-size-h3-mobile;
      }
    }
    img {
      height: $font-size-h3-desktop;
      border-radius: 50%;
      margin-left: 5px;
    }
    @media screen and (max-width: map-get($breakpoints,medium)) {
      text-align: center;
      border-left: none;
      justify-content: center;
    }
  }
  .order-headers {
    text-transform: uppercase;
    text-align: right;
    @media screen and (max-width: map-get($breakpoints,small)) {
      display: none;
    }
    .date {
      min-width: 95px;
      text-align: left;
    }
    .product {
      min-width: 180px;
      text-align: center;
    }
    .series {
      min-width: 20px;
    }
    .price {
      min-width: 80px;
    }
    .sharePrice {
      min-width: 50px;
    }
    .status {
      min-width: 80px;
      text-align: center;
    }
  }
  .orders {
    text-align: right;
    width: 100%;
    .order {
      // min-width: 840px;
      line-height: $line-height;
      padding: 10px;
      background-color: map-get(map-get($palette, primary), 3);
      margin: 10px 0;
      text-align: right;
      border-radius: 8px;
      cursor: pointer;
      box-shadow: 0 0 10px 0px
          rgba(map-get(map-get($palette, primary), 2), 0.08),
        inset 0 0 0 2px rgba(map-get(map-get($palette, primary), 2), 0.05);
      &:hover {
        box-shadow: 0 0 30px 0px
            rgba(map-get(map-get($palette, primary), 2), 0.1),
          inset 0 0 0 2px rgba(map-get(map-get($palette, primary), 2), 0.05);
      }
      .ui.grid > .column:not(.row) {
        padding-left: 5px !important;
        padding-right: 5px !important;
        height: 100%;
        margin: auto 0;
      }
      &.no-data {
        text-align: left;
        font-size: $font-size-b1-desktop;
      }
      @media screen and (max-width: map-get($breakpoints,small)) {
        text-align: left;
      }
      .sm-label {
        display: none;
        @media screen and (max-width: map-get($breakpoints,small)) {
          display: block;
          text-align: left;
          font-weight: $semi-bold;
          margin-bottom: 10px;
        }
      }

      .date {
        min-width: 95px;
        text-align: left;
      }
      .product {
        min-width: 180px;
        text-align: center;
        font-weight: $bold;
        @media screen and (max-width: map-get($breakpoints,small)) {
          text-align: left;
        }
      }
      .series {
        min-width: 20px;
      }
      .price {
        min-width: 80px;
        font-weight: $bold;
      }
      .sharePrice {
        min-width: 50px;
      }
      .status {
        min-width: 80px;
        text-align: center;
      }
    }
  }
}

.label {
  border-radius: 5px;
  max-width: 180px;
  display: inline-block;
  padding: 5px 10px;
  background-color: map-get(map-get($palette, primary), 3);
  color: map-get(map-get($palette, primary), 3);
  text-align: center;
  font-weight: $semi-bold;
  letter-spacing: 0.2px;
  font-size: $font-size-input-desktop;
  cursor: pointer;
  width: 90%;
  @media screen and (max-width: map-get($breakpoints,small)) {
    width: 100%;
  }
  &.red {
    background-color: rgba(map-get(map-get($palette, status), 1), 0.3);
    color: darken(rgba(map-get(map-get($palette, status), 1), 1), 5%);
    &:hover {
      background-color: lighten(
        rgba(map-get(map-get($palette, status), 1), 0.3),
        10%
      );
    }
  }
  &.yellow {
    background-color: rgba(map-get(map-get($palette, status), 6), 0.5);
    color: darken(map-get(map-get($palette, status), 6), 25%);
    &:hover {
      background-color: lighten(
        rgba(map-get(map-get($palette, status), 6), 0.5),
        10%
      );
    }
  }
  &.grey {
    background-color: rgba(map-get(map-get($palette, status), 7), 0.3);
    color: darken(rgba(map-get(map-get($palette, status), 7), 1), 20%);
    &:hover {
      background-color: lighten(
        rgba(map-get(map-get($palette, status), 7), 0.3),
        10%
      );
    }
  }
  &.teal {
    background-color: rgba(map-get(map-get($palette, status), 8), 0.3);
    color: darken(rgba(map-get(map-get($palette, status), 8), 1), 15%);
    &:hover {
      background-color: lighten(
        rgba(map-get(map-get($palette, status), 8), 0.3),
        10%
      );
    }
  }
  &.olive {
    background-color: rgba(map-get(map-get($palette, status), 10), 0.4);
    color: darken(rgba(map-get(map-get($palette, status), 10), 1), 20%);
    &:hover {
      background-color: lighten(
        rgba(map-get(map-get($palette, status), 10), 0.4),
        10%
      );
    }
  }
  &.orange {
    background-color: rgba(map-get(map-get($palette, status), 9), 0.4);
    color: darken(rgba(map-get(map-get($palette, status), 9), 1), 20%);
    &:hover {
      background-color: lighten(
        rgba(map-get(map-get($palette, status), 9), 0.4),
        10%
      );
    }
  }
  &.grey {
    background-color: rgba(map-get(map-get($palette, status), 7), 0.3);
    color: darken(rgba(map-get(map-get($palette, status), 7), 1), 20%);
    &:hover {
      background-color: lighten(
        rgba(map-get(map-get($palette, status), 7), 0.4),
        30%
      );
    }
  }
}
