.nav-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 150px;
  background-color: map-get(map-get($palette, primary), 3);

  box-shadow: 0 0 10px 0px rgba(map-get(map-get($palette, secondary), 6), 0.08),
    inset 0 0 0 1px rgba(map-get(map-get($palette, secondary), 6), 0.08);
  height: 65px;
  z-index: 100;
  position: relative;
  .logo {
    height: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;
    img {
      height: 35px;
    }
  }
  .main-nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 85%;

    .item {
      display: flex;
      align-items: center;
      padding: 0 10px;
      margin: 15px 15px;
      height: 100%;
      cursor: pointer;
      font-size: $font-size-nav-desktop;
      color: map-get(map-get($palette, primary), 2);
      font-weight: $semi-bold;
      &:last-child {
        margin-right: 0;
      }
      &:first-child {
        margin-left: 0;
      }

      .nav-button {
        background-color: map-get(map-get($palette, primary), 1);
        color: map-get(map-get($palette, primary), 3);
        padding: 8px 15px;
        margin-left: -1rem;
        border-radius: 6px;
        font-size: $font-size-nav-desktop;
        &:hover {
          background-color: lighten(map-get(map-get($palette, primary), 1), 3%);
        }
        @media screen and (max-width: map-get($breakpoints, medium)) {
          margin-left: 0;
          background-color: transparent;
          color: map-get(map-get($palette, primary), 2);
          padding: 0 10px;
          font-size: $font-size-nav-desktop;
          &:hover {
            background-color: transparent;
          }
        }
      }
      &:hover:not(.sign-in):not(.signup):not(.nav-button):not(.dropdown):not(.account):not(.mobile) {
        box-shadow: inset 0px -3px 0px rgba(map-get(map-get($palette, primary), 1), 1);
      }
      &:hover.sign-in {
        color: map-get(map-get($palette, primary), 1);
      }
    }
    .item.active-item {
      box-shadow: inset 0px -3px 0px rgba(map-get(map-get($palette, primary), 1), 1);
    }
    .dropdown_menu {
      z-index: 100;
      position: absolute;
      margin-left: -80px;
      display: flex;
      flex-direction: column;
      top: 64px;
      background-color: map-get(map-get($palette, primary), 3);
      box-shadow: 0px 4px 20px
        rgba(map-get(map-get($palette, primary), 2), 0.15);
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
      width: 230px;
      display: none;
      @media screen and (max-width: map-get($breakpoints,medium)) {
        margin-left: -110px;
      }
    }
    .dropdown:hover > .dropdown_menu {
      display: block;
    }
    .dropdown_menu-6 {
      animation: growDown 250ms ease-in-out forwards;
      transform-origin: top center;
    }
    @keyframes growDown {
      0% {
        transform: scale(0.5);
      }
      50% {
        transform: scale(1.05);
      }
      100% {
        transform: scale(1);
      }
    }
    .dropdown {
      .item {
        padding: 10px 15px;
        margin: 0;
        font-size: $font-size-nav-desktop;
        font-weight: $semi-bold;
        display: flex;
        flex-direction: row;
        align-items: center;
        color: map-get(map-get($palette, secondary), 2);
        img {
          margin-right: 20px;
        }
        span {
          font-size: $font-size-nav-desktop;
        }
        &:hover {
          border: none;
          color: map-get(map-get($palette, primary), 2);
        }
        &:active {
          opacity: 0.8;
        }
      }
    }
    &.mobile-active {
      display: none;
    }
  }

  .toggle {
    display: none;
  }
  @media screen and (max-width: map-get($breakpoints,large)) {
    padding: 0 60px;
    .main-nav {
      width: 85%;
      flex-wrap: nowrap;
      .item {
        margin: 15px 5px;
      }
    }
  }
  @media screen and (max-width: map-get($breakpoints, medium)) {
    padding: 0 40px;
  }
  @media screen and (max-width: map-get($breakpoints, small)) {
    padding: 0 1.5rem;
  }
}

@media screen and (max-width: map-get($breakpoints,medium)) {
  .nav-container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .main-nav {
      display: none;
    }
    .main-nav .item {
      display: flex;
      flex-direction: column;
      text-align: center;
      padding: 20px;
      margin: 0px;
      color: map-get(map-get($palette, primary), 2);
      border-bottom: 2px solid
        rgba(map-get(map-get($palette, primary), 2), 0.05);
    }
    .dropdown-active {
      background-color: map-get(map-get($palette, primary), 3);
      .item.account {
        .arrow {
          position: absolute;
          right: 1rem;
          width: 10px;
          height: 10px;
          border: solid map-get(map-get($palette, primary), 2);
          border-width: 0 3px 3px 0;
          padding: 3px;
        }
        .down {
          transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
        }
        .up {
          transform: rotate(-135deg);
          -webkit-transform: rotate(-135deg);
        }
      }
    }
    .item.active-item {
      box-shadow: none !important;
    }
    .main-nav.mobile-active {
      display: block;
      // background-color: map-get(map-get($palette, primary),1);
      background-color: map-get(map-get($palette, primary), 3);
      box-shadow: 0px 4px 20px
        rgba(map-get(map-get($palette, primary), 2), 0.15);
      width: 100vw;
      position: absolute;
      top: 64px;
      left: 0;
      max-width: 100%;
      z-index: 100;
    }
    .mobile-nav-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      z-index: 100;
      position: relative;
      .toggle-icon {
        display: block;
        height: 100%;
        padding: 5px;
      }
      #nav-icon {
        width: 20px;
        height: 36px;
        position: relative;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: 0.5s ease-in-out;
        -moz-transition: 0.5s ease-in-out;
        -o-transition: 0.5s ease-in-out;
        transition: 0.5s ease-in-out;
        cursor: pointer;
      }

      #nav-icon span {
        display: block;
        position: absolute;
        height: 2px;
        width: 100%;
        background: map-get(map-get($palette, primary), 1);
        border-radius: 9px;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: 0.25s ease-in-out;
        -moz-transition: 0.25s ease-in-out;
        -o-transition: 0.25s ease-in-out;
        transition: 0.25s ease-in-out;
      }

      #nav-icon span:nth-child(1) {
        top: 10px;
        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
      }

      #nav-icon span:nth-child(2) {
        top: 18px;
        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
      }

      #nav-icon span:nth-child(3) {
        top: 26px;
        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
      }

      #nav-icon.open span:nth-child(1) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        top: 10.5px;
        left: 0px;
      }

      #nav-icon.open span:nth-child(2) {
        width: 0%;
        opacity: 0;
      }

      #nav-icon.open span:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
        top: 24.5px;
        left: 0px;
      }
    }
  }
}
