.security-container {
  .mfa-group {
    .item {
      display: flex;
      flex-direction: row;
      border: none;
      font-size: $font-size-b1-desktop;
      padding: 1rem 0;
      line-height: $line-height;
      cursor: default;
      &:hover {
        background-color: transparent;
      }
      @media screen and (max-width: map-get($breakpoints,medium)) {
        flex-direction: column;
        font-size: $font-size-b1-tablet;
      }
      @media screen and (max-width: map-get($breakpoints,small)) {
        font-size: $font-size-b1-mobile;
      }
      .info-group {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        @media screen and (max-width: map-get($breakpoints,medium)) {
          margin: 10px 0px;
        }
      }
      span {
        font-size: $font-size-b1-desktop;
        margin-left: 3rem;
        background-color: map-get(map-get($palette, primary), 3);
        border-radius: 8px;
        padding: 5px 10px;
        color: map-get(map-get($palette, secondary), 2);
        cursor: pointer;
        &:hover {
          background-color: map-get(map-get($palette, secondary), 4);
        }
        &.enabled {
          cursor: default;
          color: map-get(map-get($palette, primary), 1);
          background-color: transparent;
          font-weight: $semi-bold;
        }
        &.remove {
          margin-left: 1rem;
          font-size: $font-size-input-desktop;
          &:hover {
            color: map-get(map-get($palette, status), 1);
          }
        }
        &.unavailable {
          cursor: default;
          background-color: map-get(map-get($palette, secondary), 4);
        }
        @media screen and (max-width: map-get($breakpoints,medium)) {
          margin-left: 0px;
          font-size: $font-size-b1-tablet;
          margin: 10px 0px;
          &.remove {
            margin-left: 0px;
          }
        }
        @media screen and (max-width: map-get($breakpoints,small)) {
          font-size: $font-size-b1-mobile;
        }
      }
    }
  }
  .item-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    span {
      margin-left: 2rem;
    }
  }
  .display {
    border-bottom: 1px solid rgba(map-get(map-get($palette, primary), 2), 0.2);
    width: 2rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $font-size-h3-desktop;
    margin-right: 2rem;
  }
  .display:last-child {
    border-right: none;
  }

  .wrap {
    display: inline-block;
    position: relative;
    display: flex;
  }
  .box-title.mfa-auth-box-section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    padding: 16px 24px;
    @media screen and (max-width: map-get($breakpoints,small)) {
      padding: 16px 0px;
    }
    & > span {
      font-weight: $regular;
      color: map-get(map-get($palette, secondary), 2);
      font-size: $font-size-b1-tablet !important;
      @media screen and (max-width: map-get($breakpoints, medium)) {
        margin: 0.5rem 0 !important;
      }
      @media screen and (max-width: map-get($breakpoints,small)) {
        font-size: $font-size-b1-mobile !important;
      }
    }
  }
  &.page-container {
    padding-bottom: 24px;
  }
}

.mfa-modal {
  .security-loading {
    width: 100%;
  }
  .security {
    @media screen and (max-width: map-get($breakpoints,medium)) {
      width: 100% !important;
    }
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    overflow: visible;
    .info-title.option {
      margin: 0;
    }
    .item-group {
      justify-content: center;
    }
    form {
      margin-top: 0;
      text-align: center;

      label {
        display: block;
        font-size: $font-size-b1-desktop;
        @media screen and (max-width: map-get($breakpoints,medium)) {
          font-size: $font-size-b1-tablet;
        }
        @media screen and (max-width: map-get($breakpoints,small)) {
          font-size: $font-size-b1-mobile;
        }
      }
      .input-group {
        margin: 1.1rem 0;
      }
      input:not(.PhoneInputInput) {
        letter-spacing: 5px;
        text-align: center;
        width: 200px;
        margin: 0 auto;
      }

      .input-group.no-label label {
        display: none;
      }
      .input-group.error:not(.checkbox) .err-msg {
        margin: 0 auto;
        text-align: center;
      }
    }
    .form-err {
      text-align: center;
      color: map-get(map-get($palette, error), 1);
      padding: 0.5rem;
      font-size: $font-size-b1-desktop;
      @media screen and (max-width: map-get($breakpoints,medium)) {
        font-size: $font-size-b1-tablet;
      }
      @media screen and (max-width: map-get($breakpoints,small)) {
        font-size: $font-size-b1-mobile;
      }
    }
    .note {
      margin: 1em 0 0;
      text-align: center;
      color: map-get(map-get($palette, secondary), 2);
      font-size: $font-size-b2-desktop;
      font-weight: $semi-bold;

      span {
        cursor: pointer;
        color: map-get(map-get($palette, primary), 4);
        font-weight: $bold;
        text-decoration: underline;
        font-size: $font-size-b2-desktop;
        &.time-counter {
          color: map-get(map-get($palette, primary), 2);
          cursor: default;
        }
      }
    }
    .mobile-setup-link {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: $semi-bold;
      min-height: 5rem;
      text-decoration: underline;
      cursor: pointer;
      font-size: $font-size-b1-desktop;
      padding: 2rem 0 0 0;
    }
    .lbl-bold {
      font-weight: $semi-bold;
      font-size: $font-size-b1-desktop;
      @media screen and (max-width: map-get($breakpoints,small)) {
        &.top-margin {
          margin-top: 10px;
        }
      }
    }
    .content {
      padding: 20px;
      text-align: center;
      margin: 0;
      @media screen and (max-width: map-get($breakpoints,medium)) {
        padding: 20px 0;
      }
    }
  }
  .ui.segment {
    height: 230px !important;
    width: 420px !important;
    @media screen and (max-width: map-get($breakpoints,medium)) {
      width: 100% !important;
    }
  }
  ul {
    margin-left: 20px;
  }
}

.delete-account {
  font-size: $font-size-b1-desktop;
  color: map-get(map-get($palette, primary), 1);
  font-weight: $regular;
  margin-top: 60px;
  display: inline-block;
  @media screen and (max-width: map-get($breakpoints,small)) {
    font-size: $font-size-b1-mobile;
  }
}

.delete-account-box {
  padding: 48px 117px;
  @media screen and (max-width: map-get($breakpoints,medium)) {
    padding: 48px 95px;
  }
  @media screen and (max-width: map-get($breakpoints,small)) {
    padding: 0px;
  }

  h4 {
    font-weight: $semi-bold;
    margin: 0px;
  }
  .delete-account-input {
    margin-top: 8px;
    max-width: 439px;
    @media screen and (max-width: map-get($breakpoints,small)) {
      max-width: 100%;
    }
  }
  .radio-group {
    margin-top: 24px;
    .radio-option label {
      font-size: $font-size-b1-desktop;
      color: map-get(map-get($palette, secondary), 2);
      @media screen and (max-width: map-get($breakpoints,medium)) {
        font-size: $font-size-b1-tablet;
      }
      @media screen and (max-width: map-get($breakpoints,small)) {
        font-size: $font-size-b1-mobile;
      }
    }
  }
  .cta-container {
    width: 439px;
    margin-top: 16px;
    display: flex;
    @media screen and (max-width: map-get($breakpoints,small)) {
      width: 100%;
    }
    .btn {
      width: 100%;
      max-width: 100%;
    }
  }
}

.confirm-delete-modal {
  h4 {
    font-weight: $semi-bold;
    margin: 0;
  }
  .content {
    margin-bottom: 24px;
  }
}
