.drop-zone-container {
  width: 100%;
  .with-cam {
    width: 90%;
  }
  div {
    &:focus {
      outline: none;
    }
  }
  &:focus {
    outline: none;
  }
  .drop-area {
    width: 100%;
    border-radius: 8px;
    background-color: map-get(map-get($palette, secondary), 4);
    padding: 10px 20px;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23C4C4C4FF' stroke-width='3' stroke-dasharray='10%2c5' stroke-dashoffset='65' stroke-linecap='square'/%3e%3c/svg%3e");
    color: map-get(map-get($palette, secondary), 3);
    display: flex;
    flex-direction: row;
    align-items: center;
    &:focus {
      outline: none;
    }
    &:hover {
      background-color: map-get(map-get($palette, primary), 3);
      cursor: pointer;
    }
    &.dragging {
      background-image: none;
      box-shadow: 0px 0px 0px 3px map-get(map-get($palette, primary), 1);
      // border: 3px solid map-get(map-get($palette, primary), 1);
    }
    .upload-img {
      width: 30px;
    }
    .left-group {
      width: 90%;
    }
    .doc-title {
      font-size: $font-size-b1-desktop;
      line-height: $line-height;
      margin-left: 3.5rem;
      font-weight: $semi-bold;
      color: map-get(map-get($palette, secondary), 3);
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      @media screen and (max-width: map-get($breakpoints,medium)) {
        margin-left: 1rem;
        font-size: $font-size-b1-tablet;
      }
      @media screen and (max-width: map-get($breakpoints,small)) {
        margin-left: 1rem;
        font-size: $font-size-b1-mobile;
      }
    }
    &.uploaded {
      background-image: none;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      cursor: default;
      .left-group {
        display: flex;
        flex-direction: row;
        align-items: center;
        .doc-title {
          color: map-get(map-get($palette, primary), 1);
        }
      }
      &:hover {
        background-color: map-get(map-get($palette, secondary), 4);
      }
      .delete-icon {
        padding: auto 0;
        cursor: pointer;
        svg {
          height: 100%;
          padding-top: 4px;
          stroke: map-get(map-get($palette, secondary), 3);
          &:hover {
            stroke: map-get(map-get($palette, primary), 1);
          }
        }
      }
    }
  }
  .progress-bar {
    height: 3px;
    background-color: map-get(map-get($palette, primary), 1);
    transform: translateY(-3px);
    transition: all 0.1s ease-in-out;
    border-radius: 8px;
  }
}
