.signup-container {
  .signup-header {
    margin-bottom: 2rem;
    font-size: $font-size-h4-desktop;
    font-weight: $semi-bold;
    text-align: left;
    @media screen and (max-width: map-get($breakpoints, medium)) {
      font-size: $font-size-h4-tablet;
    }
    @media screen and (max-width: map-get($breakpoints, small)) {
      font-size: $font-size-h4-mobile;
    }
  }

  .signup-reward-container {
    background-color: map-get(map-get($palette, secondary), 4);
    display: flex;
    padding: 30px 36px 36px 36px;
    border-radius: 8px;
    align-items: center;
    height: 150px;

    @media screen and (max-width: map-get($breakpoints, medium)) {
      height: 120px;
      padding: 20px;
    }

    @media screen and (max-width: map-get($breakpoints, small)) {
      min-height: 72px;
      max-height: 78px;
      padding: 15px;
      margin: -25px;
      border-radius: 0;
      margin-bottom: 15px;
    }

    &.partner-signup-reward-container {
      background-color: map-get(map-get($palette, primary), 3);
      box-shadow: 0px 0px 10px rgba(14, 22, 36, 0.08),
        inset 0px 0px 0px 1px rgba(14, 22, 36, 0.08);
      padding: 0px 36px 0px 0px;

      img {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        @media screen and (max-width: map-get($breakpoints, small)) {
          height: 100%;
        }
      }

      @media screen and (max-width: map-get($breakpoints, small)) {
        min-height: 72px;
        max-height: 78px;
        margin: 0;
        border-radius: 8px;
        margin-bottom: 15px;
      }
    }

    .signup-reward-text {
      font-size: $font-size-b1-desktop;
      margin-left: 30px;
      line-height: $line-height;
      @media screen and (max-width: map-get($breakpoints,medium)) {
        font-size: $font-size-b1-tablet;
      }
      @media screen and (max-width: map-get($breakpoints, small)) {
        font-size: $font-size-b1-mobile;
        margin: 0 10px;
        line-height: 1.25;
      }

      & strong {
        font-size: $font-size-b1-desktop;
        @media screen and (max-width: map-get($breakpoints,medium)) {
          font-size: $font-size-b1-tablet;
        }
        @media screen and (max-width: map-get($breakpoints, small)) {
          font-size: $font-size-b1-mobile;
        }
      }
    }

    img {
      height: 100%;
      @media screen and (max-width: map-get($breakpoints, small)) {
        height: 70%;
      }
    }
  }
  .account-signin {
    min-height: 30px;
    margin: 1rem 0;
    font-size: $font-size-b1-desktop;
    color: map-get(map-get($palette, secondary), 2);
    & > span {
      font-size: $font-size-b1-desktop;
      color: map-get(map-get($palette, primary), 1);
      font-weight: $semi-bold;
      cursor: pointer;
    }
    @media screen and (max-width: map-get($breakpoints, small)) {
      font-size: $font-size-b1-mobile;
      & > span {
        font-size: $font-size-b1-mobile;
      }
    }
  }
  .ui.grid > .row {
    padding: 0;
    @media screen and (max-width: map-get($breakpoints, medium)) {
      .eight.wide.column {
        padding: 0rem 0.5rem !important;
      }
    }
  }
}

.accredited-radio-group {
  font-size: $font-size-b1-desktop;

  @media screen and (max-width: map-get($breakpoints,medium)) {
    font-size: $font-size-b1-tablet;
  }
  @media screen and (max-width: map-get($breakpoints,small)) {
    font-size: $font-size-b1-mobile;
  }

  .title {
    font-weight: $semi-bold;
    margin-bottom: 0.3rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: $title-line-height;

    img {
      height: 18px;
      margin-right: 5px;
    }
  }

  .subtitle {
    color: map-get(map-get($palette, secondary), 2);
  }
}

.ui.error.message {
  a {
    text-decoration: none;
    color: map-get(map-get($palette, primary), 1);
    font-weight: $semi-bold;
    font-size: $font-size-b1-desktop;
  }
  @media screen and (max-width: map-get($breakpoints,medium)) {
    a {
      font-size: $font-size-b1-tablet;
    }
  }
  @media screen and (max-width: map-get($breakpoints,small)) {
    a {
      font-size: $font-size-b1-mobile;
    }
  }
}
