.top-listings-container {
  background-color: map-get(map-get($palette, primary), 3);
  .top-listing-header {
    margin-bottom: 3rem;
  }
  .ui.grid > .stackable.stackable.row > .column {
    padding-top: 0 !important;
  }
  .card {
    width: 100%;
    height: 130px;
    border-radius: 8px;
    background-color: map-get(map-get($palette, primary), 3);
    padding: 10px;
    margin: 1rem 0;
    transition: 0.3s box-shadow ease;
    box-shadow: 0 0 10px 0px rgba(map-get(map-get($palette, primary), 2), 0.08),
      inset 0 0 0 1px rgba(map-get(map-get($palette, primary), 2), 0.08);
    @media screen and (max-width: map-get($breakpoints,medium)) {
      width: 100%;
      margin: 0;
    }
    cursor: pointer;
    @media screen and (max-width: map-get($breakpoints,small)) {
      box-shadow: 0 0 10px 5px
        rgba(map-get(map-get($palette, primary), 2), 0.08);
    }
    &:hover {
      box-shadow: 0 0 30px 0px rgba(map-get(map-get($palette, primary), 2), 0.1),
        inset 0 0 0 1px rgba(map-get(map-get($palette, primary), 2), 0.08);
    }
    &:active {
      transform: scale(0.97);
    }
    display: flex;
    align-items: center;
    .row {
      display: flex;
      align-items: center;
      margin: 0px 15px;
      img {
        align-self: flex-start;
        width: auto;
        height: 70px;
        border-radius: 50%;
      }

      .info {
        display: flex;
        flex-direction: column;
        margin: 0px 0px 0px 20px;
        color: map-get(map-get($palette, primary), 2);
        .company-name {
          width: 200px;
          font-size: $font-size-h4-desktop;
          margin: 5px 0;
          font-weight: $semi-bold;
          @media screen and (max-width: map-get($breakpoints,small)) {
            width: 160px;
          }
        }
        .series {
          margin: 5px 0;
          font-size: $font-size-b1-desktop;
          color: map-get(map-get($palette, secondary), 2);
        }
      }
    }
  }
}
