.unicorns-container {
  .page-container {
    @media screen and (max-width: map-get($breakpoints, medium)) {
      padding-top: 15px;
    }
    @media screen and (max-width: map-get($breakpoints,small)) {
      padding-top: 0px;
    }
  }
  .inner-container {
    position: relative;
  }

  .page-header {
    position: relative;
    text-align: left;
    color: map-get(map-get($palette, primary), 3);
    height: 400px;
    overflow: hidden;
    background: linear-gradient(
        to right,
        transparent,
        map-get(map-get($palette, tealGradient), 1)
      ),
      linear-gradient(
        to bottom,
        map-get(map-get($palette, tealGradient), 3),
        map-get(map-get($palette, tealGradient), 2)
      );

    @media screen and (max-width: map-get($breakpoints, medium)) {
      height: 230px;
    }
    @media screen and (max-width: map-get($breakpoints,small)) {
      height: 165px;
      min-height: 165px;
      margin-bottom: 30px;
      padding-bottom: 30px;
    }
    .unicorn-backgeound {
      position: absolute;
      transform: translateX(-280px);
      opacity: 0.8;
      @media screen and (max-width: map-get($breakpoints, medium)) {
        transform: translateX(-200px) scale(0.75);
      }
      @media screen and (max-width: map-get($breakpoints,small)) {
        transform: translateX(-100px) scale(0.5);
      }
    }

    .inner-container {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
    }
    .page-header-title-container {
      width: 40%;

      @media screen and (max-width: map-get($breakpoints,small)) {
        width: 60%;
        margin-left: 20px;
      }
    }
    .page-header-title {
      font-size: $font-size-h1-desktop;
      font-weight: $semi-bold;
      text-align: left;
      line-height: $title-line-height;
      margin-bottom: 10px;

      @media screen and (max-width: map-get($breakpoints, medium)) {
        font-size: $font-size-h1-tablet;
      }
      @media screen and (max-width: map-get($breakpoints,small)) {
        font-size: $font-size-h1-mobile;
        margin-bottom: 5px;
        margin-top: 25px;
      }
    }
    .powered-by {
      display: flex;
      align-items: center;
      font-size: $font-size-b1-desktop;
      margin-bottom: 30px;
      @media screen and (max-width: map-get($breakpoints,small)) {
        font-size: $font-size-b2-desktop;
        margin-bottom: 10px;
      }

      img {
        width: 120px;
        @media screen and (max-width: map-get($breakpoints,small)) {
          width: 75px;
        }
      }
    }
    .page-header-sub-title {
      font-size: $font-size-h4-desktop;
      font-weight: $thin;
      line-height: $line-height;
      @media screen and (max-width: map-get($breakpoints, medium)) {
        font-size: $font-size-h4-tablet;
      }
      @media screen and (max-width: map-get($breakpoints,small)) {
        font-size: $font-size-h4-mobile;
      }
    }
    .page-header-fact-desc {
      font-size: $font-size-h4-desktop;
      @media screen and (max-width: map-get($breakpoints, medium)) {
        font-size: $font-size-h4-tablet;
      }
      @media screen and (max-width: map-get($breakpoints,small)) {
        font-size: $font-size-h4-mobile;
      }
    }
    .page-header-fact-item {
      @media screen and (max-width: map-get($breakpoints,small)) {
        height: 30px;
      }
    }
    .unicorn-header-img {
      width: 408px;
      @media screen and (max-width: map-get($breakpoints, medium)) {
        width: 300px;
      }
      @media screen and (max-width: map-get($breakpoints,small)) {
        width: 150px;
        margin-right: 20px;
      }
    }
  }
  .card-single-line {
    img {
      max-height: 50px;
    }
  }

  .card {
    cursor: pointer;
    align-items: center;
    flex-direction: column;
    color: map-get(map-get($palette, primary), 2);

    @media screen and (max-width: map-get($breakpoints,medium)) {
      img {
        max-height: 40px;
        width: 80%;
      }
    }

    @media screen and (max-width: map-get($breakpoints,small)) {
      height: 66px;
      margin: 7px 0px;

      img {
        max-height: 42px;
        max-width: 80%;
      }
    }
  }

  .ui.grid > .row {
    @media screen and (max-width: map-get($breakpoints,medium)) {
      .column {
        width: 33.3% !important;
      }
    }
    @media screen and (max-width: map-get($breakpoints,small)) {
      .column {
        width: 100% !important;

        &.column.two {
          width: 50% !important;
        }
        &.column.card-single-line.two {
          width: 100% !important;
        }
      }
    }
  }

  .card-img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    .loader-component {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .card-title {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: $bold;
    font-size: $font-size-h4-desktop;
    text-align: center;
    @media screen and (max-width: map-get($breakpoints,medium)) {
      font-size: $font-size-h4-tablet;
    }
    @media screen and (max-width: map-get($breakpoints,small)) {
      font-size: $font-size-h4-mobile;
    }
  }

  .card-valuation {
    text-align: center;
    margin-bottom: 15px;
    @media screen and (max-width: map-get($breakpoints,medium)) {
      margin-bottom: 5px;
    }
    span {
      font-weight: $semi-bold;
    }
  }
  @media screen and (max-width: map-get($breakpoints,small)) {
    .card-single-line {
      .card {
        flex-direction: row;
        height: 60px;
        margin-bottom: 5px;
        img {
          max-height: 40px;
          margin-left: 15px;
          width: 35%;
        }

        .card-valuation {
          width: 50%;
          margin-bottom: 0px;
          text-align: right;
          margin-right: 15px;
        }
      }
    }
  }

  .loader-component {
    height: 50px;
  }

  .unicrons-filters {
    .ui.grid {
      @media screen and (max-width: map-get($breakpoints,small)) {
        margin-left: 0px;
        margin-right: 0px;
      }
    }
    .unicrons-filters-search {
      position: relative;
      .input-group {
        margin-bottom: 0;
        margin-top: 5px;
      }
      input[type='text'] {
        padding: 8px 35px 8px 12px;
        width: 100%;
      }
      img {
        position: absolute;
        top: 15px;
        right: 12px;
        opacity: 0.7;
      }
    }
    .select-column {
      width: 22.22% !important;
      @media screen and (max-width: map-get($breakpoints, medium)) {
        padding-right: 0px !important;
      }
      @media screen and (max-width: map-get($breakpoints,small)) {
        width: 100% !important;
        margin-bottom: 5px;
      }
    }

    .select-input {
      width: 33.33% !important;
      @media screen and (max-width: map-get($breakpoints,small)) {
        width: 100% !important;
      }
    }
    select {
      width: 100%;
      margin-top: 5px;
    }
  }

  .empty-unicrons {
    margin-top: 20px;
    margin-left: 20px;
    font-size: $font-size-b1-desktop;
    font-weight: $semi-bold;
  }
}

.unicorns-details-container {
  background-color: map-get(map-get($palette, primary), 3);
  clear: both;
  .inner-container {
    position: relative;
  }
  .page-header {
    background: map-get(map-get($palette, secondary), 4);
    text-align: left;
    color: map-get(map-get($palette, primary), 2);
    align-items: flex-start;
    min-height: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    border-bottom: 1px solid map-get(map-get($palette, secondary), 3);
    margin-bottom: -158px;
    padding: 80px 0 100px 0;
    @media screen and (max-width: map-get($breakpoints, medium)) {
      padding: 50px 0 80px 0;
    }
    @media screen and (max-width: map-get($breakpoints,small)) {
      height: auto;
      margin-bottom: -124px;
      padding: 50px 0 80px 0;
    }
    .unicorn-backgeound {
      position: absolute;
      transform: translate(-386px, -36px);

      @media screen and (max-width: map-get($breakpoints, medium)) {
        transform: translate(-11%, -36px);
      }
      @media screen and (max-width: map-get($breakpoints,small)) {
        display: none;
      }
    }
  }
  .page-header-company-info {
    display: flex;
    align-items: flex-start;
    @media screen and (max-width: map-get($breakpoints,small)) {
      flex-direction: column;
    }

    .page-header-company-logo {
      width: 55%;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 20px;
      margin-left: -36px;
      margin-top: -36px;
      @media screen and (max-width: map-get($breakpoints, medium)) {
        margin-left: 0px;
        width: 50%;
        flex-direction: column;
      }
      @media screen and (max-width: map-get($breakpoints,small)) {
        width: 100%;
        flex-direction: column;
        margin-left: 0px;
        margin-top: 10px;
      }

      .page-header-company-logo-wrapper {
        min-width: 267px;
        height: 267px;
        border: 1px solid map-get(map-get($palette, secondary), 4);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        .page-header-company-logo-bg {
          display: flex;
          justify-content: center;
          align-items: center;
          border: 5px solid map-get(map-get($palette, secondary), 4);
          border-radius: 50%;
          width: 196px;
          height: 196px;
          background: map-get(map-get($palette, primary), 3);
          overflow: hidden;

          img {
            width: 80%;
          }
        }
      }
      .page-header-company-title-container {
        margin-left: 5px;
        .page-header-company-title {
          font-size: $font-size-h3-desktop;
          font-weight: $semi-bold;
          margin-bottom: 10px;

          @media screen and (max-width: map-get($breakpoints, medium)) {
            margin-top: -30px;
            padding: 0 15px;
            text-align: center;
            font-size: $font-size-h3-tablet;
          }
          @media screen and (max-width: map-get($breakpoints, small)) {
            font-size: $font-size-h3-mobile;
          }
        }
      }
    }

    .page-header-company-details {
      width: 45%;
      @media screen and (max-width: map-get($breakpoints, medium)) {
        width: 50%;
      }
      @media screen and (max-width: map-get($breakpoints,small)) {
        padding: 0px 24px;
        width: 100%;
      }
      .page-header-company-details-desc {
        line-height: $line-height;
        padding-left: 20px;

        .page-header-company-desc {
          line-height: $line-height;
          font-size: $font-size-b1-desktop;
        }
        @media screen and (max-width: map-get($breakpoints, medium)) {
          padding-left: 0px;
          padding-right: 20px;
        }
        @media screen and (max-width: map-get($breakpoints,small)) {
          padding-left: 0px;
          padding-right: 0px;
        }

        a {
          color: map-get(map-get($palette, primary), 2);
          display: block;
          text-decoration: underline;
          margin-top: 10px;
        }
        .page-header-company-signin {
          margin-top: 20px;
          display: block;
        }
      }
      span {
        text-decoration: underline;
        cursor: pointer;
        font-weight: $bold;
        &:hover {
          color: map-get(map-get($palette, secondary), 3);
        }
      }
    }
    .page-header-company-share {
      margin-top: 20px;
      span {
        font-weight: $bold;
      }
    }
  }

  .section-seperator {
    margin-bottom: 30px;
  }

  .section-line-seperator {
    border-bottom: 0.5px solid map-get(map-get($palette, secondary), 3);
  }

  .unicorn-details-section {
    color: map-get(map-get($palette, primary), 2);
    padding-bottom: 30px;
    .ui.grid {
      margin-top: 0px;
      margin-bottom: 0px;
    }
    @media screen and (max-width: map-get($breakpoints,small)) {
      .ui.grid > [class*='three column mobile-two'].row > .column {
        width: 50% !important;
      }
      .ui.grid > [class*='three column mobile-one'].row > .column {
        width: 100% !important;
      }
      .row > .column.mobile-section-seperator {
        margin-top: 30px;
      }
    }

    &:last-child {
      border: none;
    }
  }
}
.unicorn-table.ui.grid {
  margin-left: 0px;
  margin-right: 0px;

  @media screen and (max-width: map-get($breakpoints,small)) {
    display: none;
  }
  &.ui.grid {
    margin-top: 20px;
  }

  .column-right {
    text-align: right;
    padding-right: 50px;
  }
  .unicorn-table-header {
    border-bottom: 0.5px solid map-get(map-get($palette, secondary), 3);
    padding-bottom: 10px;
    padding-top: 10px;

    div {
      font-size: $font-size-b1-mobile;
      font-weight: $semi-bold;
    }
  }
  .row.unicorn-table-body {
    padding-top: 12px;
    padding-bottom: 12px;
    font-weight: $regular;

    &:nth-child(even) {
      background: rgba(map-get(map-get($palette, secondary), 3), 0.05);
    }
    &:last-child {
      border-bottom: 0.5px solid map-get(map-get($palette, secondary), 3);
    }

    div {
      font-size: $font-size-b1-mobile;
    }
  }
}

.unicorn-card-container {
  display: none;
  @media screen and (max-width: map-get($breakpoints,small)) {
    display: block;
    .unicorn-card {
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0px;
      }
      .ui.grid > .row {
        padding-top: 5px;
        padding-bottom: 5px;
      }
      .column-bold {
        font-weight: $semi-bold;
      }

      div,
      strong {
        font-size: $font-size-b1-mobile;
      }
      .unicorn-card-row-line-sepretaor {
        border-bottom: 0.5px solid map-get(map-get($palette, secondary), 3);
        display: block;
        height: 1px;
        width: 100%;
        margin-left: 18px;
        margin-right: 18px;
        margin-bottom: 5px;
        margin-top: -8px;
      }
    }
  }
}
.pager-header-summary {
  border: 1px solid map-get(map-get($palette, secondary), 4);
  box-shadow: 0px 24px 48px rgba(map-get(map-get($palette, secondary), 6), 0.2);
  border-radius: 16px;
  height: 98px;
  margin-top: 40px;
  background: map-get(map-get($palette, primary), 3);
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  font-weight: $semi-bold;
  @media screen and (max-width: map-get($breakpoints, medium)) {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  @media screen and (max-width: map-get($breakpoints,small)) {
    margin-top: 20px;
    height: 175px;
    display: block;
    padding: 24px 24px 0px 24px;
    box-shadow: 0px 8px 20px rgba(map-get(map-get($palette, secondary), 6), 0.2);
    min-height: 175px;
    height: auto;
    overflow-y: auto;
    overflow-x: hidden;

    .unicorn-details-column {
      width: 50%;
      min-width: 50%;
      float: left;
      margin-bottom: 24px;

      label {
        margin-bottom: 5px;
      }
      div {
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
  .ui.grid {
    width: 100%;
  }
}

.unicorn-details-column {
  color: map-get(map-get($palette, primary), 2);

  label {
    font-size: $font-size-b1-desktop;
    font-weight: $thin;
    margin-bottom: 10px;
    display: block;
    font-weight: $semi-bold;
    &.light {
      font-weight: $regular;
      @media screen and (max-width: map-get($breakpoints,small)) {
        margin-bottom: 30px;
      }
    }
    &.extra-light {
      font-weight: $thin;
    }
  }
  div,
  strong {
    font-size: $font-size-b1-desktop;
    line-height: $line-height;
    @media screen and (max-width: map-get($breakpoints,medium)) {
      font-size: $font-size-b1-tablet;
    }
    @media screen and (max-width: map-get($breakpoints,small)) {
      font-size: $font-size-b1-mobile;
    }
  }

  span {
    font-size: $font-size-b1-desktop;
    color: map-get(map-get($palette, primary), 2);
    font-weight: $bold;
    margin: 20px 0;
    display: block;
    text-decoration: underline;
    cursor: pointer;
    @media screen and (max-width: map-get($breakpoints,medium)) {
      font-size: $font-size-b1-tablet;
    }
    @media screen and (max-width: map-get($breakpoints,small)) {
      font-size: $font-size-b1-mobile;
    }
    &:hover {
      color: map-get(map-get($palette, secondary), 3);
    }
  }
}

.page-tabs {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  display: flex;

  @media screen and (max-width: map-get($breakpoints,small)) {
    justify-content: space-between;
  }

  .tab {
    font-size: $font-size-b1-desktop;
    padding-top: 16px;
    cursor: pointer;
    margin-right: 80px;
    line-height: 45px;

    @media screen and (max-width: map-get($breakpoints,small)) {
      margin-right: 0px;
    }

    span {
      font-size: $font-size-b1-desktop;
      @media screen and (max-width: map-get($breakpoints,small)) {
        font-size: $font-size-b1-desktop;
      }
    }
    span.lg-tab-span {
      display: inline-block;
      @media screen and (max-width: map-get($breakpoints,small)) {
        display: none;
      }
    }

    span.sm-tab-span {
      display: none;
      @media screen and (max-width: map-get($breakpoints,small)) {
        display: inline-block;
      }
    }
    img {
      display: none;
    }

    &.active {
      box-shadow: inset 0px -3px 0px map-get(map-get($palette, primary), 1);
      font-weight: $bold;
      @media screen and (max-width: map-get($breakpoints,small)) {
        box-shadow: inset -1px -3px -1px map-get(map-get($palette, primary), 1),
          inset -3px -3px 0px map-get(map-get($palette, secondary), 4);
      }

      img {
        @media screen and (max-width: map-get($breakpoints,small)) {
          display: block;
          position: absolute;
          left: -18px;
          top: 24px;
        }
      }
    }
    &:last-child {
      margin-right: 0px;
    }
  }
}

.blur-info {
  -webkit-filter: url('#blur');
  filter: url('#blur');
  -webkit-filter: blur(6px);
  filter: blur(6px);
  background-size: cover;
  opacity: 0.5;
}

.buy-unicorn {
  margin-top: 50px;
  img {
    margin-right: 20px;
  }
  @media screen and (max-width: map-get($breakpoints,small)) {
    width: 100%;
    margin-top: 40px;
  }
}

.interest-unicorn {
  img {
    margin-left: 10px;
  }
  &:hover {
    img {
      filter: invert(1%) sepia(1%) saturate(1%) hue-rotate(1deg)
        brightness(1000%) contrast(100%);
    }
  }
}

.page-header-fact-container {
  flex-direction: row;
  display: flex;
  justify-content: center;
  margin-top: 50px;
  @media screen and (max-width: map-get($breakpoints, medium)) {
    margin-top: 30px;
  }
  @media screen and (max-width: map-get($breakpoints,small)) {
    margin-top: 20px;
  }
  .page-header-fact-col {
    flex-direction: column;
    display: flex;

    &.page-header-fact-col-num {
      text-align: right;
      margin-right: 20px;
    }
  }
  .page-header-fact-number {
    color: map-get(map-get($palette, primary), 1);
    font-size: $font-size-h2-desktop;
    font-weight: $semi-bold;
    @media screen and (max-width: map-get($breakpoints, medium)) {
      font-size: $font-size-h2-tablet;
    }
    @media screen and (max-width: map-get($breakpoints,small)) {
      font-size: $font-size-h2-mobile;
    }
  }
  .page-header-fact-desc {
    color: map-get(map-get($palette, primary), 2);
    font-size: $font-size-h2-desktop;
    font-weight: $semi-bold;
    @media screen and (max-width: map-get($breakpoints, medium)) {
      font-size: $font-size-h2-tablet;
    }
    @media screen and (max-width: map-get($breakpoints,small)) {
      font-size: $font-size-h2-mobile;
    }
  }
}

.mosaic-modal {
  .mosaic-modal-section {
    margin-bottom: 20px;
    @media screen and (max-width: map-get($breakpoints,small)) {
      padding: 0 10px;
    }
    label {
      font-weight: $bold;
      font-size: $font-size-b1-desktop;
      margin-bottom: 20px;
      display: block;

      &.modal-section-title {
        font-weight: $regular;
        font-size: $font-size-h4-desktop;
        @media screen and (max-width: map-get($breakpoints,medium)) {
          font-size: $font-size-h4-tablet;
        }
        @media screen and (max-width: map-get($breakpoints,small)) {
          font-size: $font-size-h4-mobile;
          margin-top: 20px;
        }
      }
    }
    p {
      font-size: $font-size-b1-desktop;
    }
  }
  .mosaic-modal-mobile {
    display: none;
  }
  @media screen and (max-width: map-get($breakpoints,small)) {
    .mosaic-modal-web {
      display: none;
    }
    .mosaic-modal-mobile {
      display: block;
    }
  }
}
