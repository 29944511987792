.wallet-container {
  .wallet {
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .content {
      margin: 5rem 0;
      text-align: center;
      line-height: $line-height;
      font-size: $font-size-h4-desktop;
      @media screen and (max-width: map-get($breakpoints,medium)) {
        font-size: $font-size-h4-tablet;
      }
      @media screen and (max-width: map-get($breakpoints,small)) {
        font-size: $font-size-h4-mobile;
      }
    }
    .uphold-balance,
    .ach-transfer {
      width: 100%;
      margin-bottom: 0px;
      .title-group {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 1.5rem;
        .title {
          font-size: $font-size-h4-desktop;
          font-weight: $semi-bold;
          display: flex;
          align-items: center;
          @media screen and (max-width: map-get($breakpoints,medium)) {
            font-size: $font-size-h4-tablet;
          }
          @media screen and (max-width: map-get($breakpoints,small)) {
            font-size: $font-size-h4-mobile;
            margin-bottom: 1rem;
          }
        }

        .refresh-btn {
          padding: 5px;
          cursor: pointer;
        }
      }
      .uphold-account,
      .bank-account {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 15px;
        border-radius: 8px;
        margin-bottom: 10px;
        box-shadow: 0 2px 20px
          rgba(map-get(map-get($palette, primary), 2), 0.05);
        .left-group {
          display: flex;
          flex-direction: row;
          align-items: center;
          .currency {
            font-size: $font-size-h4-desktop;
            font-weight: $semi-bold;
            margin: 0 1rem;
            @media screen and (max-width: map-get($breakpoints,medium)) {
              font-size: $font-size-h4-tablet;
            }
            @media screen and (max-width: map-get($breakpoints,small)) {
              font-size: $font-size-h4-mobile;
            }
          }
          img {
            height: 40px;
          }
        }
        .balance {
          font-size: $font-size-h4-desktop;
          @media screen and (max-width: map-get($breakpoints,medium)) {
            font-size: $font-size-h4-tablet;
          }
          @media screen and (max-width: map-get($breakpoints,small)) {
            font-size: $font-size-h4-mobile;
          }
        }
      }
      .note {
        text-align: center;
        margin-top: 100px;
        color: map-get(map-get($palette, secondary), 3);
      }
    }
    .linqto-balance {
      margin-top: 20px;
    }
    @media screen and (max-width: map-get($breakpoints,small)) {
      margin: 1rem 0;
      .image {
        width: 100%;
      }
      .content {
        margin: 2rem 0;
      }
    }
  }

  .wallet-error {
    h3 {
      margin-bottom: 24px;
    }
    .content {
      color: map-get(map-get($palette, secondary), 2);
      margin-bottom: 24px;
      @media screen and (max-width: map-get($breakpoints,small)) {
        margin-bottom: 16px;
      }
    }
    .wallet-support {
      margin-top: 64px;
      @media screen and (max-width: map-get($breakpoints,small)) {
        text-align: center;
      }
      a {
        color: map-get(map-get($palette, primary), 1);
        font-weight: $semi-bold;
      }
    }
  }

  .wallet-connect {
    display: flex;
    flex-direction: row;
    text-align: left;

    .wallet-connect-copy {
      width: 45%;
      padding-right: 12px;
      .content {
        text-align: left;
        margin: 0px 0px 24px;
        font-size: $font-size-b1-desktop;
        color: map-get(map-get($palette, secondary), 2);
        @media screen and (max-width: map-get($breakpoints,medium)) {
          font-size: $font-size-b1-tablet;
          margin: 0px 0px 18px;
        }
        @media screen and (max-width: map-get($breakpoints,small)) {
          font-size: $font-size-b1-mobile;
          margin: 0px 0px 16px;
        }
      }
      @media screen and (max-width: map-get($breakpoints,medium)) {
        width: 55%;
      }
      @media screen and (max-width: map-get($breakpoints,small)) {
        width: 100%;
        padding-right: 0px;
      }
    }
    .wallet-connect-image {
      width: 55%;
      text-align: center;
      position: relative;
      transform: translateY(-80px);
      .wallet-connect-image-bg {
        background: linear-gradient(
          293.45deg,
          map-get(map-get($palette, primary), 1) 22.11%,
          map-get(map-get($palette, secondary), 1) 92.03%
        );
        opacity: 0.08;
        width: 92%;
        padding-top: 92%;
        margin-left: 4%;
        border-radius: 50%;
        position: absolute;
        z-index: -1;
      }
      img {
        width: 50%;
        @media screen and (max-width: map-get($breakpoints,medium)) {
          width: 55%;
        }
      }
      @media screen and (max-width: map-get($breakpoints,medium)) {
        width: 45%;
        transform: translateY(-50px);
      }
      @media screen and (max-width: map-get($breakpoints,small)) {
        display: none;
      }
    }
    .wallet-connect-cta {
      width: 90%;
      @media screen and (max-width: map-get($breakpoints,small)) {
        width: 100%;
      }

      img {
        margin-right: 24px;
        @media screen and (max-width: map-get($breakpoints,medium)) {
          margin-right: 12px;
        }
        @media screen and (max-width: map-get($breakpoints,small)) {
          margin-right: 24px;
        }
      }

      .wallet-connect-btn {
        width: 100%;
        padding-right: 48px;
        @media screen and (max-width: map-get($breakpoints,medium)) {
          padding-right: 36px;
        }
        @media screen and (max-width: map-get($breakpoints,small)) {
          padding-right: 48px;
        }
      }
      .content {
        margin-top: 24px;
      }
    }
    .mobile-image {
      display: none;
    }
    @media screen and (max-width: map-get($breakpoints,small)) {
      .mobile-container {
        display: flex;
        justify-content: space-between;
        .mobile-image {
          display: block;
          width: 48%;
          img {
            width: 100%;
          }
        }
        .mobile-copy {
          width: 50%;
          padding-right: 6px;
        }
      }
    }
  }
  .wallet-accounts {
    display: flex;
    width: 100%;

    @media screen and (max-width: map-get($breakpoints,small)) {
      flex-direction: column;
    }
    .wallet-accounts-container {
      width: 65%;
      padding-right: 36px;
      transform: translateY(-40px);

      @media screen and (max-width: map-get($breakpoints,small)) {
        width: 100%;
        padding-right: 0px;
      }
      .title-group {
        justify-content: flex-end;
        margin-bottom: 24px;
        @media screen and (max-width: map-get($breakpoints,small)) {
          margin-bottom: 0px;
        }
        .title {
          font-size: $font-size-b1-mobile;
          font-weight: $regular;
          color: map-get(map-get($palette, secondary), 2);
          line-height: $title-line-height;
          @media screen and (max-width: map-get($breakpoints,small)) {
            margin-top: 2px;
            margin-bottom: 16px;
          }
        }
      }
      .uphold-balance {
        .uphold-account {
          padding: 0px 16px;
          box-shadow: 0px 0px 10px
              rgba(map-get(map-get($palette, primary), 2), 0.08),
            inset 0px 0px 0px 1px
              rgba(map-get(map-get($palette, primary), 2), 0.08);
          height: 48px;
          .left-group {
            img {
              height: 30px;
              width: 30px;
            }
            .currency {
              margin-left: 8px;
              font-weight: $regular;
              font-size: $font-size-b1-mobile;
            }
          }
          .balance {
            font-weight: $regular;
            font-size: $font-size-b1-mobile;
          }
        }
        .bucks-account {
          .left-group {
            img {
              width: 50px;
              height: 27px;
            }
          }
        }
      }
      .inline-text-link {
        align-items: center;
        display: flex;
        font-size: $font-size-b1-tablet;
        font-weight: $semi-bold;
        @media screen and (max-width: map-get($breakpoints,small)) {
          justify-content: center;
        }
      }
    }
    .wallet-bucks-container {
      width: 35%;
      @media screen and (max-width: map-get($breakpoints,small)) {
        width: 100%;
      }

      .wallet-bucks {
        background: map-get(map-get($palette, secondary), 4);
        border-radius: 8px;
        padding: 24px;
        @media screen and (max-width: map-get($breakpoints,small)) {
          margin: 0px -24px;
          border-radius: 0;
          transform: translateY(-24px);
          margin-bottom: -24px;
          padding: 16px 24px;
        }
        .wallet-bucks-header {
          font-weight: $semi-bold;
          font-size: $font-size-h4-tablet;
          line-height: $title-line-height;
          margin: 8px 0 24px;
          @media screen and (max-width: map-get($breakpoints,small)) {
            margin: 0px 0 16px;
          }
        }
        .wallet-bucks-body {
          font-size: $font-size-b1-mobile;
          line-height: $line-height;
          color: map-get(map-get($palette, secondary), 2);
          .inline-text-link {
            display: inline;
          }
        }
        .btn {
          font-size: $btn-font-size-phone;
          width: 100%;
          margin: 24px 0 16px;
          @media screen and (max-width: map-get($breakpoints,small)) {
            margin: 16px 0 16px;
          }
        }
      }
    }
  }
}

.input-group.checkbox.link-account-checkbox {
  .checkbox-label {
    .checkmark {
      margin-top: 5px;
      &.disabled {
        background: map-get(map-get($palette, secondary), 3);
      }
    }
    .children {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      div {
        color: map-get(map-get($palette, primary), 2);
      }
      small {
        color: map-get(map-get($palette, secondary), 3);
      }
    }
  }
}

.colored {
  color: map-get(map-get($palette, primary), 1);
}
