.verify {
  margin: 60px 13vw 100px;
  min-height: 950px;
  height: 90vh;
  @media screen and (max-width: map-get($breakpoints,medium)) {
    margin: 30px 5vw;
  }
  @media screen and (max-width: map-get($breakpoints,small)) {
    margin: 30px 5vw;
  }
  .status-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .message {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;
      @media screen and (max-width: map-get($breakpoints,medium)) {
        flex-direction: column;
      }
      img {
        height: 150px;
      }
      p {
        font-size: $font-size-b1-desktop;
        padding: 50px;
        @media screen and (max-width: map-get($breakpoints,medium)) {
          font-size: $font-size-b1-tablet;
          text-align: center;
        }
        @media screen and (max-width: map-get($breakpoints,small)) {
          font-size: $font-size-b1-mobile;
        }
      }
    }
  }
}
