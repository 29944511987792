.faq-link {
  cursor: pointer;

  &.question {
    display: flex;
    align-items: center;
    border: none;
    border-bottom: 1px solid rgb(228, 227, 227);
    & > span {
      font-size: $font-size-b1-desktop;
      color: map-get(map-get($palette, primary), 2);
      font-weight: $semi-bold;
      padding: 1rem 0;
      width: 100%;
      @media screen and (max-width: map-get($breakpoints,medium)) {
        font-size: $font-size-b1-tablet;
      }
      @media screen and (max-width: map-get($breakpoints,small)) {
        font-size: $font-size-b1-mobile;
      }
    }
  }

  &.answer {
    strong,
    a {
      font-size: $font-size-b1-desktop;
      @media screen and (max-width: map-get($breakpoints,medium)) {
        font-size: $font-size-b1-tablet;
      }
      @media screen and (max-width: map-get($breakpoints,small)) {
        font-size: $font-size-b1-mobile;
      }
    }
  }

  &.arrow {
    margin-right: 10px;
    transition: all 600ms ease;

    &.open {
      transform: rotate(90deg);
    }
    &.close {
      transform: rotate(0deg);
    }
  }

  &.active {
    max-height: 0;
    overflow: hidden;
    transition: max-height 250ms ease-in-out;
    margin: 10px 0 0 20px;
    &.open {
      max-height: 700px;
      margin-bottom: 20px;
    }
    &.close {
      max-height: 0;
      margin: 0 0 0 0;
    }
  }

  li {
    margin-left: 1rem;
    font-size: $font-size-b2-mobile;
  }

  @media screen and (max-width: map-get($breakpoints,medium)) {
    &.question {
      & > span {
        font-size: $font-size-b1-tablet;
      }
    }

    &.answer {
      font-size: $font-size-b1-tablet;
    }
  }
  @media screen and (max-width: map-get($breakpoints,small)) {
    &.question {
      & > span {
        font-size: $font-size-b1-mobile;
      }
    }

    &.answer {
      font-size: $font-size-b1-mobile;
    }
  }
}
