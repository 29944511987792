.order-detail-container {
  .contact-form {
    margin-bottom: 100px;
    @media screen and (max-width: map-get($breakpoints, small)) {
      padding: 10px;
      margin-bottom: 50px;
    }

    .header {
      font-size: $font-size-h4-desktop;
      font-weight: $bold;
      margin: 1rem 0;
      line-height: $title-line-height;
      @media screen and (max-width: map-get($breakpoints,medium)) {
        font-size: $font-size-h4-tablet;
      }
      @media screen and (max-width: map-get($breakpoints,small)) {
        font-size: $font-size-h4-mobile;
      }
    }
    .sub-header {
      margin: 1rem 0;
      font-size: $font-size-b1-desktop;
      font-weight: $bold;
      color: map-get(map-get($palette, primary), 1);
      @media screen and (max-width: map-get($breakpoints,medium)) {
        font-size: $font-size-b1-tablet;
      }
      @media screen and (max-width: map-get($breakpoints,small)) {
        font-size: $font-size-b1-mobile;
      }
    }
  }
}

.uphold-support {
  font-size: $font-size-b2-desktop;
  margin-top: -0.4rem;
  text-align: center;
  a {
    text-decoration: underline;
    font-size: $font-size-b2-desktop;
  }
}
.info {
  font-size: $font-size-b2-desktop;
  color: map-get(map-get($palette, secondary), 3);
  margin: 1rem 0;
  line-height: $line-height;
}
.place-order-m-content {
  display: none;
}

.place-order-box__details.confirm-bucks .confirm-checkbox {
  margin-top: 0px;
  margin-bottom: 10px;

  .children {
    margin-top: 1px;
    @media screen and (max-width: map-get($breakpoints, small)) {
      a {
        font-size: $font-size-b2-desktop;
      }
    }
  }
}
.place-order-box__details {
  .auth-title {
    margin-top: 10px;
    font-size: $font-size-b2-desktop;
  }
  .subtitle {
    font-size: $font-size-b2-desktop;
  }
  &.verification {
    .input-group input {
      text-align: center;
      letter-spacing: 2px;
      font-size: $font-size-b1-desktop;
    }
  }
}

@media screen and (max-width: map-get($breakpoints, small)) {
  .order-confirmation {
    .product-header {
      img,
      .product-name,
      .series,
      .price {
        display: none;
      }
    }
    .agreements {
      display: none;
    }

    .place-order-box__details small {
      font-size: $font-size-b3-desktop;
    }
    .order-group {
      .product-order {
        .buy-product {
          padding-bottom: 1rem;

          .summary,
          .summary b {
            font-size: $font-size-b1-mobile;
            line-height: $line-height;
          }
        }
        .children {
          font-size: $font-size-b2-desktop;
          line-height: $line-height;
        }
        .place-order-box__details:not(.confirm-checkbox):not(.verification):not(.linqto-bucks) {
          min-height: 30px;
          height: auto;
          font-size: $font-size-b1-mobile;
          span,
          div,
          div > b {
            font-size: $font-size-b1-mobile;
          }
          div > b.total-amt {
            font-size: $font-size-h4-desktop;
            @media screen and (max-width: map-get($breakpoints,medium)) {
              font-size: $font-size-h4-tablet;
            }
            @media screen and (max-width: map-get($breakpoints,small)) {
              font-size: $font-size-h4-mobile;
            }
          }
          .max-50 {
            max-width: 50%;
          }
        }

        .place-order-box {
          div {
            font-size: $font-size-b1-mobile;
          }
          div.info {
            font-size: $font-size-b2-desktop;
          }
          div.err-msg {
            margin-bottom: 10px;
          }
          .line {
            border-bottom: 0.8px solid
              rgba(map-get(map-get($palette, primary), 2), 0.1);
          }
        }
      }
    }

    .info {
      margin: 0 0 1rem 0;
      font-size: $font-size-b3-desktop;
    }
  }

  .place-order-m-content {
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;

    .agreements {
      display: block;
      margin: 0px;

      .agreement {
        padding: 0.8rem 1.4rem;
      }
    }
  }
}
