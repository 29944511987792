.header-progressbar-wrapper {
  @media screen and (max-width: map-get($breakpoints,small)) {
    margin-top: -64px;
    display: flex;
    flex-direction: column;
    .progressbar-wrapper {
      order: 1;
    }
    .page-title {
      order: 2;
      margin-bottom: 0;
    }
    h2 {
      order: 4;
      margin-top: 24px;
      margin-bottom: 0;
    }
    .mobile-account-email {
      order: 3;
      margin-top: 8px;
    }
  }
}

.progressbar-wrapper {
  display: flex;
  width: 80%;
  margin: 20px auto;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: map-get($breakpoints,small)) {
    width: 100%;
    justify-content: space-between;
  }

  .progressbar-step {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: map-get(map-get($palette, secondary), 3);

    &.active {
      color: map-get(map-get($palette, primary), 1);
    }
    &.completed {
      color: map-get(map-get($palette, primary), 1);
    }
  }
  .progressbar-line {
    height: 1px;
    width: 30%;
    border-top: 0.5px solid map-get(map-get($palette, secondary), 2);
    margin-bottom: 20px;
    @media screen and (max-width: map-get($breakpoints,medium)) {
      width: 25%;
    }
  }
}
hr.title-separator {
  @media screen and (max-width: map-get($breakpoints, small)) {
    display: none;
  }
}
