.status-container {
  display: flex;
  flex-direction: row;
  .status-group {
    position: relative;
    .status {
    }
    .dots {
      height: 10px;
      width: 10px;
      background-color: map-get(map-get($palette, secondary), 3);
      border-radius: 50%;
      display: inline-block;
      z-index: 100;
    }
  }
  .line {
    width: 100%;
    position: relative;
    top: 27px;
    left: 100px;
    z-index: 0;
  }
}

:root {
  --background-modal-color: map-get(map-get($palette, primary), 3);
  --body-color: map-get(map-get($palette, primary), 3);
  --color-timeline-default: map-get(map-get($palette, status), 4);
  --color-step-completed: map-get(map-get($palette, status), 2);
  --color-checkmark-completed: map-get(map-get($palette, primary), 3);
  --color-in-progress: map-get(map-get($palette, status), 3);
  --color-label-default: var(--color-timeline-default);
  --color-label-completed: var(--color-step-completed);
  --color-label-loading: var(--color-in-progress);
  --color-icon-completed: var(--color-step-completed);
  --color-icon-default: var(--color-timeline-default);
}

.main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: all 200ms ease;
  background: var(--background-modal-color);
  height: 220px;
  min-width: 420px;
  max-width: 750px;
  flex-grow: 1;
  border-radius: 5px;
  box-shadow: 0 2px 5px 0 rgba(map-get(map-get($palette, primary), 2), 0.14);
  .steps-container {
    padding: 40px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .step {
      z-index: 1;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 200ms ease;
      flex-grow: 0;
      height: 15px;
      width: 15px;
      border: 4px solid var(--color-timeline-default);
      border-radius: 50%;
      .preloader,
      svg {
        display: none;
      }
      &.completed {
        width: 18px;
        height: 18px;
        background: var(--color-step-completed);
        border: none;
        svg {
          transition: all 200ms ease;
          display: block;
          height: 10px;
          width: 10px;
          fill: var(--color-checkmark-completed);
        }
      }
      &.in-progress {
        width: 18px;
        height: 18px;
        background: var(--color-in-progress);
        border: none;
        .preloader {
          display: block;
          height: 10px;
          width: 10px;
          border: 2px solid map-get(map-get($palette, primary), 3);
          border-radius: 50%;
          border-left-color: transparent;
          animation-name: spin;
          animation-duration: 2000ms;
          animation-iteration-count: infinite;
          animation-timing-function: linear;
        }
      }
      .label {
        position: absolute;
        top: 30px;
        filter: none;
        z-index: 2000;
        color: var(--color-label-default);
        transition: all 200ms ease;
        font-weight: $bold;
        &.completed {
          color: var(--color-label-completed);
        }
        &.loading {
          color: var(--color-label-loading);
        }
      }
      .icon {
        font-size: 40px;
        position: absolute;
        top: -60px;
        color: var(--color-icon-default);
        transition: color 200ms ease;
        &.completed {
          color: var(--color-icon-completed);
        }
        &.in-progress {
          color: var(--color-in-progress);
        }
      }
    }
    .line {
      transition: all 200ms ease;
      height: 2px;
      flex-grow: 1;
      max-width: 120px;
      background: var(--color-timeline-default);
      &.completed {
        background: var(--color-step-completed);
      }
      &.next-step-uncomplete {
        background: linear-gradient(
          to right,
          var(--color-step-completed),
          var(--color-timeline-default)
        );
      }
      &.next-step-in-progress {
        background: linear-gradient(
          to right,
          var(--color-step-completed),
          var(--color-in-progress)
        );
      }
      &.prev-step-in-progress {
        background: linear-gradient(
          to right,
          var(--color-in-progress),
          var(--color-timeline-default)
        );
      }
    }
  }
}
