.invest-detail-container {
  .page-container {
    min-height: auto;
    padding: 0px;

    @media screen and (max-width: map-get($breakpoints, medium)) {
      padding: 0px 40px;
    }

    @media screen and (max-width: map-get($breakpoints, small)) {
      padding: 0px 24px;
    }
  }
  .invest-header-container {
    padding-bottom: 24px;
    border-bottom: 0.5px solid map-get(map-get($palette, secondary), 3);
    .invest-details-info {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 48px 0;

      @media screen and (max-width: map-get($breakpoints, small)) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 24px 0;
      }

      .invest-header {
        width: 50%;
        .toggle-switch-container {
          margin: 40px 0 0 0;
          @media screen and (max-width: map-get($breakpoints, small)) {
            margin: 20px 0 0 0;
          }
        }
        .company-sold-out {
          display: flex;
          align-items: center;
        }
        .company-available {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }
        @media screen and (max-width: map-get($breakpoints, small)) {
          width: 100%;
          padding-bottom: 16px;
          .company-sold-out {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
          }
        }
        .invest-logo {
          width: 188px;
          height: 66px;
          object-fit: contain;
          @media screen and (max-width: map-get($breakpoints, small)) {
            width: 85px;
            height: 30px;
          }
        }
        .invest-name {
          font-weight: $semi-bold;
          font-size: $font-size-h3-desktop;
          @media screen and (max-width: map-get($breakpoints, small)) {
            font-size: $font-size-h3-mobile;
          }
        }
        .offer-price {
          font-size: $font-size-h4-desktop;
          color: map-get(map-get($palette, secondary), 2);
          margin-top: 40px;
          @media screen and (max-width: map-get($breakpoints, small)) {
            margin-top: 0px;
            font-size: $font-size-b1-mobile;
          }
          .price {
            font-weight: $semi-bold;
            font-size: $font-size-h4-desktop;
            color: map-get(map-get($palette, primary), 2);
            @media screen and (max-width: map-get($breakpoints, small)) {
              font-size: $font-size-h4-mobile;
            }
          }
        }
        .offer-price-not-signed,
        .offer-price-not-signed span {
          margin-top: 16px;
          font-weight: $semi-bold;
          font-size: $font-size-h2-tablet;
          line-height: $title-line-height;
          @media screen and (max-width: map-get($breakpoints, small)) {
            display: none;
          }
        }
        .offer-price-not-signed-mobile {
          color: map-get(map-get($palette, secondary), 2);
          span {
            font-weight: $semi-bold;
            color: map-get(map-get($palette, primary), 2);
          }
        }
        .fully-subscribed {
          font-weight: $semi-bold;
          font-size: $font-size-h4-desktop;
          color: map-get(map-get($palette, secondary), 2);
          margin-left: 8px;
          @media screen and (max-width: map-get($breakpoints, medium)) {
            margin-left: 0px;
          }
          @media screen and (max-width: map-get($breakpoints, small)) {
            font-size: $font-size-h4-mobile;
          }
        }
        &.not-signed-header {
          margin-top: 40px;
          @media screen and (max-width: map-get($breakpoints, small)) {
            margin-top: 0px;
          }
        }
        &.fully-subscribed-header {
          flex-direction: row;
          display: flex;
          align-items: center;
          @media screen and (max-width: map-get($breakpoints, medium)) {
            flex-direction: column;
            align-items: flex-start;
          }
          @media screen and (max-width: map-get($breakpoints, small)) {
            flex-direction: row;
            align-items: center;
          }
        }
      }

      .invest-order {
        width: 50%;
        @media screen and (max-width: map-get($breakpoints, small)) {
          width: 100%;
        }
        .invest-order-header {
          font-size: $font-size-h4-desktop;
          font-weight: $semi-bold;
          margin-bottom: 16px;
          @media screen and (max-width: map-get($breakpoints, small)) {
            display: none;
          }
        }
        .invest-order-box {
          padding: 16px;
          margin-bottom: 16px;

          .invest-order-row {
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            align-items: center;
            margin-bottom: 16px;

            .price {
              font-weight: $semi-bold;
              font-size: $font-size-h4-mobile;
            }

            .slider {
              margin: 0 2rem;
              font-size: $font-size-h4-mobile;
            }
            .linqto-bucks-available {
              color: map-get(map-get($palette, secondary), 2);
            }

            .input-group.checkbox .checkbox-label {
              margin: 0px;
            }

            .input-group.checkbox {
              margin-top: 0px;
            }
            select {
              max-width: 65%;
            }
            &.top-aligned {
              align-items: flex-start;
            }
            &.check-box-row {
              align-items: flex-start;
              .linqto-checkbox-container {
                height: 48px;

                &.full-row {
                  width: 100%;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  @media screen and (max-width: map-get($breakpoints, small)) {
                    justify-content: space-between;
                  }

                  .linqto-bucks-available {
                    padding-left: 8px;
                  }
                }
                &.half-row {
                  width: 50%;
                  @media screen and (max-width: map-get($breakpoints, medium)) {
                    width: 100%;
                    justify-content: space-between;
                    display: flex;
                    align-items: center;
                  }
                  .linqto-bucks-available {
                    padding-left: 25px;
                    @media screen and (max-width: map-get($breakpoints, medium)) {
                      padding-left: 0px;
                    }
                  }
                }
              }

              .linqto-bucks-percent {
                color: map-get(map-get($palette, secondary), 2);
                max-width: 50%;
                @media screen and (max-width: map-get($breakpoints, small)) {
                  max-width: 100%;
                }
              }
              @media screen and (max-width: map-get($breakpoints, small)) {
                margin-top: -16px;
                flex-direction: column;
              }
            }
            &.linqto-bucks-not-used {
              color: map-get(map-get($palette, secondary), 2);
              line-height: $line-height;
            }
            &:last-child {
              margin-bottom: 0px;
            }
          }
        }

        &.fully-subscribed-cta {
          margin: 48px 0;
          @media screen and (max-width: map-get($breakpoints, small)) {
            margin: 0;
          }
        }
        &.sign-in-cta {
          margin-top: 102px;
          @media screen and (max-width: map-get($breakpoints, small)) {
            margin-top: 0px;
          }
        }
      }
    }
  }

  .invest-tabs-container {
    .page-tabs {
      margin-top: -61px;

      .tab {
        margin-right: 160px;
        @media screen and (max-width: map-get($breakpoints, small)) {
          margin-right: 0px;
        }
        span {
          @media screen and (max-width: map-get($breakpoints, small)) {
            font-size: $font-size-b1-mobile;
          }
        }
      }
    }
  }

  .invest-tab {
    padding: 24px 0px;
    @media screen and (max-width: map-get($breakpoints, small)) {
      padding: 8px 0px 0px;
    }
    .row {
      span {
        font-size: $font-size-b1-desktop;
        line-height: $line-height;
        color: map-get(map-get($palette, secondary), 2);
        &.dark {
          color: map-get(map-get($palette, primary), 2);
        }
        &.semi-bold {
          font-weight: $semi-bold;
        }
        &.padding-left {
          padding-left: 12px;
        }
        &.mobile-normal {
          @media screen and (max-width: map-get($breakpoints, small)) {
            font-weight: $regular;
          }
        }
        &.full-line {
          display: block;
        }
        &.one-line {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &.mobile-title {
          @media screen and (max-width: map-get($breakpoints, small)) {
            font-size: $font-size-h4-mobile;
          }
        }
        &.mobile-line {
          @media screen and (max-width: map-get($breakpoints, small)) {
            display: inline;
          }
        }
        &.half-line {
          display: inline-block;
          width: 50%;
        }
        &.under-line {
          text-decoration: underline;
          cursor: pointer;
        }
        &.sub-title {
          margin-bottom: 8px;
        }
        &.no-title {
          margin-top: 30px;
          display: inline-block;
          @media screen and (max-width: map-get($breakpoints, small)) {
            margin-top: 0px;
          }
        }
        &.mobile-spaced {
          @media screen and (max-width: map-get($breakpoints, small)) {
            margin-top: 24px;
          }
        }
        @media screen and (max-width: map-get($breakpoints, small)) {
          font-size: $font-size-b1-mobile;
        }
      }
      .website-link {
        font-weight: $semi-bold;
        color: map-get(map-get($palette, secondary), 2);
        text-decoration: underline;
        font-size: $font-size-b1-desktop;
        margin-left: -5px;
        @media screen and (max-width: map-get($breakpoints, small)) {
          display: block;
          margin-top: 16px;
          margin-bottom: -16px;
          font-size: $font-size-b1-mobile;
          margin-left: 0px;
        }
      }
      .desc {
        font-size: $font-size-b1-desktop;
        line-height: $line-height;
        color: map-get(map-get($palette, secondary), 2);
        @media screen and (max-width: map-get($breakpoints, small)) {
          font-size: $font-size-b1-mobile;
        }
      }
      .two-cols-text {
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;
        @media screen and (max-width: map-get($breakpoints, small)) {
          -webkit-column-count: 1;
          -moz-column-count: 1;
          column-count: 1;
        }
      }
      .grey-background {
        background: map-get(map-get($palette, secondary), 4);
        padding: 32px 36px;
        @media screen and (max-width: map-get($breakpoints, small)) {
          padding: 24px;
          margin-left: -24px;
          margin-right: -24px;
          margin-top: 16px;
        }
      }
      .rounded {
        border-radius: 8px;
        @media screen and (max-width: map-get($breakpoints, small)) {
          border-radius: 0px;
        }
      }
      .bordered {
        border: 0.5px solid map-get(map-get($palette, secondary), 3);
        padding: 16px;
        @media screen and (max-width: map-get($breakpoints, small)) {
          border: none;
          padding: 16px 0px;
        }
      }
      .floated-right {
        float: right;
        max-width: 202;
        @media screen and (max-width: map-get($breakpoints, small)) {
          float: none;
          max-width: auto;
        }
      }
      .title {
        font-size: $font-size-b1-desktop;
        line-height: $title-line-height;
        color: map-get(map-get($palette, primary), 2);
        font-weight: $semi-bold;
        margin-bottom: 42px;
        @media screen and (max-width: map-get($breakpoints, small)) {
          font-size: $font-size-b1-tablet;
          margin-bottom: 24px;
        }
      }
      li {
        font-size: $font-size-b1-desktop;
        line-height: $line-height;
        margin-left: 20px;
        margin-bottom: 36px;
        @media screen and (max-width: map-get($breakpoints, small)) {
          font-size: $font-size-b1-mobile;
          margin-bottom: 24px;
        }
      }
    }

    .ui.grid > [class*='two column mobile-one'].row > .column,
    .ui.grid > [class*='three column mobile-one'].row > .column {
      @media screen and (max-width: map-get($breakpoints, small)) {
        width: 100% !important;
      }
    }

    .ui.grid > .row {
      @media screen and (max-width: map-get($breakpoints, small)) {
        &.desktop-content {
          display: none;
        }
      }
      > .column {
        line-height: $line-height;
        @media screen and (max-width: map-get($breakpoints, small)) {
          &.desktop-content {
            display: none;
          }
        }
      }
      > .column > .ui.grid {
        margin-top: 0px;
        margin-bottom: 0px;

        > .row {
          padding-top: 0;
          padding-bottom: 0;

          &.unicorn-table-header {
            padding-bottom: 5px;
            margin-top: 24px;
          }
        }
      }
    }
    .section-seprator {
      border-bottom: 0.5px solid map-get(map-get($palette, secondary), 3);
      display: flex;
      @media screen and (max-width: map-get($breakpoints, small)) {
        display: none;
      }
    }
    .line-space {
      margin-top: 16px;
    }
    .mobile-grey {
      margin-left: 1rem;
      margin-right: 1rem;
      @media screen and (max-width: map-get($breakpoints, small)) {
        background-color: map-get(map-get($palette, secondary), 4);
        margin: 0px -0.5rem;
        padding: 32px 24px 0;
        min-width: calc(100% + 1rem);
      }
    }
  }
  .unicorn-card-row {
    .column {
      &.max-30 {
        max-width: 28%;
      }
      &.min-70 {
        min-width: 72%;
      }
    }
  }

  .ui.grid.invest-table {
    margin-left: 0px;
    margin-right: 0px;
    .unicorn-table-header {
      .column-right {
        padding-right: 16px;
        @media screen and (max-width: map-get($breakpoints, medium)) {
          padding-right: 8px;
        }
        @media screen and (max-width: map-get($breakpoints, small)) {
          padding-right: 6px;
        }
      }
      .column:first-child {
        padding-left: 0px;
      }
      div {
        font-size: $font-size-b1-desktop;
      }
    }
    .column {
      &.max-15 {
        max-width: 15%;
      }
      &.max-12 {
        max-width: 12%;
      }
      &.max-23 {
        max-width: 23%;
      }
      &.min-35 {
        min-width: 35%;
      }
      &.min-45 {
        min-width: 45%;
      }
      &.min-65 {
        min-width: 65%;
      }
      &.max-m-25 {
        @media screen and (max-width: map-get($breakpoints, small)) {
          max-width: 25%;
        }
      }
      &.min-m-50 {
        @media screen and (max-width: map-get($breakpoints, small)) {
          min-width: 50%;
        }
      }
    }
    .unicorn-table-body {
      div {
        font-size: $font-size-b1-desktop;
      }
      .column {
        min-height: 50px;
        font-size: $font-size-b1-desktop;
        color: map-get(map-get($palette, secondary), 2);
        &:first-child {
          padding-left: 0px;
        }
        &.column-right {
          align-items: flex-end;
          padding-right: 16px;
          @media screen and (max-width: map-get($breakpoints, medium)) {
            padding-right: 8px;
          }
          @media screen and (max-width: map-get($breakpoints, small)) {
            padding-right: 6px;
          }
        }
        &.column-gray {
          background: map-get(map-get($palette, secondary), 4);
        }
        &.column-bottom {
          height: 100%;
          display: flex;
          justify-content: flex-end;
          align-items: flex-end;
        }
        &.dark-color {
          color: map-get(map-get($palette, primary), 2);
        }
        &.semi-bold {
          font-weight: $semi-bold;
        }
      }
    }

    &.column-table.ui.grid {
      .row.unicorn-table-body:nth-child(even) {
        background: none;
      }
      .row.unicorn-table-body {
        align-items: flex-end;
        border-bottom: 0.5px solid map-get(map-get($palette, secondary), 3);
        .column {
          padding-top: 16px;
          padding-bottom: 16px;
        }
      }
      @media screen and (max-width: map-get($breakpoints, small)) {
        display: flex;
        .unicorn-table-header {
          div {
            font-size: $font-size-b1-mobile;
          }
        }
      }
    }
  }
  .fact-container {
    font-size: $font-size-b1-desktop;
    margin-bottom: 24px;
    @media screen and (max-width: map-get($breakpoints, small)) {
      font-size: $font-size-b1-mobile;
      margin-bottom: 16px;
    }

    .fact-items {
      overflow: hidden;
      font-size: $font-size-b1-desktop;
      @media screen and (max-width: map-get($breakpoints, small)) {
        font-size: $font-size-b1-mobile;
      }
      &.maxHeight {
        max-height: 215px;
        @media screen and (max-width: map-get($breakpoints, small)) {
          max-height: 195px;
        }
      }
    }
    .fact-title {
      margin-top: 8px;
      font-size: $font-size-b1-desktop;
      line-height: $line-height;
      font-weight: $semi-bold;
      @media screen and (max-width: map-get($breakpoints, small)) {
        font-size: $font-size-h4-mobile;
      }
    }
    .fact-item {
      margin-bottom: 32px;
      font-size: $font-size-b1-desktop;
      line-height: $line-height;
      color: map-get(map-get($palette, secondary), 2);
      @media screen and (max-width: map-get($breakpoints, small)) {
        font-size: $font-size-b1-mobile;
        margin-bottom: 24px;
      }
      &:last-child {
        margin-bottom: 0px;
      }
    }
    .icon {
      @media screen and (max-width: map-get($breakpoints, small)) {
        display: none;
      }
    }
    a {
      color: map-get(map-get($palette, primary), 1);
      cursor: pointer;
    }
  }
}

.no-padding-top {
  padding-top: 0 !important;
}
.no-padding-bottom {
  padding-bottom: 0 !important;
}

.outer-container {
  @media screen and (max-width: map-get($breakpoints, small)) {
    margin-bottom: 0px !important;
    padding-bottom: 0px !important;
  }
}
.chart-container {
  height: 360px;
  margin-top: 16px;
  background-color: map-get(map-get($palette, secondary), 4);
  margin-bottom: 10px;
}

.mobile-chart-container {
  overflow-x: auto;
  margin-bottom: 24px;
  @media screen and (max-width: map-get($breakpoints, small)) {
    margin-left: -24px;
    padding-left: 24px;
  }
}

.mobile-chart-container::-webkit-scrollbar:horizontal {
  height: 3px;
}
.mobile-chart-container::-webkit-scrollbar-thumb:horizontal {
  background: map-get(map-get($palette, secondary), 3);
  border-radius: 3px;
}
.mobile-chart-container::-webkit-scrollbar-track:horizontal {
  display: none;
}

.chart-footnote {
  font-size: $font-size-b1-mobile;
  color: map-get(map-get($palette, secondary), 2);
  @media screen and (max-width: map-get($breakpoints, small)) {
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 32px;
    }
  }
}

.table-footnote {
  margin: 24px 0;
  width: 100%;
  font-size: $font-size-b1-mobile;
  color: map-get(map-get($palette, secondary), 2);
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: break-word;

  div {
    display: inline;
    font-size: $font-size-b1-mobile;
  }
}

@media screen and (max-width: map-get($breakpoints, small)) {
  .stikey-tabs {
    position: fixed;
    top: 125px;
    width: 100%;
    left: 0px;
    padding-left: 24px;
    padding-right: 24px;
    border-bottom: 0.5px solid map-get(map-get($palette, secondary), 2);
    background: #fff;
    z-index: 10;
  }

  .stikey-padding {
    padding-top: 60px !important;
  }

  .stikey-header {
    position: fixed;
    top: 0;
    z-index: 10;
    width: 100%;
    max-height: 80px;
    left: 0;
    padding: 0px 24px;
    background: map-get(map-get($palette, primary), 3);
  }
}
