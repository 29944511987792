@import './status-bar';
@import './drop-area';
@import './request-modal';
@import './financial-advisor';

.investor-status-container {
  // margin: 60px 13vw 100px;
  min-height: 100vh;
  .info-container {
    // font-weight: $bold;
    margin: 30px 0 0 0;
    .accStatus {
      display: inline;
      margin-right: 5px;

      &.red {
        color: rgba(map-get(map-get($palette, status), 1), 1);
      }
      &.teal {
        color: map-get(map-get($palette, primary), 1);
      }
    }
    .info {
      font-weight: $regular;
      font-size: $font-size-b1-desktop;
      line-height: $line-height;
      @media screen and (max-width: map-get($breakpoints,medium)) {
        font-size: $font-size-b1-tablet;
      }
      @media screen and (max-width: map-get($breakpoints,small)) {
        font-size: $font-size-b1-mobile;
      }

      &.acc-info {
        margin: 5px 0;
        div {
          font-size: $font-size-b1-desktop;
          line-height: $line-height;
          @media screen and (max-width: map-get($breakpoints,medium)) {
            font-size: $font-size-b1-tablet;
          }
          @media screen and (max-width: map-get($breakpoints,small)) {
            font-size: $font-size-b1-mobile;
          }
        }
      }
    }
    .info-title {
      font-weight: $bold;
      font-size: $font-size-b1-desktop;
      line-height: $line-height;
      @media screen and (max-width: map-get($breakpoints,medium)) {
        font-size: $font-size-b1-tablet;
      }
      @media screen and (max-width: map-get($breakpoints,small)) {
        font-size: $font-size-b1-mobile;
      }

      @media screen and (max-width: map-get($breakpoints,small)) {
        &.info-group {
          align-items: flex-start;
        }
      }
    }
  }
  .header-content {
    margin-top: 16px;
  }
  .required-container {
    font-size: $font-size-b1-desktop;
    line-height: $line-height;
    font-weight: $bold;
    @media screen and (max-width: map-get($breakpoints,medium)) {
      font-size: $font-size-b1-tablet;
    }
    @media screen and (max-width: map-get($breakpoints,small)) {
      font-size: $font-size-b1-mobile;
    }
    margin-bottom: 1em;
    .info {
      margin: 15px 0;
    }
    .doc-header {
      margin-bottom: 25px;
      margin-top: 1rem;
      color: map-get(map-get($palette, primary), 1);
    }
    .file-group {
      display: flex;
      flex-direction: row;
      text-align: center;
      font-weight: $regular;
      margin-top: 20px;
      .file-btn {
        padding: 5px 10px;
        margin-right: 5px;
        color: map-get(map-get($palette, secondary), 3);
        cursor: pointer;
        &.selected {
          color: map-get(map-get($palette, primary), 2);
          border-bottom: 3px solid map-get(map-get($palette, primary), 1);
        }
      }
    }
    .info-title {
      padding: 1rem;
    }
  }
  .request-container {
    font-size: $font-size-b1-desktop;
    line-height: $line-height;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 10px 0 50px;
    @media screen and (max-width: map-get($breakpoints,medium)) {
      font-size: $font-size-b1-tablet;
    }
    @media screen and (max-width: map-get($breakpoints,small)) {
      font-size: $font-size-b1-mobile;
    }
    div {
      font-size: $font-size-b1-desktop;
      line-height: $line-height;
      @media screen and (max-width: map-get($breakpoints,medium)) {
        font-size: $font-size-b1-tablet;
      }
      @media screen and (max-width: map-get($breakpoints,small)) {
        font-size: $font-size-b1-mobile;
      }
    }
    .centered {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .request-info {
      ol {
        list-style-position: inside;
        li {
          font-size: $font-size-b1-desktop;
          line-height: $line-height;
          @media screen and (max-width: map-get($breakpoints,medium)) {
            font-size: $font-size-b1-tablet;
          }
          @media screen and (max-width: map-get($breakpoints,small)) {
            font-size: $font-size-b1-mobile;
          }
        }
      }
    }
    .top-margin {
      margin: 1.5em 0;
    }
  }
  .info-title.option {
    padding: 0;
  }
  .ui.grid {
    margin-top: 0;
    margin-bottom: 0;
  }
}
.support-container {
  text-align: right;
  a {
    text-decoration: underline;
    color: map-get(map-get($palette, primary), 2);
  }
}
.video-container {
  text-align: center;
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  .info-container {
    text-align: left;
  }
  .acc-video {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    @media screen and (max-width: map-get($breakpoints,medium)) {
      width: 100%;
    }
  }
}

.country-dropdown-self-accreditation {
  margin: 10px 30px;
  select {
    width: 100%;
  }
}

.accreditation-types-modal {
  ul {
    margin-left: 20px;
  }
}
