.landing-container {
  margin: 0 auto;
  .container {
    padding: 3.5rem 150px;
    @media screen and (max-width: map-get($breakpoints,large)) {
      padding: 3.5rem 60px;
    }
    @media screen and (max-width: map-get($breakpoints,medium)) {
      padding: 3.5rem 40px;
    }
    @media screen and (max-width: map-get($breakpoints,small)) {
      padding: 3.5rem 24px;
    }
  }
  .hero-header {
    color: map-get(map-get($palette, primary), 2);
    text-align: center;
    font-size: $font-size-h2-desktop;
    font-weight: $bold;
    @media screen and (max-width: map-get($breakpoints,medium)) {
      font-size: $font-size-h2-tablet;
    }
    @media screen and (max-width: map-get($breakpoints,small)) {
      font-size: $font-size-h2-mobile;
    }
  }
  .hero-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    .inner-hero-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      @media screen and (max-width: map-get($breakpoints,medium)) {
        flex-direction: column;
      }
      @media screen and (max-width: map-get($breakpoints,medium)) {
        padding-bottom: 0px !important;
      }
    }
    .hero-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .new-listing-news {
        margin-bottom: 1rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        flex-wrap: wrap;
        .new-listing-product {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
        .list-logo {
          margin: 0 5px;
          height: 30px;
          border-radius: 30px;
        }
        .list-content {
          white-space: nowrap;
          font-size: $font-size-h4-desktop;
          @media screen and (max-width: map-get($breakpoints,medium)) {
            font-size: $font-size-h4-tablet;
          }
          @media screen and (max-width: map-get($breakpoints,small)) {
            font-size: $font-size-h4-mobile;
          }
        }
        .list-name {
          font-size: $font-size-h4-desktop;
          font-weight: $bold;
          @media screen and (max-width: map-get($breakpoints,medium)) {
            font-size: $font-size-h4-tablet;
          }
          @media screen and (max-width: map-get($breakpoints,small)) {
            font-size: $font-size-h4-mobile;
          }
        }
        @media screen and (max-width: map-get($breakpoints,small)) {
          .list-content,
          .list-name {
            font-size: $font-size-h4-mobile;
          }
          flex-direction: column;
          align-items: flex-start;
          .list-logo {
            margin: 0;
            margin-right: 5px;
          }
        }
      }
      .hero-title {
        font-size: $font-size-h1-desktop;
        font-weight: $bold;
        line-height: $title-line-height;
        z-index: 3;
        max-width: 80%;
      }
      .hero-content {
        font-size: $font-size-h4-desktop;
        line-height: $line-height;
        max-width: 90%;
        margin: 1rem 0;
      }
      @media screen and (max-width: map-get($breakpoints,medium)) {
        .hero-title {
          font-size: $font-size-h1-tablet;
          max-width: 100%;
        }
        .hero-content {
          font-size: $font-size-h4-tablet;
        }
      }
      @media screen and (max-width: map-get($breakpoints,small)) {
        .hero-title {
          font-size: $font-size-h1-mobile;
          max-width: 100%;
        }
        .hero-content {
          font-size: $font-size-h4-mobile;
        }
      }
    }

    .img-container {
      position: relative;
      width: 450px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      @media screen and (max-width: map-get($breakpoints,medium)) {
        width: 100%;
        margin-top: 3rem;
      }
      @media screen and (max-width: map-get($breakpoints,medium)) {
        width: 100%;
        margin-top: 0;
      }
      .circle-bg {
        position: absolute;
        top: 100px;
        z-index: -10;
        background-color: rgba(map-get(map-get($palette, primary), 1), 0.1);
        height: 450px;
        width: 450px;
        border-radius: 300px;
      }

      .apps-group {
        z-index: 10;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .app-img {
          margin: 0 auto;
          z-index: 30;
          height: 600px;
          margin-bottom: 30px;
          @media screen and (max-width: map-get($breakpoints,small)) {
            height: 400px;
            margin-bottom: 0px;
          }
        }

        .app-icon-group {
          display: flex;
          flex-direction: row;
          justify-content: center;
          .app-store,
          .android {
            margin: 0 10px;
          }
          @media screen and (max-width: map-get($breakpoints,medium)) {
            .app-store,
            .android {
              max-width: 230px;
            }
          }
        }
        @media screen and (max-width: map-get($breakpoints,small)) {
          width: 100%;
          .app-icon-group {
            flex-direction: row;
            align-items: center;
            a {
              width: 100%;
              text-align: center;
              .app-store,
              .android {
                margin: 5px 0;
                width: 90%;
                max-width: 200px;
              }
            }
          }
        }
      }
      @media screen and (max-width: map-get($breakpoints,small)) {
        .circle-bg {
          display: none;
        }
        .apps-group {
          .app-img {
            width: 80%;
            transform: scale(1.1);
            z-index: -100;
            position: relative;
            top: 1.5rem;
            object-fit: cover;
            object-position: top;
            &.ismobile {
              top: 1.5rem;
            }
          }
          .app-content {
            margin-top: -1.5rem;
            background-color: map-get(map-get($palette, primary), 3);
            font-size: $font-size-h2-mobile;
            line-height: $line-height;
            font-weight: $semi-bold;
            text-align: center;
            padding: 10px 0;
          }
          .app-icon-group {
            background-color: map-get(map-get($palette, primary), 3);
            padding: 1.5rem 0 2rem;
          }
        }
      }
    }
  }
  .steps-container {
    background-color: map-get(map-get($palette, secondary), 4);
    .steps {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 3.5rem 0;
      .step-container {
        text-align: center;
        .step-title {
          color: map-get(map-get($palette, primary), 2);
          margin-top: 15px;
          font-size: $font-size-h3-desktop;
          font-weight: $semi-bold;
        }
        .icon {
          height: 220px;
        }
        @media screen and (max-width: map-get($breakpoints,large)) {
          .icon {
            height: 150px;
          }
          &:not(last-child) {
            margin-right: 1rem;
          }
          .step-title {
            font-size: $font-size-h4-desktop;
          }
        }
      }
      .line-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 30%;
        .line {
          width: 100%;
          min-width: 50px;
          max-width: 150px;
          height: 1px;
          margin: 0 15px;
          border-bottom: 1px solid
            rgba(map-get(map-get($palette, primary), 2), 0.25);
          @media screen and (max-width: map-get($breakpoints,medium)) {
            display: none;
          }
        }
      }
      @media screen and (max-width: map-get($breakpoints,small)) {
        flex-direction: column;
        margin: 0;
        margin-top: 3rem;
        .step-container {
          margin-bottom: 3rem;
          &:not(last-child) {
            margin-right: 0;
          }
        }
      }
    }
  }

  .uphold-container {
    display: flex;
    justify-content: center;
    background-color: rgba(map-get(map-get($palette, secondary), 4), 0.4);
    padding-bottom: 0;

    .uphold-inner-container {
      display: flex;
      flex-direction: row;
      @media screen and (max-width: map-get($breakpoints, medium)) {
        flex-direction: column;
        align-items: center;
      }
    }
    .text-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      max-width: 550px;
      color: map-get(map-get($palette, primary), 2);
      @media screen and (max-width: map-get($breakpoints, medium)) {
        align-items: center;
        text-align: center;
        margin: 20px 0;
      }

      .uphold-title {
        font-size: $font-size-h2-desktop;
        font-weight: $bold;
        @media screen and (max-width: map-get($breakpoints,medium)) {
          font-size: $font-size-h2-tablet;
        }
        @media screen and (max-width: map-get($breakpoints,small)) {
          font-size: $font-size-h2-mobile;
        }
      }
      .uphold-content {
        margin: 40px 0;
        font-size: $font-size-h4-desktop;
        line-height: $line-height;
        font-weight: $regular;
        @media screen and (max-width: map-get($breakpoints,medium)) {
          font-size: $font-size-h4-tablet;
        }
        @media screen and (max-width: map-get($breakpoints,small)) {
          font-size: $font-size-h4-mobile;
        }
      }
    }
    img {
      width: 500px;
      @media screen and (max-width: map-get($breakpoints, small)) {
        width: 400px;
      }
    }
  }
  .feature-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    .feature-group {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      width: 100%;
      padding: 50px 0;

      @media screen and (max-width: map-get($breakpoints,medium)) {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .feature {
        display: flex;
        flex-direction: row;
        &:not(:last-child) {
          margin-bottom: 80px;
        }
        .img-container {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          height: 100px;
          width: 100px;
          min-width: 100px;
          background-color: map-get(map-get($palette, primary), 3);
          box-shadow: 0px 0px 20px
            rgba(map-get(map-get($palette, primary), 2), 0.1);
          img {
            max-height: 50px;
          }
        }
        .right-group {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 0 0 1rem 3rem;
          .feat-title {
            color: map-get(map-get($palette, primary), 2);
            margin-bottom: 1rem;
            font-size: $font-size-h3-desktop;
            font-weight: $semi-bold;
          }
          .feat-content {
            font-size: $font-size-h4-desktop;
            color: map-get(map-get($palette, secondary), 2);
            line-height: $line-height;
          }
          @media screen and (max-width: map-get($breakpoints,medium)) {
            .feat-title {
              font-size: $font-size-h3-tablet;
            }
            .feat-content {
              font-size: $font-size-h4-tablet;
            }
          }
          @media screen and (max-width: map-get($breakpoints,small)) {
            .feat-title {
              font-size: $font-size-h3-mobile;
            }
            .feat-content {
              font-size: $font-size-h4-mobile;
            }
          }
        }
        @media screen and (max-width: map-get($breakpoints,small)) {
          flex-direction: column;
          align-items: center;
          .right-group {
            padding: 0 0;
            text-align: center;
            margin-top: 20px;
            .feat-content {
              line-height: $line-height;
            }
          }
        }
      }
    }
  }
  .app-container {
    display: flex;
    flex-direction: column;
    padding-bottom: 0 !important;
    @media screen and (max-width: map-get($breakpoints,medium)) {
      padding: 3.5rem 60px 0;
    }
    background-color: map-get(map-get($palette, secondary), 4);
    justify-content: center;
    align-items: center;
    .app-img {
      max-height: 500px;
      overflow: hidden;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;
      img {
        width: 100%;
        max-width: 650px;
        min-width: 350px;
        transform: scale(1);
      }
    }
    .intro-container {
      display: flex;
      flex-direction: column;
      padding: 40px 0;
      justify-content: center;

      .app-header {
        margin-bottom: 30px;
      }
      .app-content {
        font-size: $font-size-h3-desktop;
        text-align: center;
        line-height: $line-height;
      }
      @media screen and (max-width: map-get($breakpoints,medium)) {
        .app-header {
          margin-bottom: 20px;
        }
        .app-content {
          font-size: $font-size-h3-tablet;
        }
      }

      @media screen and (max-width: map-get($breakpoints,small)) {
        .app-content {
          font-size: $font-size-h3-mobile;
        }
      }

      .download-group {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 60px;
        .icon.app-store {
          margin-right: 20px;
        }
      }
      @media screen and (max-width: map-get($breakpoints,small)) {
        .app-content {
          font-size: $font-size-b1-desktop;
        }
        .download-group {
          .icon.app-store {
            margin-right: 10px;
          }
        }
      }
    }
    @media screen and (max-width: map-get($breakpoints,medium)) {
      .app-img {
        max-height: 300px;
        overflow: hidden;
        img {
          width: 100%;
        }
      }
    }
    @media screen and (max-width: map-get($breakpoints,small)) {
      padding: 3.5rem 24px 0;
      .app-img {
        max-height: 220px;
        overflow: hidden;
        img {
          width: 100%;
        }
      }
    }
  }

  .linqto-container {
    background-color: map-get(map-get($palette, secondary), 4);
    padding: 100px 60px;

    @media screen and (max-width: map-get($breakpoints,small)) {
      padding: 3.5rem 24px;
    }

    .inner-container {
      display: flex;
      flex-direction: row;

      @media screen and (max-width: map-get($breakpoints,medium)) {
        flex-direction: column;
        align-items: center;
        text-align: center;
      }
    }

    img {
      min-width: 416px;
      margin-right: 45px;
      @media screen and (max-width: map-get($breakpoints,medium)) {
        min-width: 60%;
        max-width: 60%;
        margin-right: 0;
        margin-bottom: 45px;
      }
      @media screen and (max-width: map-get($breakpoints,small)) {
        min-width: 80%;
      }
    }

    .linqto-header {
      font-weight: $bold;
      font-size: $font-size-h2-desktop;
      line-height: $title-line-height;
      margin-bottom: 24px;
      @media screen and (max-width: map-get($breakpoints,medium)) {
        font-size: $font-size-h2-tablet;
      }
      @media screen and (max-width: map-get($breakpoints,small)) {
        font-size: $font-size-h2-mobile;
      }
    }

    .linqto-sub-header {
      font-weight: $semi-bold;
      font-size: $font-size-h3-desktop;
      margin-bottom: 12px;
      @media screen and (max-width: map-get($breakpoints, medium)) {
        font-size: $font-size-h3-tablet;
      }
      @media screen and (max-width: map-get($breakpoints,small)) {
        font-size: $font-size-h3-mobile;
      }
    }

    .linqto-content {
      font-size: $font-size-h4-desktop;
      margin-bottom: 30px;
      line-height: $line-height;

      @media screen and (max-width: map-get($breakpoints,medium)) {
        font-size: $font-size-h4-tablet;
        max-width: 550px;
        margin: 0 auto 30px auto;
      }

      @media screen and (max-width: map-get($breakpoints,small)) {
        font-size: $font-size-h4-mobile;
      }
    }

    .linqto-signup {
      font-size: $font-size-b1-desktop;
      margin-top: 15px;

      @media screen and (max-width: map-get($breakpoints,small)) {
        margin-top: 30px;
      }

      a {
        color: map-get(map-get($palette, primary), 1);
        text-decoration: underline;

        @media screen and (max-width: map-get($breakpoints,small)) {
          display: block;
        }
      }
    }
  }

  .unicorns-container {
    background: linear-gradient(
        to right,
        transparent,
        map-get(map-get($palette, tealGradient), 1)
      ),
      linear-gradient(
        to bottom,
        map-get(map-get($palette, tealGradient), 3),
        map-get(map-get($palette, tealGradient), 2)
      );
    color: map-get(map-get($palette, primary), 3);
    padding: 60px 60px;
    overflow: hidden;
    position: relative;
    height: 700px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: map-get($breakpoints,medium)) {
      height: 460px;
    }
    @media screen and (max-width: map-get($breakpoints,small)) {
      padding: 3.5rem 24px;
      height: 350px;
    }

    .unicorn-backgeound {
      position: absolute;
      transform: translateX(-320px);
      @media screen and (max-width: map-get($breakpoints,medium)) {
        transform: translate(-11%, -50px);
        width: 1500px;
      }
      @media screen and (max-width: map-get($breakpoints,small)) {
        transform: translate(-11%, -60px);
        width: 800px;
      }
    }

    .hero-container {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;

      @media screen and (max-width: map-get($breakpoints,medium)) {
        justify-content: space-around;
      }
    }

    .linqto-header {
      font-weight: $bold;
      font-size: $font-size-h1-desktop;
      width: 90%;
      line-height: $title-line-height;
      @media screen and (max-width: map-get($breakpoints,medium)) {
        width: 100%;
        font-size: $font-size-h2-tablet;
        margin-left: auto;
        margin-right: auto;
      }
      @media screen and (max-width: map-get($breakpoints,small)) {
        font-size: $font-size-h2-mobile;
        width: 100%;
      }
    }
    .text-content {
      width: 50%;
      @media screen and (max-width: map-get($breakpoints,small)) {
        width: 100%;
      }
    }
    .linqto-content {
      font-size: $font-size-h4-desktop;
      margin-bottom: 20px;
      font-weight: $thin;
      width: 80%;
      line-height: $line-height;
      @media screen and (max-width: map-get($breakpoints,medium)) {
        width: 100%;
        font-size: $font-size-b1-tablet;
        margin-bottom: 40px;
      }
      @media screen and (max-width: map-get($breakpoints,small)) {
        font-size: $font-size-b1-mobile;
      }
    }

    .hero-img {
      width: 500px;
      cursor: pointer;
      @media screen and (max-width: map-get($breakpoints,medium)) {
        width: 300px;
      }
      @media screen and (max-width: map-get($breakpoints,small)) {
        width: 130px;
      }
    }

    .powered-by {
      font-size: $font-size-nav-desktop;
      display: flex;
      align-items: center;
      margin-bottom: 50px;
      margin-top: 20px;
      @media screen and (max-width: map-get($breakpoints,medium)) {
        margin-bottom: 30px;
      }
      @media screen and (max-width: map-get($breakpoints, small)) {
        margin-top: 5px;
        margin-bottom: 20px;
        font-size: $font-size-b2-desktop;
      }

      img {
        width: 135px;
        margin-left: 5px;
        @media screen and (max-width: map-get($breakpoints, small)) {
          width: 70px;
          margin-left: 0px;
        }
      }
    }
  }
}

.event-banner-container {
  background-color: map-get(map-get($palette, primary), 1);
  padding: 6px 1rem;
  color: map-get(map-get($palette, primary), 3);
  font-weight: $semi-bold;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  animation: showBanner 0.8s ease-in-out forwards;
  z-index: 0;
  user-select: none;
  &.hide-banner {
    animation: hideBanner 0.8s ease-in-out forwards;
  }
  @keyframes showBanner {
    0% {
      top: 0px;
      z-index: 60;
      display: none;
      visibility: hidden;
    }
    100% {
      top: 65px;
      z-index: 60;
      visibility: visible;
    }
  }
  @keyframes hideBanner {
    0% {
      top: 65px;
      z-index: 60;
      visibility: visible;
    }
    100% {
      top: 0px;
      z-index: 60;
      display: none;
      visibility: hidden;
    }
  }
  &:hover {
    color: map-get(map-get($palette, primary), 3);
  }
  .event-content-group {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .event-date {
      background-color: map-get(map-get($palette, primary), 3);
      color: map-get(map-get($palette, primary), 1);
      padding: 4px 8px;
      border-radius: 6px;
      margin: 0 1rem;
      font-size: $font-size-b2-desktop;
      text-transform: uppercase;
    }
    @media screen and (max-width: map-get($breakpoints,small)) {
      justify-content: flex-start;
      .event-date {
        font-size: $font-size-b3-desktop;
      }
      span {
        font-size: $font-size-b3-desktop;
      }
    }
  }
  .event-dismiss {
    position: absolute;
    right: 1rem;
    &:hover {
      opacity: 0.5;
    }
  }
}
