//Heading H1
$font-size-h1-desktop: 60px;
$font-size-h1-tablet: 48px;
$font-size-h1-mobile: 32px;
//Subheading H2
$font-size-h2-desktop: 48px;
$font-size-h2-tablet: 36px;
$font-size-h2-mobile: 24px;
//Title H3
$font-size-h3-desktop: 36px;
$font-size-h3-tablet: 24px;
$font-size-h3-mobile: 20px;
//Subtitle H4
$font-size-h4-desktop: 24px;
$font-size-h4-tablet: 20px;
$font-size-h4-mobile: 18px;
//Body B1
$font-size-b1-desktop: 20px;
$font-size-b1-tablet: 18px;
$font-size-b1-mobile: 16px;
//Body B2
$font-size-b2-desktop: 14px;
$font-size-b2-tablet: 14px;
$font-size-b2-mobile: 14px;
//Body B3
$font-size-b3-desktop: 12px;
$font-size-b3-tablet: 12px;
$font-size-b3-mobile: 12px;
//Body B4
$font-size-b4-desktop: 10px;
$font-size-b4-tablet: 10px;
$font-size-b4-mobile: 10px;
// Forms
$font-size-input-desktop: 16px;
$font-size-input-tablet: 16px;
$font-size-input-mobile: 16px;
// Nav
$font-size-nav-desktop: 18px;
$font-size-nav-tablet: 18px;
$font-size-nav-mobile: 18px;

$line-height: 1.5;
$small-line-height: 1.1;
$title-line-height: 96%;

$bold: 700;
$semi-bold: 600;
$regular: 400;
$thin: 300;

$btn-font-size-web: 24px;
$btn-font-size-tab: 24px;
$btn-font-size-phone: 18px;
