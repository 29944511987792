.manual_kyc_wrapper {
  margin-top: 20px;

  .first-col.column {
    padding-left: 0px !important;
  }

  .verfication-fields {
    margin-top: 50px;

    .ui.grid > .row {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .show-zone {
    display: block;
    margin-bottom: 10px;
  }

  .hide-zone {
    display: none;
  }
  .error-container {
    padding-left: 0;
  }

  .ui.small.header.form-header {
    padding-left: 0 !important;
  }

  @media screen and (max-width: map-get($breakpoints, medium)) {
    .ui.grid > .eight.wide.column.tablet_sixteen {
      width: 100% !important;
    }

    .ui.grid > .four.wide.column.tablet_eight,
    .ui.grid > .row > .four.wide.column.tablet_eight {
      width: 50% !important;
    }
    .ui.grid > .row > .three.wide.column {
      width: 16.6% !important;
      padding-left: 0px;
      padding-right: 8px;
    }
  }

  @media screen and (max-width: map-get($breakpoints, small)) {
    .ui.grid > .eight.wide.column.tablet_sixteen {
      width: 100% !important;
    }

    .ui.grid > .four.wide.column.tablet_eight,
    .ui.grid > .row > .four.wide.column.tablet_eight {
      width: 100% !important;
      padding: 0 !important;
    }
    .ui.grid > .row > .three.wide.column {
      width: 100% !important;
      padding: 0 !important;
    }
  }
}

.kyc-manual-modal .ui.segment {
  position: absolute;
  top: -10px;
  width: 100%;
  left: 0px;
  right: 0px;
  bottom: 0px;
  height: 535px !important;
}
