@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&family=Source+Sans+Pro:wght@300;400;600;700;900&display=swap');
@import './font.scss';
@import './color.scss';
// NOTE: since we are using max-width in our styling approach
// small => anything till tablet size 767
// medium => anything till desktop size 1023
// large => anything till large desktop size 1439
// smallest size product care about is 360px for mobile
$breakpoints: (
  small: 767px,
  medium: 1023px,
  large: 1439px,
);

*,
body,
html {
  font-family: 'Source Sans Pro', sans-serif !important;
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: $line-height;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
}

@import './layout/landing.scss';
@import './layout/top-listings';
@import './layout/nav.scss';
@import './layout/footer.scss';
@import './onboarding/signup.scss';
@import './onboarding/signin.scss';
@import './onboarding/activate.scss';
@import './onboarding/verify.scss';
@import './profile/profile.scss';
@import './profile/_progressbar.scss';
@import './confirmOrder/order-modal.scss';
@import './orderDetail/order-detail.scss';
@import './portfolio/portfolio.scss';
@import './confirmOrder/order-confirm';
@import './wallet/wallet.scss';
@import './confirmOrder/error-modal';
@import './contact.scss';
@import './about.scss';
@import './semantic.scss';
@import './not-found';
@import './investorStatus/investorStatus.scss';
@import './linqto.scss';
@import './Security/security';
@import './autoVerification';
@import './faq.scss';
@import './wallet/transfer-modal';
@import './rewards/_rewards.scss';
@import './imagecropper/_imagecropper.scss';
@import './manualverification/_manualverification.scss';
@import './linqtoPortfolio/_linqtoPortfolio.scss';
@import './unicorns/_unicorns';
@import './box.scss';
@import './accountBanner/_accountBanner.scss';
@import './invest/_invest.scss';
@import './invest/_invest-details.scss';
@import './identity.scss';
@import './origination/_origination.scss';
@import './origination/_origination-details.scss';

body {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  color: map-get(map-get($palette, primary), 2);
}

.ui.dimmer.modals.visible.active {
  height: 100%;
  position: fixed;
  overflow: hidden;
  overflow-y: hidden;
  margin: 0;
  bottom: 0 !important;
  right: 0 !important;
}

.ui.form .field.field {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    box-shadow: 0 0 0px 1000px map-get(map-get($palette, secondary), 4) inset !important;
    transition: background-color 5000s ease-in-out 0s;
  }
}

.ui.form {
  .selecting {
    select {
      box-shadow: 0 0 0px 1000px map-get(map-get($palette, secondary), 4) inset !important;
      transition: background-color 5000s ease-in-out 0s;
      border: none;
      &:focus {
        outline: none;
      }
    }
    .arrow {
      font-size: $font-size-input-desktop;
      color: map-get(map-get($palette, secondary), 2);
      padding: 0 0 2px;
      /*left line */
      top: -28px;
      right: -90%;
      height: 0px;
      width: 20px;
      position: relative;
      pointer-events: none;
      img {
        width: 20px;
      }
    }
  }
}

h2 {
  font-size: $font-size-h2-desktop;
  font-weight: $semi-bold;
  color: map-get(map-get($palette, primary), 2);
  margin-bottom: 24px;
  @media screen and (max-width: map-get($breakpoints, medium)) {
    font-size: $font-size-h2-tablet;
  }
  @media screen and (max-width: map-get($breakpoints, small)) {
    font-size: $font-size-h2-mobile;
  }
}

h3 {
  font-size: $font-size-h3-desktop;
  font-weight: $semi-bold;
  color: map-get(map-get($palette, primary), 2);
  margin-bottom: 24px !important;
  @media screen and (max-width: map-get($breakpoints, medium)) {
    font-size: $font-size-h3-tablet;
  }
  @media screen and (max-width: map-get($breakpoints, small)) {
    font-size: $font-size-h3-mobile;
  }
}

hr {
  margin-top: 24px;
  margin-bottom: 16px;
  color: map-get(map-get($palette, secondary), 2);
  border-width: 0.5px;
}
