.order-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  background-color: map-get(map-get($palette, primary), 3);
  .order-modal-inner {
    .error {
      text-align: center;
      padding: 0rem 2rem;
      color: map-get(map-get($palette, error), 1);
    }

    .order-modal-title {
      font-weight: $semi-bold;
      font-size: $font-size-b1-desktop;
      display: block;
      text-align: center;
      margin: 10px 0 25px 0;
      @media screen and (max-width: map-get($breakpoints,medium)) {
        font-size: $font-size-b1-tablet;
      }
      @media screen and (max-width: map-get($breakpoints,small)) {
        margin: 20px 0 20px 0;
        font-size: $font-size-b1-mobile;
      }
    }

    .or-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      margin: 1rem 0;
      @media screen and (max-width: map-get($breakpoints,small)) {
        margin: 0.5rem 0 1rem 0;
      }

      .line {
        width: 50%;
        border: 1px solid map-get(map-get($palette, secondary), 3);
      }

      .or {
        margin: 0rem 1rem;
        text-transform: uppercase;
        color: map-get(map-get($palette, primary), 2);
        font-size: $font-size-b1-mobile;
        font-weight: $semi-bold;
      }
    }
    .wire-link-container {
      width: 100%;
      display: flex;
      justify-content: center;
      margin: 8px 0 0;
      padding: 0 16px;
      & > span {
        font-weight: $regular;
        font-size: $font-size-b1-desktop;
        line-height: 30.17px;
        color: map-get(map-get($palette, secondary), 2);
        & > span {
          cursor: pointer;
          font-weight: $semi-bold;
          font-size: $font-size-b1-desktop;
          line-height: 30.17px;
          color: map-get(map-get($palette, primary), 1);
          @media screen and (max-width: map-get($breakpoints, medium)) {
            font-size: $font-size-b1-mobile;
          }
        }
        @media screen and (max-width: map-get($breakpoints, medium)) {
          font-size: $font-size-b1-mobile;
        }
      }
      @media screen and (max-width: map-get($breakpoints, medium)) {
        margin: 0;
      }
    }
    .order-uphold {
      padding: 10px 0;

      .uphold-account {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        border-radius: 8px;
        margin-bottom: 10px;
        box-shadow: 0 0 10px 0px
            rgba(map-get(map-get($palette, primary), 2), 0.08),
          inset 0 0 0 2px rgba(map-get(map-get($palette, primary), 2), 0.05);
        cursor: pointer;
        .left-group {
          display: flex;
          flex-direction: row;
          align-items: center;
          .currency {
            font-size: $font-size-h4-desktop;
            font-weight: $semi-bold;
            margin: 0 1rem;
          }
          img {
            height: 40px;
          }
        }
        .balance {
          font-size: $font-size-b1-desktop;
        }
        @media screen and (max-width: map-get($breakpoints, small)) {
          .left-group {
            .currency {
              font-size: $font-size-h4-tablet;
            }
          }
        }
        @media screen and (max-width: map-get($breakpoints, small)) {
          .left-group {
            .currency {
              font-size: $font-size-h4-mobile;
            }
          }
          .balance {
            font-size: $font-size-b2-mobile;
          }
        }
        &.uphold-disabled {
          opacity: 0.5;
          cursor: default;
        }
      }
    }
    .uphold-no-funds {
      font-size: $font-size-b1-mobile;
      @media screen and (max-width: map-get($breakpoints, small)) {
        margin-top: 15px;
        margin-bottom: 15px;
        width: 100%;
        text-align: center;
      }
    }
    .uphold-error-container {
      .wire-link-container {
        margin-top: 16px;
        @media screen and (max-width: map-get($breakpoints, small)) {
          margin-top: 0px;
        }
      }
    }
  }
  .order-modal-upload-wrapper {
    margin-top: -27px;
    @media screen and (max-width: map-get($breakpoints, small)) {
      margin-top: 0px;
    }
  }
  @media screen and (max-width: map-get($breakpoints, small)) {
    .order-modal-upload-wrapper-revs {
      display: flex;
      flex-direction: column-reverse;
    }
  }
}

.insufficient-funds {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: map-get($breakpoints, small)) {
    flex-direction: column;
    align-items: stretch;
    text-align: center;
    width: 100%;
  }
}

.uphold-payment-copy {
  color: #0e1624;
  font-weight: $regular;
  font-size: $font-size-b1-desktop;
  line-height: 30px;
  margin-bottom: 16px;
  @media screen and (max-width: map-get($breakpoints, medium)) {
    font-size: $font-size-b1-tablet;
  }
  @media screen and (max-width: map-get($breakpoints, small)) {
    font-size: $font-size-b1-mobile;
    line-height: 24px;
    margin-bottom: 8px;
  }
}

.uphold-btn-container {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;

  .uphold-side-container {
    min-width: 33%;
    max-width: 33%;
    margin-top: 35px;
    & > span {
      font-size: $font-size-b1-desktop;
      line-height: 30px;
      font-weight: $regular;
      color: map-get(map-get($palette, secondary), 2);
      margin-left: 17px;
    }
    @media screen and (max-width: map-get($breakpoints,large)) {
      display: none;
    }
  }

  @media screen and (max-width: map-get($breakpoints,large)) {
    width: 100%;
    display: flex;
  }

  .uphold-btn {
    display: flex;
    justify-content: center;
    @media screen and (max-width: map-get($breakpoints,large)) {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
}

.mb-4-0 {
  margin-bottom: 4px !important;
  @media screen and (max-width: map-get($breakpoints, small)) {
    margin-bottom: 0px !important;
  }
}
