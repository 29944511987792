.request-modal {
  .request-label {
    background-color: map-get(map-get($palette, primary), 1) !important;
    color: map-get(map-get($palette, primary), 3);
    text-align: center;
  }
  .content {
    .success-msg {
      display: flex;
      flex-direction: row;
      align-items: center;
      .msg {
        margin-left: 10px;
      }
    }
  }
}
