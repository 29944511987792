.rewards-container {
  .rewards-header {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: map-get($breakpoints, medium)) {
      display: block;
      flex-direction: column;
      text-align: center;
    }
    .page-title {
      @media screen and (max-width: map-get($breakpoints, medium)) {
        margin-bottom: 0px;
      }
    }
    .my-bucks {
      font-weight: $bold;
      color: map-get(map-get($palette, primary), 1);
      font-size: $font-size-h4-desktop;
      border: 1px solid map-get(map-get($palette, primary), 1);
      height: 47px;
      line-height: $line-height;
      padding: 0px 20px;
      white-space: nowrap;
      display: flex;
      align-items: center;

      @media screen and (max-width: map-get($breakpoints, medium)) {
        margin: 12px auto;
        display: inline-block;
        font-size: $font-size-h3-tablet;
        height: 40px;
      }
      @media screen and (max-width: map-get($breakpoints, small)) {
        font-size: $font-size-h3-mobile;
        height: 32px;
      }
    }
  }

  .title-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem;

    .title {
      font-size: $font-size-h4-desktop;
      font-weight: $semi-bold;
      display: flex;
      align-items: center;

      @media screen and (max-width: map-get($breakpoints, medium)) {
        width: 90%;
        font-size: $font-size-h4-tablet;
      }
      @media screen and (max-width: map-get($breakpoints, small)) {
        width: 100%;
        font-size: $font-size-h4-mobile;
        margin-top: 1rem;
        margin-bottom: 1rem;
      }
    }

    @media screen and (max-width: map-get($breakpoints, small)) {
      flex-direction: column;
      align-items: center;
      margin-bottom: 0.9rem;

      .title {
        margin-bottom: 1rem;
      }
    }
  }

  .rewards-share-container {
    background-color: map-get(map-get($palette, secondary), 4);

    padding: 30px 36px 36px 36px;
    border-radius: 8px;

    @media screen and (max-width: map-get($breakpoints, medium)) {
      padding: 25px;
    }
    .rewards-share-inner {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;

      @media screen and (max-width: map-get($breakpoints, medium)) {
        align-items: flex-start;
      }

      @media screen and (max-width: map-get($breakpoints, small)) {
        flex-direction: column;
        align-items: center;
      }
    }

    .note {
      font-size: $font-size-b1-desktop;
      margin-top: 8px;

      @media screen and (max-width: map-get($breakpoints, medium)) {
        font-size: $font-size-b1-tablet;
      }

      @media screen and (max-width: map-get($breakpoints, small)) {
        margin-top: 0px;
        margin-bottom: 5px;
        font-size: $font-size-b1-mobile;
      }
    }

    .rewards-share-img {
      max-width: 196px;

      @media screen and (max-width: map-get($breakpoints, medium)) {
        max-width: 156px;
      }
    }

    .rewards-info {
      margin-top: 15px;
      @media screen and (max-width: map-get($breakpoints, medium)) {
        font-size: $font-size-b1-tablet;
        strong {
          font-size: $font-size-b1-mobile;
        }
      }
      @media screen and (max-width: map-get($breakpoints, small)) {
        font-size: $font-size-b1-mobile;
        strong {
          font-size: $font-size-b1-mobile;
        }
      }
    }
  }

  .rewards-share {
    display: flex;
    flex-direction: column;
    width: 100%;

    .title-group {
      margin-bottom: 1rem;

      @media screen and (max-width: map-get($breakpoints, small)) {
        align-items: flex-start;
      }
    }

    .share-text {
      font-size: $font-size-b3-desktop;
      font-weight: $semi-bold;
      color: map-get(map-get($palette, secondary), 3);
      height: 15px;
      position: absolute;
      right: 16px;
      top: 12px;
      cursor: default;

      @media screen and (max-width: map-get($breakpoints, medium)) {
        right: 0px;
        top: 44px;
      }
    }
    .input-group {
      label {
        font-weight: $regular;
        color: map-get(map-get($palette, primary), 2);

        .children {
          margin-left: 10px;
        }
        a {
          text-decoration: underline;
        }
      }
      &.checkbox {
        .checkbox-label {
          align-items: unset;
          input {
            width: 20px;
          }
        }
      }
    }
  }

  .input-share-container {
    display: flex;
    flex-direction: row;

    @media screen and (max-width: map-get($breakpoints, small)) {
      flex-direction: column;
    }

    .actions-share {
      padding-top: 5px;

      @media screen and (max-width: map-get($breakpoints, small)) {
        margin-top: 20px;
        text-align: center;
        padding-top: 0px;
      }
    }

    .share-btn {
      width: 30px;
      height: 30px;
      background: map-get(map-get($palette, primary), 3);
      border: 0;
      outline: 0;
      border-radius: 50%;
      cursor: pointer;
      margin: 0 5px;
      transition: all ease-in-out 0.1s;
      cursor: pointer;

      @media screen and (max-width: map-get($breakpoints, small)) {
        width: 40px;
        height: 40px;
        margin: 0 6px;
      }

      img {
        width: 18px;
        height: 18px;
        transition: all ease-in-out 0.1s;
      }

      &:hover {
        background: rgba(map-get(map-get($palette, primary), 3), 1);
      }

      &:hover img {
        transform: scale(1.1);
      }
    }
    &.disabled {
      .input-share {
        input {
          font-weight: $regular;
          color: map-get(map-get($palette, secondary), 3);
          cursor: default;
          outline: none;
          font-size: $font-size-input-desktop;
        }

        .ghost-input {
          cursor: default;
          font-size: $font-size-input-desktop;
        }
      }
      .share-btn {
        cursor: default;
        img {
          opacity: 0.2;
        }
      }
      .copy-btn {
        cursor: default;
        opacity: 0.3;
      }
    }
  }

  .input-share {
    position: relative;
    margin-right: 5px;
    width: 70%;

    @media screen and (max-width: map-get($breakpoints, medium)) {
      width: 66%;
      margin-right: 0px;
    }

    @media screen and (max-width: map-get($breakpoints, small)) {
      width: 100%;
      margin-right: 0px;
    }

    input[type='text'] {
      width: 100%;
      height: 40px;
      background: map-get(map-get($palette, primary), 3);
      border-radius: 8px;
      border: 0;
      padding: 11px 16px;
      font-weight: $semi-bold;
      font-size: $font-size-input-desktop;
      text-overflow: ellipsis;
      cursor: pointer;

      @media screen and (max-width: map-get($breakpoints, medium)) {
        min-width: 100%;
        padding: 11px 35px 11px 11px;
        font-size: $font-size-b2-desktop;
      }
    }

    .copy-btn {
      width: 30px;
      height: 30px;
      background: transparent;
      border: 0;
      outline: 0;
      cursor: pointer;
      position: absolute;
      right: 10px;
      top: 5px;

      @media screen and (max-width: map-get($breakpoints, medium)) {
        right: 5px;
      }

      img {
        width: 20px;
        height: 20px;
        transition: all ease-in-out 0.1s;
      }

      &:hover img {
        transform: scale(1.1);
      }
    }

    .ghost-input {
      font-weight: $semi-bold;
      font-size: $font-size-b1-desktop;
      position: absolute;
      width: 100%;
      height: 30px;
      display: block;
      top: 11px;
      left: 16px;
      opacity: 0;
      cursor: pointer;

      @media screen and (max-width: map-get($breakpoints, medium)) {
        top: 13px;
        left: 11px;
        white-space: nowrap;
        overflow: hidden;
        width: 90%;
        font-size: $font-size-b2-desktop;
      }
    }

    .ghost-input[copied='1'] {
      opacity: 0;
      animation-name: ghosting;
      animation-duration: 0.9s;
    }
  }

  .rewards-steps {
    margin-top: 30px;
    margin-bottom: 60px;
    line-height: $line-height;
    font-size: $font-size-b1-desktop;

    @media screen and (max-width: map-get($breakpoints, medium)) {
      font-size: $font-size-b1-tablet;
    }

    @media screen and (max-width: map-get($breakpoints, small)) {
      font-size: $font-size-b1-mobile;
      margin-bottom: 30px;
    }

    .rewards-steps-title {
      font-weight: $semi-bold;
      font-size: $font-size-h4-desktop;
      margin-bottom: 5px;
      @media screen and (max-width: map-get($breakpoints, medium)) {
        font-size: $font-size-h4-tablet;
      }
      @media screen and (max-width: map-get($breakpoints, small)) {
        font-size: $font-size-h4-mobile;
      }
    }
  }
  .rewards-features-container {
    .rewards-features-title {
      line-height: $line-height;
      font-size: $font-size-b1-desktop;
      margin-bottom: 10px;

      @media screen and (max-width: map-get($breakpoints, medium)) {
        margin-top: 20px;
        margin-bottom: 20px;
        font-size: $font-size-b1-tablet;
      }

      @media screen and (max-width: map-get($breakpoints, small)) {
        font-size: $font-size-b1-mobile;
      }
    }
    .rewards-features-details {
      background-color: map-get(map-get($palette, secondary), 4);
      padding: 30px 36px 36px 36px;
      border-radius: 8px;
      display: flex;
      margin-top: 30px;

      @media screen and (max-width: map-get($breakpoints, medium)) {
        align-items: end;
      }

      @media screen and (max-width: map-get($breakpoints, small)) {
        flex-direction: column-reverse;
        align-items: center;
        padding: 22px;
        margin-top: 15px;
      }

      ol {
        clear: both;
        list-style: none;
        padding-left: 20px;
      }

      ol li {
        padding: 5px 21px;
        display: block;
        position: relative;
        counter-increment: inst;
        line-height: $line-height;
        font-size: $font-size-b1-desktop;

        @media screen and (max-width: map-get($breakpoints, medium)) {
          font-size: $font-size-b1-tablet;
        }

        @media screen and (max-width: map-get($breakpoints, small)) {
          margin-bottom: 10px;
          padding: 4px 5px 5px 21px;
          font-size: $font-size-b1-mobile;
        }
      }

      li::before {
        content: counter(inst);
        font-weight: $semi-bold;
        font-size: $font-size-b1-desktop;
        background: map-get(map-get($palette, primary), 1);
        color: map-get(map-get($palette, primary), 3);
        text-align: center;
        height: 30px;
        width: 30px;
        line-height: $line-height;
        position: absolute;
        transition: all 0.2s ease-in-out;
        left: -20px;
        border-radius: 50%;

        @media screen and (max-width: map-get($breakpoints, medium)) {
          font-size: $font-size-b1-tablet;
        }

        @media screen and (max-width: map-get($breakpoints, small)) {
          top: 10px;
          font-size: $font-size-b1-mobile;
        }
      }
    }
    .rewards-features-img {
      min-width: 400px;
      @media screen and (max-width: map-get($breakpoints, medium)) {
        min-width: 148px;
      }
      @media screen and (max-width: map-get($breakpoints, small)) {
        max-width: 170px;
      }
    }
    .rewards-features-text {
      padding-right: 120px;
      @media screen and (max-width: map-get($breakpoints, medium)) {
        padding-right: 0px;
      }

      .rewards-features-text-title {
        font-size: $font-size-h4-desktop;
        font-weight: $semi-bold;
        margin-bottom: 20px;
        @media screen and (max-width: map-get($breakpoints, medium)) {
          font-size: $font-size-h4-tablet;
        }
        @media screen and (max-width: map-get($breakpoints, small)) {
          font-size: $font-size-h4-mobile;
          margin-top: 20px;
          text-align: center;
        }
      }
    }
    .rewards-features-desc {
      margin-top: 20px;
      @media screen and (max-width: map-get($breakpoints, medium)) {
        margin-top: 10px;
      }

      @media screen and (max-width: map-get($breakpoints, small)) {
        font-size: $font-size-b3-mobile;
      }

      .rewards-features-desc-title {
        font-size: $font-size-h4-desktop;
        margin-bottom: 10px;
        @media screen and (max-width: map-get($breakpoints, medium)) {
          font-size: $font-size-h4-tablet;
        }
        @media screen and (max-width: map-get($breakpoints, small)) {
          margin-top: 20px;
          margin-bottom: 20px;
          text-align: center;
          font-size: $font-size-h4-mobile;
        }
      }
      a {
        text-decoration: underline;
        @media screen and (max-width: map-get($breakpoints, small)) {
          font-size: $font-size-b3-mobile;
        }
      }
    }

    strong {
      font-size: $font-size-b1-desktop;
      @media screen and (max-width: map-get($breakpoints,medium)) {
        font-size: $font-size-b1-tablet;
      }
      @media screen and (max-width: map-get($breakpoints,small)) {
        font-size: $font-size-b1-mobile;
      }
    }
    .rewards-terms {
      font-size: $font-size-b3-desktop;
      color: map-get(map-get($palette, primary), 2);
      display: flex;
      justify-content: flex-end;

      a {
        color: map-get(map-get($palette, primary), 2);
        text-decoration: underline;
        font-size: $font-size-b3-desktop;
        margin-left: 5px;
      }
    }
  }

  .rewards-history-container {
    .title {
      font-size: $font-size-b1-desktop;
      font-weight: $semi-bold;
      line-height: $title-line-height;
      color: map-get(map-get($palette, primary), 1);
      border-bottom: 1px solid map-get(map-get($palette, secondary), 3);
      padding-bottom: 16px;
    }

    .order-list-container {
      margin: 40px 0 85px;
      min-height: auto;

      @media screen and (max-width: map-get($breakpoints, medium)) {
        margin: 20px 0 20px;
      }
    }

    .order-headers {
      padding-left: 10px;
      padding-right: 10px;

      .ui.grid > .column:not(.row),
      .ui.grid > .row > .column {
        padding-left: 5px !important;
        padding-right: 5px !important;
      }

      .text-left {
        text-align: left;
      }
    }

    .order {
      cursor: default;
      padding: 14px 10px;

      &:hover {
        box-shadow: 0 0 10px 0px
            rgba(map-get(map-get($palette, primary), 2), 0.08),
          inset 0 0 0 2px rgba(map-get(map-get($palette, primary), 2), 0.05);
      }

      .text-left {
        text-align: left;
      }

      .one.column {
        @media screen and (max-width: map-get($breakpoints, small)) {
          display: none;
        }
      }
    }
  }

  .rewards-support-container {
    display: flex;
    justify-content: flex-end;
    font-weight: $semi-bold;
    color: map-get(map-get($palette, primary), 1);
    font-size: $font-size-b1-desktop;

    @media screen and (max-width: map-get($breakpoints, small)) {
      justify-content: center;
    }

    a {
      color: map-get(map-get($palette, secondary), 2);
      margin-left: 5px;
      text-decoration: underline;
    }
  }

  .page-title {
    @media screen and (max-width: map-get($breakpoints, small)) {
      margin-bottom: 1rem;
      font-size: $font-size-h4-mobile;
      line-height: $title-line-height;
      padding: 0;
    }
  }

  .desktop-hidden {
    display: none;

    @media screen and (max-width: map-get($breakpoints, medium)) {
      display: block;
    }
  }
  @media screen and (max-width: map-get($breakpoints, small)) {
    .contact-support-container {
      justify-content: center;
    }
  }
}

@keyframes ghosting {
  0% {
    opacity: 0;
    transform: translate(0, 0);
  }

  25% {
    opacity: 0.25;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 0;
    transform: translate(0, -18px);
  }
}
