.fa-container {
  .header {
    font-size: $font-size-h3-desktop;
    font-weight: $semi-bold;
    line-height: $title-line-height;
    color: map-get(map-get($palette, secondary), 2);
    @media screen and (max-width: map-get($breakpoints,small)) {
      font-size: $font-size-h3-mobile;
    }
  }
  ol {
    counter-reset: list;
    padding-left: 28px;
    @media screen and (max-width: map-get($breakpoints,small)) {
      padding-left: 22px;
    }
  }
  ol > li {
    list-style: none;
  }
  ol > li:before {
    content: counter(list, lower-alpha) ') ';
    counter-increment: list;
  }
  h3 {
    margin: 24px 0;
  }
  .fa-checkbox.checkbox {
    margin-bottom: -4px;
    margin-top: 16px;
    width: 100%;
    @media screen and (max-width: map-get($breakpoints,small)) {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      margin-bottom: -8px;
    }
  }
  .fa-checkbox.input-group.checkbox .checkmark {
    position: absolute !important;
    left: 0;
    top: auto;
    height: 18px;
    width: 18px;
    border-radius: 2px;
    border: 1.5px solid map-get(map-get($palette, secondary), 3);
    &:after {
      left: 4px;
    }
  }
  .checkbox-content {
    color: map-get(map-get($palette, secondary), 2);
    display: flex;
    flex-direction: row;
    align-items: center;
    @media screen and (max-width: map-get($breakpoints,small)) {
      display: inline-block;
      line-height: 1;
    }
  }
  .FA-submit-success.content {
    color: map-get(map-get($palette, primary), 2);
    font-size: $font-size-nav-desktop;
    @media screen and (max-width: map-get($breakpoints,small)) {
      font-size: $font-size-b1-mobile;
    }
  }
  .ui.grid {
    margin-top: 8px;
    @media screen and (max-width: map-get($breakpoints,small)) {
      margin-right: -0.5rem;
      margin-left: -0.5rem;
    }
  }
  .ui.grid > .column:not(.row) {
    @media screen and (max-width: map-get($breakpoints,small)) {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }
  }
  .link.primary {
    margin-top: -3px;
    @media screen and (max-width: map-get($breakpoints,small)) {
      display: inline-block;
      font-size: $font-size-b1-mobile;
      text-align: left;
      line-height: 48px;
      min-height: 0;
      width: fit-content;
    }
    &.contact-us {
      font-size: $btn-font-size-web;
      @media screen and (max-width: map-get($breakpoints,small)) {
        font-size: $btn-font-size-phone;
      }
    }
  }
}
