.box {
  box-shadow: 0px 0px 20px rgba(map-get(map-get($palette, secondary), 6), 0.1),
    inset 0px 0px 0px 2px rgba(map-get(map-get($palette, secondary), 6), 0.06);
  border-radius: 8px;
  margin-bottom: 24px;
  overflow: hidden;

  @media screen and (max-width: map-get($breakpoints, small)) {
    &:not(.product-order):not(.agreement):not(.invest-order-box):not(.orginiation-order-box):not(.tooltip-box):not(.search-box):not(.message-box) {
      box-shadow: none;
      margin: 12px 0 32px 0;
    }
  }
  .box-title {
    min-height: 56px;
    padding: 0px 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: $font-size-b1-desktop;
    font-weight: $semi-bold;
    border-bottom: 1px solid rgba(map-get(map-get($palette, secondary), 3), 0.2);

    &.top-border {
      border-top: 1px solid rgba(map-get(map-get($palette, secondary), 3), 0.2);
    }

    @media screen and (max-width: map-get($breakpoints,medium)) {
      font-size: $font-size-b1-tablet;
    }

    @media screen and (max-width: map-get($breakpoints, small)) {
      min-height: 48px;
      padding: 0px;
      border-bottom: 0px;
      flex-direction: column;
      align-items: flex-start;
      justify-content: left;
      font-size: $font-size-b1-mobile;
      span:last-of-type {
        margin: 1rem 0;
        font-weight: $regular;
      }
    }
    span {
      font-size: $font-size-b1-desktop;
      @media screen and (max-width: map-get($breakpoints,medium)) {
        font-size: $font-size-b1-tablet;
      }

      @media screen and (max-width: map-get($breakpoints,small)) {
        font-size: $font-size-b1-mobile;
      }
    }
  }

  .box-body {
    display: flex;
    align-items: left;
    flex-direction: column;
    justify-content: center;

    .content {
      margin: 20px 20px 10px;
      @media screen and (max-width: map-get($breakpoints, small)) {
        margin: 4px 0;
      }
    }
  }

  .box-content {
    padding: 25px 10px;
  }
  .box-item {
    min-height: 85px;
    padding: 0px 25px;
    display: flex;
    align-items: center;
    flex-direction: row;
    font-size: $font-size-b1-desktop;
    border-bottom: 1px solid rgba(map-get(map-get($palette, secondary), 3), 0.2);

    @media screen and (max-width: map-get($breakpoints,medium)) {
      font-size: $font-size-b1-tablet;
    }

    @media screen and (max-width: map-get($breakpoints, small)) {
      min-height: 64px;
      padding: 0px;
      border-bottom: 0px;
      font-size: $font-size-b1-mobile;
    }

    &.centered {
      @media screen and (max-width: map-get($breakpoints, small)) {
        justify-content: center;
      }
    }

    &:last-child {
      border-bottom: none;
    }

    .box-item-action {
      margin-left: auto;
      margin-top: 0px;
      margin-bottom: 0px;
    }

    .box-item-icon img {
      width: 35px;
      height: 35px;

      @media screen and (max-width: map-get($breakpoints, small)) {
        display: none;
      }
    }

    .box-item-description {
      line-height: $line-height;
      margin: 0 15px 0 30px;
      font-size: $font-size-b1-desktop;
      @media screen and (max-width: map-get($breakpoints,medium)) {
        font-size: $font-size-b1-tablet;
      }
      @media screen and (max-width: map-get($breakpoints,small)) {
        font-size: $font-size-b1-mobile;
        margin: 0 10px 0 0;
      }

      .box-item-sub-title {
        color: map-get(map-get($palette, secondary), 3);
        font-size: $font-size-b2-desktop;
        display: block;

        @media screen and (max-width: map-get($breakpoints, small)) {
          display: none;
        }
      }
    }

    .box-item-column {
      flex-direction: column;
      align-items: baseline;

      &.status-unverified {
        display: flex;
        padding: 25px 0;
        @media screen and (max-width: map-get($breakpoints, small)) {
          padding: 0;
        }
        span {
          margin-bottom: 16px;
          line-height: $line-height;
          font-size: $font-size-b1-desktop;
          @media screen and (max-width: map-get($breakpoints, medium)) {
            font-size: $font-size-b1-tablet;
          }
          @media screen and (max-width: map-get($breakpoints, small)) {
            font-size: $font-size-b1-mobile;
          }
        }
      }
      &.status-verified {
        width: 100%;
        min-height: 100px;
        display: flex;
        justify-content: center;
      }
    }

    .verified {
      img {
        @media screen and (max-width: map-get($breakpoints, small)) {
          width: 18px;
          height: 18px;
          margin-top: 2px;
        }
      }
    }
  }
}

.search-box {
  overflow: unset;
}

.box-group {
  display: flex;
  flex-direction: row;
  gap: 32px;
  @media screen and (max-width: map-get($breakpoints, small)) {
    flex-direction: column;
  }
}
