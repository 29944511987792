.ui.segment {
  height: 500px;
  border: none !important;
  box-shadow: none;
}

@media screen and (max-width: map-get($breakpoints, small)) {
  .ui.grid .column.m-sixteen {
    width: 100% !important;
  }
}
