.profile-container {
  .ui.small.header {
    text-align: left;
    margin: 10px 0px 20px 0;
    padding-left: 1rem;

    @media screen and (max-width: map-get($breakpoints, small)) {
      padding-left: 0rem;
    }

    .require {
      color: map-get(map-get($palette, error), 1);
      font-size: $font-size-b3-desktop;
    }

    .input-group.checkbox {
      margin-bottom: 0;

      label {
        margin-bottom: 0;
      }
    }
  }
  .ui.error.message > .header {
    text-align: left;
  }

  .full-width {
    padding: 0px;
  }
  .profile-email {
    color: map-get(map-get($palette, secondary), 2);
    font-weight: $regular;
  }
  .web-account-email {
    @media screen and (max-width: map-get($breakpoints, small)) {
      display: none;
    }
  }
  .mobile-account-email {
    display: none;
    @media screen and (max-width: map-get($breakpoints, small)) {
      display: block;
      margin-top: -25px;
      font-weight: $semi-bold;
    }
  }
  form {
    &.ui.form {
      font-size: $font-size-input-desktop;
    }

    padding-bottom: 50px;
  }
  .ira-exist {
    display: flex;
    align-items: center;

    span {
      font-weight: $semi-bold;
      font-size: $font-size-b2-desktop;

      @media screen and (max-width: map-get($breakpoints, small)) {
        margin-bottom: 15px;
        display: block;
      }
    }

    img {
      margin-left: 15px;

      @media screen and (max-width: map-get($breakpoints, small)) {
        margin-bottom: 15px;
      }
    }

    &.ira-select {
      @media screen and (max-width: map-get($breakpoints, small)) {
        flex-direction: column;
      }
    }
  }

  .error-container {
    margin-left: 1rem;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .no-label .input-group {
    label {
      min-height: 13px;
      display: block;
    }
    .err-msg {
      display: none;
    }
  }

  .input-group.checkbox .checkmark {
    top: 4px;
  }
  .column-hidden {
    display: none;
  }

  .ui.grid > .column.column-padding:not(.row),
  .ui.grid > .row > .column.column-padding {
    padding-right: 0;
    padding-left: 0;
  }

  @media screen and (max-width: map-get($breakpoints, medium)) {
    .ui.grid > .eight.wide.column.tablet_sixteen {
      width: 100% !important;
    }

    .ui.grid > .four.wide.column.tablet_eight,
    .ui.grid > .row > .four.wide.column.tablet_eight,
    .ui.grid > .four.eight.column.tablet_eight,
    .ui.grid > .row > .eight.wide.column.tablet_eight {
      width: 50% !important;
    }
    .ui.grid > .row > .three.wide.column {
      width: 25% !important;
    }
  }

  @media screen and (max-width: map-get($breakpoints, small)) {
    .ui.grid > .four.wide.column.tablet_eight,
    .ui.grid > .eight.wide.column.tablet_eight {
      width: 100% !important;
    }
    &.eight.wide.mobilr:last-child {
      padding-right: 16px;
    }
    .error-container {
      width: 100%;
      margin-left: 0;
    }
    .error.message {
      margin-left: 0;
      margin-right: 0;
      width: 100%;
    }
    .box .box-content {
      padding: 0px;
    }
    .box .box-title.top-border {
      padding-top: 15px;
    }

    .box .box-title {
      color: rgba(map-get(map-get($palette, primary), 2), 0.87);
      font-weight: $bold;
      font-size: $font-size-b1-desktop;
      @media screen and (max-width: map-get($breakpoints,medium)) {
        font-size: $font-size-b1-tablet;
      }
      @media screen and (max-width: map-get($breakpoints,small)) {
        font-size: $font-size-b1-mobile;
      }
    }
  }
  .profile-status {
    display: inline;
    font-weight: $regular;
    color: map-get(map-get($palette, secondary), 2);

    img {
      margin-right: 4px;
    }
  }
  .add-entity {
    width: 100%;
    .btn.md {
      max-width: 100%;
      width: 100%;
    }
  }
  .entity-box {
    width: 50%;
    padding: 16px 24px 32px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 16px;
    @media screen and (max-width: map-get($breakpoints,small)) {
      padding: 16px 0px 0px;
      width: 100%;
    }
    div:not(.tooltip-box) {
      font-size: $font-size-b1-desktop;
      color: map-get(map-get($palette, secondary), 2);
      @media screen and (max-width: map-get($breakpoints,small)) {
        font-size: $font-size-b1-mobile;
      }
    }
    a {
      font-weight: $regular;
    }
    .entity-icon {
      width: 16px;
      height: 16px;
      margin: 10px 10px 0 0;
    }
    .entity-list {
      flex-direction: column;
      margin-top: 8px;
      width: 100%;
      @media screen and (max-width: map-get($breakpoints,small)) {
        margin-top: 0px;
        padding-top: 8px;
      }
      .entity-list-title {
        cursor: pointer;
        flex-direction: row;
        @media screen and (max-width: map-get($breakpoints,small)) {
          margin-bottom: 8px;
        }
      }
      h4 {
        font-weight: $semi-bold;
        display: inline-block;
        color: map-get(map-get($palette, primary), 2);
        margin-bottom: 0px;
      }
      h5 {
        font-weight: $semi-bold;
        display: inline-block;
        color: map-get(map-get($palette, primary), 2);
        margin-bottom: 0px;
        font-size: $font-size-b1-tablet;
      }
      span {
        list-style: none;
        color: map-get(map-get($palette, secondary), 2);
        line-height: $line-height;
        font-size: $font-size-b1-desktop;
        font-weight: $semi-bold;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @media screen and (max-width: map-get($breakpoints,small)) {
          font-size: $font-size-b1-mobile;
        }
        &.no-entity {
          color: map-get(map-get($palette, secondary), 3);
        }
      }
      &.broker-list {
        width: 100%;
        .broker-list-item {
          display: flex;
          flex-direction: row;
          align-items: flex-end;
          height: 48px;
          border-bottom: 0.5px solid map-get(map-get($palette, secondary), 3);
          justify-content: flex-end;
          span {
            font-size: $font-size-b1-mobile;
            font-weight: $regular;
            color: map-get(map-get($palette, primary), 2);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            &.item-type {
              margin-right: auto;
              width: 60%;
            }
            &.item-name {
              color: map-get(map-get($palette, secondary), 2);
              width: 40%;
            }
            &.item-action {
              margin-left: 16px;
              text-align: right;
              display: flex;
              flex-direction: column;
              align-items: center;
              font-size: 10px;
              color: map-get(map-get($palette, primary), 1);
              text-transform: capitalize;
            }
          }
        }
      }
    }
    .message-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 24px;
      div {
        font-size: $font-size-b1-mobile;
      }
      @media screen and (max-width: map-get($breakpoints,small)) {
        padding: 16px;
      }
      @media screen and (min-width: map-get($breakpoints,small)) {
        box-shadow: none;
      }
    }
    &.broker-box {
      @media screen and (max-width: map-get($breakpoints,small)) {
        margin-bottom: -32px !important;
      }
    }
  }
  .box-group {
    @media screen and (max-width: map-get($breakpoints,small)) {
      margin-top: -32px;
    }
  }
}

.psd-modal {
  .ui.grid .column {
    padding-left: 0rem;
    padding-right: 0rem;
  }

  @media screen and (max-width: map-get($breakpoints, small)) {
    .ui.grid {
      margin-top: 0rem;
      margin-bottom: 0rem;
    }
  }
}

.unaccredited-order-copy-margin {
  margin: 32px 0 16px 0;
  @media screen and (max-width: map-get($breakpoints, small)) {
    margin: 24px 0 16px 0;
  }
}
