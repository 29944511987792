.footer-container {
  display: flex;
  flex-direction: column;
  background-color: map-get(map-get($palette, primary), 3);
  color: map-get(map-get($palette, secondary), 2);
  justify-content: space-between;
  padding: 100px 150px;
  border-top: 0.5px solid rgba(map-get(map-get($palette, secondary), 3), 0.3);
  text-transform: uppercase;
  .margined {
    font-size: $font-size-b3-desktop;
    color: map-get(map-get($palette, secondary), 2);
    text-transform: none;
    a {
      font-size: $font-size-b3-desktop;
      color: map-get(map-get($palette, secondary), 2);
      text-decoration: underline;
      &:hover {
        color: map-get(map-get($palette, secondary), 3);
      }
    }
  }
  .inner-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .logo-group {
    color: map-get(map-get($palette, secondary), 3);
    max-width: 30%;

    a {
      color: map-get(map-get($palette, secondary), 3);
      text-decoration: underline;
    }
    .logo {
      height: 35px;
      transform: scale(1);
    }
    .copyright {
      margin: 1rem 0 1.5rem;
      font-size: $font-size-b1-desktop;
      @media screen and (max-width: map-get($breakpoints,medium)) {
        font-size: $font-size-b1-tablet;
      }
      @media screen and (max-width: map-get($breakpoints,small)) {
        font-size: $font-size-b1-mobile;
      }
    }
  }
  @media screen and (max-width: map-get($breakpoints, large)) {
    padding: 3.5rem 60px;
  }

  .nav-group {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 30%;
    .navs {
      display: flex;
      flex-direction: column;
      .nav-title {
        color: map-get(map-get($palette, secondary), 2);
        font-weight: $bold;
        margin-bottom: 10px;
        font-size: $font-size-b1-desktop;
        @media screen and (max-width: map-get($breakpoints,medium)) {
          font-size: $font-size-b1-tablet;
        }
        @media screen and (max-width: map-get($breakpoints,small)) {
          font-size: $font-size-b1-mobile;
        }
      }
      a.nav-title {
        cursor: pointer;
        text-decoration: underline;
      }
      .item {
        &:not(last-child) {
          margin-bottom: 5px;
          margin-top: 5px;
        }
        .footer-link {
          font-size: $font-size-b1-desktop;
          font-weight: $regular;
          color: map-get(map-get($palette, secondary), 2);
          @media screen and (max-width: map-get($breakpoints,medium)) {
            font-size: $font-size-b1-tablet;
          }
          @media screen and (max-width: map-get($breakpoints,small)) {
            font-size: $font-size-b1-mobile;
          }
          &:hover {
            color: map-get(map-get($palette, secondary), 3);
          }
        }
      }
      &:not(:first-child) {
        margin-top: 1.5rem;
      }
    }
  }
  @media screen and (max-width: map-get($breakpoints,medium)) {
    padding: 3.5rem 40px;
    .inner-container {
      flex-wrap: wrap;
      flex-direction: row;
      .logo-group {
        max-width: 100%;
        width: 100%;
      }
      .nav-group {
        width: 45%;
        .navs {
          margin-bottom: 1rem;
        }
      }
    }
  }
  @media screen and (max-width: map-get($breakpoints,small)) {
    padding: 3.5rem 24px;
  }
}

.terms-container,
.terms-container div {
  font-size: $font-size-b1-desktop;
  @media screen and (max-width: map-get($breakpoints,medium)) {
    font-size: $font-size-b1-tablet;
  }
  @media screen and (max-width: map-get($breakpoints,small)) {
    font-size: $font-size-b1-mobile;
  }
  strong,
  a {
    font-size: $font-size-b1-desktop;
    @media screen and (max-width: map-get($breakpoints,medium)) {
      font-size: $font-size-b1-tablet;
    }
    @media screen and (max-width: map-get($breakpoints,small)) {
      font-size: $font-size-b1-mobile;
    }
  }
  a {
    text-decoration: underline;
    font-weight: $regular;
  }
}

.privacy-container,
.privacy-container ol li,
.privacy-container strong,
.privacy-container a {
  font-size: $font-size-b1-desktop;
  @media screen and (max-width: map-get($breakpoints,medium)) {
    font-size: $font-size-b1-tablet;
  }
  @media screen and (max-width: map-get($breakpoints,small)) {
    font-size: $font-size-b1-mobile;
  }
}
