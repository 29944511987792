.invest-container {
  .content {
    color: map-get(map-get($palette, secondary), 2);
  }

  .ui.grid {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  @media screen and (max-width: map-get($breakpoints,small)) {
    .invest-list-container {
      .ui.grid > [class*='two column'].row > .column {
        width: 100% !important;
      }
    }
  }

  .product-filters {
    margin-top: 32px;

    .ui.grid {
      @media screen and (max-width: map-get($breakpoints,small)) {
        margin-left: 0px;
        margin-right: 0px;
        display: flex;
        justify-content: space-between;
      }
    }
    .unicrons-filters-search {
      position: relative;
      .input-group {
        margin-bottom: 0;
        margin-top: 5px;
      }
      input[type='text'] {
        padding: 8px 35px 8px 12px;
        width: 100%;
      }
      img {
        position: absolute;
        top: 15px;
        right: 12px;
        opacity: 0.7;
      }
    }
    .select-column {
      width: 25% !important;
      @media screen and (max-width: map-get($breakpoints, small)) {
        width: 49% !important;
        margin-bottom: 5px;
      }
    }

    .select-input {
      width: 50% !important;
      @media screen and (max-width: map-get($breakpoints, small)) {
        width: 100% !important;
      }
    }
    select {
      width: 100%;
      margin-top: 5px;
    }
  }

  .product-card {
    border-radius: 8px;
    padding: 20px 16px;
    margin-bottom: 14px;
    min-height: 138px;
    cursor: pointer;
    background-color: map-get(map-get($palette, primary), 3);
    box-shadow: 0 0 10px 0px rgba(map-get(map-get($palette, primary), 2), 0.08),
      inset 0 0 0 2px rgba(map-get(map-get($palette, primary), 2), 0.05);

    .product-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: map-get(map-get($palette, primary), 2);

      .sector {
        font-weight: $semi-bold;
        line-height: $line-height;
      }
      .icon {
        height: 40px;
        width: 80px;
        object-fit: contain;
      }
    }

    .product-description {
      font-weight: $regular;
      line-height: $line-height;
      margin-top: 8px;
      color: map-get(map-get($palette, secondary), 2);
      height: 50px;
      overflow: hidden;
      @media screen and (max-width: map-get($breakpoints,small)) {
        height: auto;
        overflow: auto;
      }
    }
  }

  .flag {
    height: 20px;
    width: 92px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    font-size: $font-size-b4-desktop;
    text-transform: uppercase;
    left: 0;
    right: 0;
    position: absolute;
    top: -8px;
    margin-left: auto;
    margin-right: auto;
    color: map-get(map-get($palette, primary), 3);
    &.teal {
      background-color: map-get(map-get($palette, primary), 1);
    }
    &.red {
      background-color: map-get(map-get($palette, error), 1);
    }
    &.darkGrey {
      background-color: map-get(map-get($palette, secondary), 2);
    }
    &.grey {
      background-color: map-get(map-get($palette, secondary), 3);
    }
  }

  .loader-component {
    margin-top: 32px;
  }
}
