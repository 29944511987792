.page-container {
  display: flex;
  flex-direction: column;
  min-height: 80vh;
  padding: 96px 150px;
  @media screen and (max-width: map-get($breakpoints,large)) {
    padding: 96px 60px;
  }
  @media screen and (max-width: map-get($breakpoints,medium)) {
    padding: 96px 40px;
  }
  @media screen and (max-width: map-get($breakpoints,small)) {
    padding: 64px 24px;
    .image {
      width: 100%;
    }
  }
}

.inner-container {
  width: 100%;
  max-width: 906px;
  margin: 0 auto;
}

// used when there is full size background
.inner-page-container {
  padding: 0 150px;
  @media screen and (max-width: map-get($breakpoints,large)) {
    padding: 0 60px;
  }
  @media screen and (max-width: map-get($breakpoints,medium)) {
    padding: 0 40px;
  }
  @media screen and (max-width: map-get($breakpoints,small)) {
    padding: 0 25px;
  }
}
.hide-mobile {
  @media screen and (max-width: map-get($breakpoints,small)) {
    display: none !important;
  }
}

.page-content {
  font-size: $font-size-b1-desktop;
  line-height: $line-height;
  margin-bottom: 2rem;
  @media screen and (max-width: map-get($breakpoints,medium)) {
    font-size: $font-size-b1-tablet;
  }
  @media screen and (max-width: map-get($breakpoints,small)) {
    font-size: $font-size-b1-mobile;
  }
}
.center {
  margin: 0 auto;
}
.margined {
  margin: 24px 0;
}
.quote {
  font-style: italic;
}
.pointer {
  cursor: pointer !important;
}
//use for component
.btn-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  &.start {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    & button:nth-child(1) {
      margin-right: 10px;
    }
    @media screen and (max-width: map-get($breakpoints,small)) {
      flex-direction: column;
    }
  }
  &.centered {
    justify-content: center;
    align-items: center;
  }
  &.left {
    justify-content: flex-start;
    @media screen and (max-width: map-get($breakpoints,medium)) and (min-width: map-get($breakpoints,small)) {
      &.hero {
        flex-direction: row;
      }
    }
  }
  &.right {
    justify-content: flex-end;
  }

  &.two {
    .btn {
      width: 50%;
      &:not(:last-child) {
        margin-right: 16px;
      }
      @media screen and (max-width: map-get($breakpoints,small)) {
        width: 100%;
        &:last-child {
          margin-top: 0px;
        }
      }
    }
  }

  &.side-by-side {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    .btn {
      width: 50%;
      &:not(:last-child) {
        margin-right: 16px;
      }
      @media screen and (max-width: map-get($breakpoints,small)) {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: map-get($breakpoints, medium)) {
    flex-direction: column;
    &.two {
      .btn {
        width: 100%;
        &:nth-child(1),
        &:not(.hero) {
          margin-bottom: 5px;
        }
        &:last-child {
          margin-top: 0px;
        }
      }
    }
  }
  @media screen and (max-width: map-get($breakpoints, small)) {
    &.left,
    &.right {
      justify-content: center;
      align-items: center;
    }
    &.two {
      .btn {
        &:not(:last-child) {
          margin-right: 0;
          margin-bottom: 10px;
        }
      }
    }
  }
}
.btn {
  border: none;
  background-color: map-get(map-get($palette, secondary), 3);
  cursor: pointer;
  padding: 13px 16px;
  border-radius: 8px;
  color: map-get(map-get($palette, primary), 3);
  font-size: $btn-font-size-web;
  font-weight: $semi-bold;
  line-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  margin: 16px 0 8px 0;

  &:hover {
    background-color: lighten(map-get(map-get($palette, secondary), 3), 2%);
  }
  &.primary {
    background: radial-gradient(
      122.27% 198.92% at -22.27% -27.38%,
      #3095aa 0%,
      #097187 100%
    );
    cursor: pointer;
    &:hover {
      background: map-get(map-get($palette, secondary), 5);
      background-color: map-get(map-get($palette, secondary), 5);
    }
    &:disabled {
      cursor: default;
      background: map-get(map-get($palette, secondary), 3);
    }
  }
  &.secondary {
    box-shadow: 0px 0px 0px 2px map-get(map-get($palette, primary), 1) inset;
    background-color: map-get(map-get($palette, primary), 3);
    color: map-get(map-get($palette, primary), 1);
    &:hover:not(:disabled) {
      background: radial-gradient(
        122.27% 198.92% at -22.27% -27.38%,
        #3095aa 0%,
        #097187 100%
      );
      color: map-get(map-get($palette, primary), 3);
      box-shadow: none;
    }
    &:disabled {
      cursor: default;
      box-shadow: 0px 0px 0px 2px map-get(map-get($palette, secondary), 3) inset;
      color: map-get(map-get($palette, secondary), 3);
      &:hover {
        background-color: map-get(map-get($palette, primary), 3);
        box-shadow: 0px 0px 0px 2px map-get(map-get($palette, secondary), 3)
          inset;
      }
    }
  }
  &.special {
    background: radial-gradient(
      126.47% 183.96% at -26.47% -17.8%,
      map-get(map-get($palette, secondary), 1) 0%,
      map-get(map-get($palette, primary), 1) 100%
    );
    cursor: pointer;
    border: 2px solid map-get(map-get($palette, primary), 3);
    box-shadow: 0px 0px 10px map-get(map-get($palette, primary), 3);
    &:hover {
      background: radial-gradient(
        126.47% 183.96% at -26.47% -17.8%,
        lighten(map-get(map-get($palette, secondary), 1), 2%) 0%,
        lighten(map-get(map-get($palette, primary), 1), 2%) 100%
      );
    }
    &:disabled {
      background-color: lighten(map-get(map-get($palette, primary), 1), 30%);
      &:hover {
        background-color: lighten(map-get(map-get($palette, primary), 1), 30%);
      }
    }
  }
  &.sm {
    font-size: 15px;
    padding: 9px 10px;
    min-width: 100px;
  }
  &.md {
    max-width: 408px;
    max-height: 56px;
    min-width: 96px;
    min-height: 56px;
  }
  &.lg {
    font-size: 26px;
    line-height: 36px;
    min-width: 200px;
    max-width: 300px;
  }
  &.place-order-button {
    width: 100%;
    max-width: 100%;
  }
  &.disconnect-btn {
    margin-left: 1rem;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  &.change-pwd-btn {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  &.full-width {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }
  @media screen and (max-width: map-get($breakpoints, medium)) {
    &.md,
    &.lg {
      min-width: 0;
    }
  }
  @media screen and (max-width: map-get($breakpoints, small)) {
    margin: 16px 0 8px 0;
    &.md,
    &.lg {
      min-width: 0;
      font-size: $btn-font-size-phone;
      width: 100%;
    }
    &.lg {
      margin: 5px 0;
      font-size: 20px;
      width: 100%;
      max-width: 100%;
      padding: 10px 25px;
    }
    &.md {
      min-height: 48px;
      max-height: 48px;
      line-height: 23px;
    }
    &.sm {
      margin: 8px 0;
    }
  }
  .loading {
    border: 3px solid map-get(map-get($palette, secondary), 4);
    border-top: 3px solid map-get(map-get($palette, secondary), 3);
    border-radius: 50%;
    height: 18px;
    width: 18px;
    margin: 0 auto;
    animation: spin 0.5s linear infinite;
  }
  &:focus {
    outline: none;
  }
  &.right {
    float: right;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

// Global Title and Header
.page-title {
  font-size: $font-size-h1-desktop;
  font-weight: $semi-bold;
  line-height: $title-line-height;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  text-align: left;
  @media screen and (max-width: map-get($breakpoints, medium)) {
    font-size: $font-size-h1-tablet;
    align-items: center;
  }
  @media screen and (max-width: map-get($breakpoints, small)) {
    padding: 0px;
    font-size: $font-size-h1-mobile;
    margin: 16px 0;
    align-items: center;
    justify-content: space-between;
  }
  &.subinfo {
    align-items: flex-end;
    justify-content: space-between;
    @media screen and (max-width: map-get($breakpoints, small)) {
      flex-direction: column;
      align-items: flex-start;
      & > span {
        margin-top: 8px;
      }
    }
  }
}
.page-sub-title {
  @media screen and (max-width: map-get($breakpoints,small)) {
    margin-top: -8px;
    margin-bottom: 16px;
  }
}
h3 {
  font-weight: $semi-bold;
  font-size: $font-size-h3-desktop;
  line-height: $title-line-height;
  @media screen and (max-width: map-get($breakpoints,medium)) {
    font-size: $font-size-h3-tablet;
  }
  @media screen and (max-width: map-get($breakpoints,small)) {
    font-size: $font-size-h3-mobile;
  }
}

.content {
  color: map-get(map-get($palette, secondary), 2);
  font-size: $font-size-b1-desktop;
  line-height: $line-height;
  font-weight: $regular;
  @media screen and (max-width: map-get($breakpoints,medium)) {
    font-size: $font-size-b1-tablet;
  }
  @media screen and (max-width: map-get($breakpoints,small)) {
    font-size: $font-size-b1-mobile;
  }
  a {
    font-size: $font-size-b1-desktop;
    @media screen and (max-width: map-get($breakpoints,medium)) {
      font-size: $font-size-b1-tablet;
    }
    @media screen and (max-width: map-get($breakpoints,small)) {
      font-size: $font-size-b1-mobile;
    }
  }
  &.sm {
    font-size: $font-size-b2-desktop;
    line-height: $title-line-height;
    margin-top: 0.8rem;
    @media screen and (max-width: map-get($breakpoints, medium)) {
      font-size: $font-size-b2-mobile;
    }
  }
  &.edit-true {
    border: none;
    box-shadow: inset 0 -2px map-get(map-get($palette, secondary), 3);
    &:focus {
      outline: none;
    }
  }
  &.lg {
    font-size: $font-size-b1-desktop;
    @media screen and (max-width: map-get($breakpoints,medium)) {
      font-size: $font-size-b1-tablet;
    }
    @media screen and (max-width: map-get($breakpoints,small)) {
      font-size: $font-size-b1-mobile;
    }
  }
  &.space-below-sm {
    padding-bottom: 16px;
  }
  &.space-above-sm {
    padding-top: 16px;
  }
  b {
    font-weight: $semi-bold;
    font-size: 1em;
  }
}

.section-header {
  font-size: $font-size-h3-desktop;
  font-weight: $bold;
  line-height: $title-line-height;
  margin: 20px 0;
  @media screen and (max-width: map-get($breakpoints,medium)) {
    font-size: $font-size-h3-tablet;
  }
  @media screen and (max-width: map-get($breakpoints,small)) {
    font-size: $font-size-h3-mobile;
  }
}

.ui.form .field > label {
  font-size: $font-size-input-desktop;
  margin: 10px 0;
  text-align: left;
  padding: 0 5px;
}

form.ui .error.message {
  margin-bottom: 1.2rem;
}

.ui.grid > .column:not(.row) {
  padding-top: 0;
  padding-bottom: 0;
}

a {
  color: map-get(map-get($palette, primary), 4);
  font-weight: $bold;

  &:hover {
    color: map-get(map-get($palette, secondary), 3);
  }
}

select,
.ui.form select {
  border: 1px solid map-get(map-get($palette, secondary), 3);
  padding: 0 16px;
  box-sizing: border-box;
  height: 48px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  font-size: $font-size-input-desktop;
  font-weight: $semi-bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: map-get(map-get($palette, secondary), 2);
  background: map-get(map-get($palette, primary), 3);
  cursor: pointer;
  outline: none;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg width='13' height='8' viewBox='0 0 13 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.6093 -6.18684e-08L13 1.41538L7.19535 7.32308L6.5 8L5.80465 7.32308L-6.07893e-08 1.41538L1.3907 -5.16467e-07L6.5 5.2L11.6093 -6.18684e-08Z' fill='%234E5156'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: right 16px top 50%;
  padding-right: 36px;
  @media screen and (max-width: map-get($breakpoints,medium)) {
    font-size: $font-size-b1-tablet;
  }
  @media screen and (max-width: map-get($breakpoints,small)) {
    font-size: $font-size-b1-mobile;
  }
  &:hover {
    outline: none;
    border: 1px solid map-get(map-get($palette, primary), 1);
  }
  &:active,
  &:focus {
    outline: none;
    border-color: map-get(map-get($palette, primary), 1);
  }
  &:disabled {
    color: map-get(map-get($palette, secondary), 3);
    background-color: map-get(map-get($palette, secondary), 4);
    opacity: 1;
  }
  &.error {
    border: 1px solid map-get(map-get($palette, error), 1);
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    box-shadow: 0 0 0px 1000px rgba(map-get(map-get($palette, primary), 2), 0)
      inset;
    transition: background-color 5000s ease-in-out 0s;
  }
  &:-webkit-autofill:disabled {
    -webkit-text-fill-color: map-get(map-get($palette, secondary), 3);
    box-shadow: 0 0 0px 1000px map-get(map-get($palette, secondary), 4) inset;
    transition: background-color 5000s ease-in-out 0s;
  }
}

.PhoneInput {
  padding-left: 15px;

  .PhoneInputInput {
    padding-left: 55px !important;
    margin-left: -55px;
  }
  .PhoneInputCountrySelect:disabled {
    opacity: 0;
  }
}

h4 {
  font-size: $font-size-h4-desktop;
  font-weight: $semi-bold;
  margin: 1rem 0;
  @media screen and (max-width: map-get($breakpoints,medium)) {
    font-size: $font-size-h4-tablet;
  }
  @media screen and (max-width: map-get($breakpoints,small)) {
    font-size: $font-size-h4-mobile;
  }
}
p,
blockquote {
  font-size: $font-size-b1-desktop;
  @media screen and (max-width: map-get($breakpoints,medium)) {
    font-size: $font-size-b1-tablet;
  }
  @media screen and (max-width: map-get($breakpoints,small)) {
    font-size: $font-size-b1-mobile;
  }
}
blockquote {
  border-left: none;
}

.container {
  width: 100%;
}

.dimmer-2 {
  position: fixed;
  top: 0 !important;
  left: 0 !important;
  width: 100%;
  height: 100vh;
  // text-align: center;
  vertical-align: middle;
  padding: 2em;
  background: hsla(0, 0%, 100%, 0.5);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  line-height: 1;
  animation-fill-mode: both;
  animation-duration: 0.5s;
  transition: background-color 0.5s linear;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  will-change: opacity;
  z-index: 1000;
  @media screen and (max-width: map-get($breakpoints, small)) {
    padding: 2em 0;
  }
  @media screen and (max-width: map-get($breakpoints, small)) {
    &.dimmer-no-padding {
      padding: 0;
    }
  }
}

.modal {
  max-width: 800px;
  border-radius: 8px;
  position: relative;
  box-shadow: 0px 24px 48px -18px rgba(map-get(map-get($palette, secondary), 6), 0.2);
  background-color: map-get(map-get($palette, primary), 3);
  &.primary > .title-group {
    background: radial-gradient(
      122.27% 198.92% at -22.27% -27.38%,
      map-get(map-get($palette, secondary), 5) 0%,
      map-get(map-get($palette, primary), 1) 100%
    );
    color: map-get(map-get($palette, primary), 3);
  }
  &.error > .title-group {
    background: radial-gradient(
      122.27% 198.92% at -22.27% -27.38%,
      map-get(map-get($palette, secondary), 5) 0%,
      map-get(map-get($palette, primary), 1) 100%
    );
    color: map-get(map-get($palette, primary), 3);
  }
  .title-group {
    position: absolute;
    top: -48px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 0;
    min-height: 48px;
    max-height: 48px;
    width: 100%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    box-shadow: 1px -1px 10px rgba(map-get(map-get($palette, primary), 3), 0.5);
    @media screen and (max-width: map-get($breakpoints, small)) {
      left: 0;
    }
    .modal-title {
      font-size: $font-size-b1-desktop;
      line-height: $line-height;
      font-weight: $semi-bold;

      @media screen and (max-width: map-get($breakpoints,medium)) {
        font-size: $font-size-b1-tablet;
      }
      @media screen and (max-width: map-get($breakpoints,small)) {
        font-size: $font-size-b1-mobile;
      }
    }
    .close-icon {
      position: absolute;
      right: 0;
      margin: 0 16px 0 0;
    }
  }
  &.sm {
    width: 500px;
    @media screen and (max-width: map-get($breakpoints, medium)) {
      width: 350px;
    }
    @media screen and (max-width: map-get($breakpoints, small)) {
      width: 90vw;
    }
  }
  &.md {
    width: 50vw;
    @media screen and (max-width: map-get($breakpoints, medium)) {
      width: 75vw;
    }
    @media screen and (max-width: map-get($breakpoints, small)) {
      width: 90vw;
    }
  }
  &.lg {
    min-width: 80vw;
    @media screen and (max-width: map-get($breakpoints, small)) {
      min-height: 60vh;
      max-width: 90vw;
      min-width: 80vw;
    }
  }
  .modal-inner-content {
    box-shadow: 1px 1px 10px rgba(map-get(map-get($palette, primary), 3), 0.5);
    padding: 32px;
    background-color: map-get(map-get($palette, primary), 3);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    max-height: 80vh;
    width: 100%;
    overflow-y: auto;

    li,
    p {
      font-size: $font-size-b1-desktop;
      font-weight: $regular;
      color: map-get(map-get($palette, secondary), 2);
      line-height: 30px;
    }
    strong {
      font-size: $font-size-b1-desktop;
    }

    .ui.grid {
      margin: 0px;
    }
    // scrollbar-width: thin;
    &::-webkit-scrollbar-track {
      //full track
      background-color: transparent;
      border-radius: 20px;
    }

    &::-webkit-scrollbar-thumb {
      //bar
      opacity: 0;
      cursor: pointer;
    }

    @media screen and (max-width: map-get($breakpoints, small)) {
      height: 100%;
      padding: 24px;
      li,
      p {
        font-size: $font-size-b1-mobile;
        line-height: 24px;
      }
      strong {
        font-size: $font-size-b1-mobile;
      }
    }
  }
}

.success-modal {
  .content {
    text-align: center;
    margin-bottom: 16px;
    @media screen and (max-width: map-get($breakpoints, small)) {
      text-align: left;
    }
  }
  .btn {
    min-width: 340px;
    @media screen and (max-width: map-get($breakpoints, small)) {
      min-width: 100%;
      font-size: $font-size-h4-mobile;
    }
  }
}
.info-title {
  &.sub-title {
    font-size: $font-size-h4-mobile;
    color: map-get(map-get($palette, secondary), 2);
    line-height: $title-line-height;
    font-weight: $semi-bold;
    margin-bottom: 32px;
  }
  &.option {
    border-bottom: 1px solid rgba(map-get(map-get($palette, secondary), 4), 0.4);
    color: map-get(map-get($palette, primary), 1);
    padding: 1rem 0;
    margin: 1rem 0;
    font-weight: $semi-bold;
    width: 100%;
    white-space: break-spaces;
  }
  &.info-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: map-get($breakpoints, small)) {
      flex-direction: column;
    }
  }
  &.with-img {
    display: flex;
    flex-direction: row;
    align-items: center;
    img {
      margin-left: 0.5rem;
      cursor: pointer;
    }
  }
}

.kycStatus {
  display: inline;
  margin: 0 5px;

  &.red {
    color: map-get(map-get($palette, status), 1);
  }
  &.teal {
    color: map-get(map-get($palette, primary), 1);
  }
}

.pending-group {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 1rem;
}

.pending-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
}

svg path,
svg rect {
  fill: map-get(map-get($palette, primary), 1);
}

.ui.negative.tiny.message {
  padding: 0.8rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.err-message {
  font-size: $font-size-b2-desktop;
}

.ui.pointing.label {
  font-size: $font-size-b2-desktop !important;
  padding: 0.5rem 1rem !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}

.agreements {
  margin: 2rem 0;
  padding-right: 1rem;
  @media screen and (max-width: map-get($breakpoints, medium)) {
    padding-right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .agreement {
    color: map-get(map-get($palette, primary), 2);
    background-color: rgba(map-get(map-get($palette, secondary), 4), 0.4);
    padding: 0.8rem 2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
    width: 350px;
    max-width: 100%;
    border-radius: 10px;
    @media screen and (max-width: map-get($breakpoints, medium)) {
      width: 100%;
    }
    .name {
      font-weight: $semi-bold;
      line-height: $line-height;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 90%;
    }
    img {
      margin-left: 1rem;
    }
  }
}

.sub-header {
  font-size: $font-size-b2-desktop;
  font-weight: $semi-bold;
  margin: 1rem 0;
}

.upload-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  .webcam {
    cursor: pointer;
    height: 50px;
    width: 9%;
    border-radius: 8px;
    padding: 5px 8px;
    color: map-get(map-get($palette, secondary), 3);
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: map-get($breakpoints, medium)) {
      width: 12%;
      border: none;
    }
    .cam-img {
      height: 80%;
    }
  }
}

// image loaderStyle

.loader-component {
  width: 100%;
  height: 100%;
  margin: 0px;
  text-align: center;
  padding: 5px;
  .circle-spinner {
    text-align: center;
    width: 20px;
    height: 20px;
    position: relative;
    margin: 0 auto;
  }

  .double-bounce1 {
    background-color: rgba(map-get(map-get($palette, primary), 1), 0.9);
  }
  .double-bounce2 {
    background-color: rgba(map-get(map-get($palette, primary), 1), 0.2);
  }
  .double-bounce1,
  .double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;

    -webkit-animation: sk-bounce 1.5s infinite ease-in-out;
    animation: sk-bounce 1.5s infinite ease-in-out;
  }

  .double-bounce2 {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }

  @-webkit-keyframes sk-rotateplane {
    0% {
      -webkit-transform: perspective(120px);
    }
    50% {
      -webkit-transform: perspective(120px) rotateY(180deg);
    }
    100% {
      -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg);
    }
  }

  @keyframes sk-rotateplane {
    0% {
      transform: perspective(120px) rotateX(0deg) rotateY(0deg);
      -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
      opacity: 1;
    }
    50% {
      transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
      -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
      opacity: 1;
    }
    100% {
      transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
      -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
      opacity: 1;
    }
  }

  @-webkit-keyframes sk-bounce {
    0%,
    100% {
      -webkit-transform: scale(0);
    }
    50% {
      -webkit-transform: scale(1);
    }
  }

  @keyframes sk-bounce {
    0%,
    100% {
      transform: scale(0);
      -webkit-transform: scale(0);
    }
    50% {
      transform: scale(1);
      -webkit-transform: scale(1);
    }
  }
}

.input {
  &.error {
    display: flex;
    text-align: left;
    padding: 0.3em 0.5em;
  }
}

// input component input group
$err-red: rgba(map-get(map-get($palette, error), 1), 0.8);

.input-group {
  display: flex;
  flex-direction: column;
  &:not(.confirm-checkbox):not(.linqto-bucks) {
    margin-bottom: 16px;
  }
  label {
    color: map-get(map-get($palette, primary), 2);
    font-size: $font-size-input-desktop;
    text-align: left;
    line-height: $line-height;
  }
  input:not([type='checkbox']):not([type='radio']),
  textarea {
    border: 1px solid map-get(map-get($palette, secondary), 3);
    box-sizing: border-box;
    padding: 0 16px;
    height: 48px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    font-size: $font-size-input-desktop;
    font-weight: $semi-bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: map-get(map-get($palette, primary), 2);
    &:hover {
      border-color: map-get(map-get($palette, secondary), 3);
    }
    &:focus,
    &:active {
      outline: none;
      border-color: map-get(map-get($palette, primary), 1);
    }
    &:disabled {
      color: map-get(map-get($palette, secondary), 3);
      background-color: map-get(map-get($palette, secondary), 4);
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      background-color: none;
      -webkit-box-shadow: 0 0 0px 1000px map-get(map-get($palette, primary), 3)
        inset;
      box-shadow: 0 0 0px 1000px map-get(map-get($palette, primary), 3) inset;
      transition: background-color 5000s ease-in-out 0s;
    }
    &:-webkit-autofill:disabled {
      -webkit-text-fill-color: map-get(map-get($palette, secondary), 3);
      box-shadow: 0 0 0px 1000px map-get(map-get($palette, secondary), 4) inset;
      transition: background-color 5000s ease-in-out 0s;
      border-color: map-get(map-get($palette, secondary), 4);
    }
    &::placeholder {
      color: map-get(map-get($palette, secondary), 3);
    }
    &.text-end {
      width: 100%;
      text-align: end;
    }
  }
  &.error:not(.checkbox) {
    margin-bottom: 0.2rem;
    input,
    textarea,
    select {
      border: 1px solid $err-red;
      &:hover {
        border: 1px solid $err-red;
      }
    }
    .err-msg {
      line-height: $line-height;
      font-size: $font-size-input-desktop;
      font-weight: $semi-bold;
      color: $err-red;
      text-align: left;
    }
  }

  textarea {
    width: 100%;
    height: 200px;
    font-size: $font-size-input-desktop;
    padding-top: 10px;
  }
  &.checkbox {
    margin-top: 1rem;
    .checkbox-label {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0.2rem 0;
      @media screen and (max-width: map-get($breakpoints,medium)) {
        margin: 0.25rem 0 0 0.7rem;
      }
    }
    label {
      input {
        opacity: 0;
      }
    }
    &.error {
      .checkbox-label {
        .checkmark {
          box-shadow: inset 0 0 0 1.5px $err-red;
          border: 1.5px solid rgba($err-red, 0.1);
        }
      }
    }
    /* Create a custom checkbox */
    .checkmark {
      position: absolute !important;
      top: 0;
      left: 0;
      height: 16px;
      width: 16px;
      border-radius: 4px;
      border: 1px solid map-get(map-get($palette, secondary), 3);
    }

    /* On mouse-over, add a grey background color */
    &:hover input ~ .checkmark {
      background-color: map-get(map-get($palette, secondary), 4);
      cursor: pointer;
    }

    /* When the checkbox is checked, add a blue background */
    input:checked ~ .checkmark {
      background-color: map-get(map-get($palette, primary), 3);
      border: 1px solid map-get(map-get($palette, secondary), 3);
    }
    input:disabled ~ .checkmark {
      background-color: map-get(map-get($palette, secondary), 3);
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
      content: '';
      position: absolute;
      display: none;
    }

    /* Show the checkmark when checked */
    input:checked ~ .checkmark:after {
      display: block;
    }

    /* Style the checkmark/indicator */
    .checkmark:after {
      left: 3px;
      width: 7px;
      height: 12px;
      border: solid map-get(map-get($palette, primary), 1);
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
    .children {
      margin-left: 1rem;

      a {
        text-decoration: none;
        color: map-get(map-get($palette, primary), 1);
        font-weight: $semi-bold;
      }
    }

    &.default-check {
      pointer-events: none;
    }
  }
  &.country_dropdown {
    margin-top: 0.5rem;
    margin-left: 1.5rem;
  }
}

.phone-input {
  .react-tel-input {
    .form-control {
      border: 1px solid map-get(map-get($palette, secondary), 3);
      border-radius: 8px;
      font-size: $font-size-input-desktop;
      font-weight: $semi-bold;
      max-height: 43px;
      padding-left: 48px !important;
      &:focus,
      &:active {
        outline: none;
        border-color: map-get(map-get($palette, primary), 1);
      }
      &:disabled {
        color: map-get(map-get($palette, secondary), 3);
        border-color: map-get(map-get($palette, secondary), 3);
        background-color: map-get(map-get($palette, secondary), 4);
      }
    }
  }
}
.AI-quality {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 3rem;
  .quality {
    width: 32%;
    min-width: 250px;
    box-shadow: 0 0 10px 5px rgba(map-get(map-get($palette, primary), 2), 0.08);
    border-radius: 8px;
    padding: 1rem;
    .title-group {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 1rem;
      img {
        height: 30px;
      }
      p {
        padding-left: 1rem;
      }
    }
    margin-top: 1rem;
    @media screen and (max-width: map-get($breakpoints,medium)) {
      width: 49%;
    }
    @media screen and (max-width: map-get($breakpoints,small)) {
      width: 100%;
    }
  }
}
.radio-group {
  .radio-option {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    input[type='radio'] {
      font-size: $font-size-b1-desktop;
      cursor: pointer;
      -webkit-appearance: none;
      appearance: none;
      padding: 0;
      -webkit-print-color-adjust: exact;
      color-adjust: exact;
      display: inline-block;
      vertical-align: middle;
      background-origin: border-box;
      -webkit-user-select: none;
      user-select: none;
      flex-shrink: 0;
      height: 1rem;
      width: 1rem;
      border: 1px solid map-get(map-get($palette, secondary), 2);
      background-color: map-get(map-get($palette, primary), 3);
      border-radius: 10px;
      color: map-get(map-get($palette, primary), 1);
      margin-top: 0.4em;
      @media screen and (max-width: map-get($breakpoints,medium)) {
        font-size: $font-size-b1-tablet;
      }
      @media screen and (max-width: map-get($breakpoints,small)) {
        font-size: $font-size-b1-mobile;
      }
    }
    input[type='radio']:checked {
      border-color: transparent;
      background-color: currentColor;
      background-size: 100% 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
    }
    input[type='radio']:disabled:checked {
      background-color: map-get(map-get($palette, secondary), 3);
    }
    label {
      margin-left: 0.75rem;
      cursor: pointer;
    }
    label > span > a {
      color: map-get(map-get($palette, primary), 1);
      font-weight: $regular;
    }
  }
}

.form-header {
  display: flex;
  flex-direction: row;
  .checkbox {
    margin-top: 0;
    margin-left: 1rem;
  }
}

.error-msg {
  color: $err-red;
  font-weight: $semi-bold;
}

.ui.grid {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.contact-support-container {
  display: flex;
  justify-content: flex-end;
  font-weight: $semi-bold;
  color: map-get(map-get($palette, primary), 1);
  font-size: $font-size-b1-desktop;
  @media screen and (max-width: map-get($breakpoints,medium)) {
    font-size: $font-size-b1-tablet;
  }
  @media screen and (max-width: map-get($breakpoints,small)) {
    font-size: $font-size-b1-mobile;
  }

  a {
    color: map-get(map-get($palette, secondary), 2);
    margin-left: 5px;
    text-decoration: underline;
    font-size: $font-size-b1-desktop;
    font-weight: $semi-bold;
    @media screen and (max-width: map-get($breakpoints,medium)) {
      font-size: $font-size-b1-tablet;
    }
    @media screen and (max-width: map-get($breakpoints,small)) {
      font-size: $font-size-b1-mobile;
    }
  }
}

.order-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media screen and (max-width: map-get($breakpoints, medium)) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .product-header {
    @media screen and (max-width: map-get($breakpoints, medium)) {
      text-align: center;
    }
    @media screen and (max-width: map-get($breakpoints, small)) {
      width: 100%;
    }
    .icon {
      height: 110px;
      border-radius: 50%;
      @media screen and (max-width: map-get($breakpoints, small)) {
        height: 50px;
      }
    }

    .product-name {
      margin: 0.8rem 0 1.8rem;
      font-size: $font-size-h1-desktop;
      font-weight: $bold;
      line-height: $title-line-height;
      @media screen and (max-width: map-get($breakpoints, medium)) {
        font-size: $font-size-h1-tablet;
      }
      @media screen and (max-width: map-get($breakpoints, small)) {
        font-size: $font-size-h1-mobile;
      }
    }
    .series {
      font-size: $font-size-h4-desktop;
      @media screen and (max-width: map-get($breakpoints,medium)) {
        font-size: $font-size-h4-tablet;
      }
      @media screen and (max-width: map-get($breakpoints,small)) {
        font-size: $font-size-h4-mobile;
      }
    }

    .price {
      margin: 10px 0;
      font-size: $font-size-h3-desktop;
      font-weight: $bold;
      @media screen and (max-width: map-get($breakpoints,medium)) {
        font-size: $font-size-h3-tablet;
      }
      @media screen and (max-width: map-get($breakpoints, small)) {
        font-size: $font-size-h3-mobile;
      }

      &.disabled {
        color: map-get(map-get($palette, secondary), 3);
      }
      span {
        color: map-get(map-get($palette, primary), 2);
        font-weight: $regular;
      }
    }

    .description {
      width: 17rem;
      font-size: $font-size-b1-desktop;
      color: map-get(map-get($palette, primary), 2);
      @media screen and (max-width: map-get($breakpoints,medium)) {
        font-size: $font-size-b1-tablet;
      }
      @media screen and (max-width: map-get($breakpoints,small)) {
        font-size: $font-size-b1-mobile;
      }
    }
  }

  .product-order {
    width: 60%;
    border-radius: 8px;
    max-width: 600px;
    margin-bottom: 0;
    align-self: flex-start;
    line-height: $small-line-height;
    @media screen and (max-width: map-get($breakpoints, medium)) {
      width: 100%;
      max-width: 100%;
    }

    .buy-product {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      border-radius: 5px;
      padding: 1.8rem;
      font-weight: $semi-bold;
      line-height: $small-line-height;
      div {
        font-size: $font-size-b1-tablet;
      }
      .title {
        font-size: $font-size-h3-desktop;
        @media screen and (max-width: map-get($breakpoints,medium)) {
          font-size: $font-size-h3-tablet;
        }
        @media screen and (max-width: map-get($breakpoints,small)) {
          font-size: $font-size-h3-mobile;
        }
        span {
          font-size: $font-size-h3-desktop;
          @media screen and (max-width: map-get($breakpoints,medium)) {
            font-size: $font-size-h3-tablet;
          }
          @media screen and (max-width: map-get($breakpoints,small)) {
            font-size: $font-size-h3-mobile;
          }
        }
        width: 100%;
        margin-bottom: 1rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        @media screen and (max-width: map-get($breakpoints, small)) {
          flex-direction: column;
          .label {
            margin-top: 10px;
          }
        }
      }
      .summary {
        font-weight: $regular;
        b {
          font-size: $font-size-h4-desktop;
          @media screen and (max-width: map-get($breakpoints,medium)) {
            font-size: $font-size-h4-tablet;
          }
          @media screen and (max-width: map-get($breakpoints,small)) {
            font-size: $font-size-h4-mobile;
          }
        }
      }
    }

    .place-order-box {
      // height: 85%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 1.8rem;
      &:not(.review) {
        border-top: 1px solid rgba(map-get(map-get($palette, primary), 2), 0.05);
      }
      &.review {
        padding-top: 0px;
      }

      .sold-out {
        color: map-get(map-get($palette, error), 1);
        font-weight: $semi-bold;
        font-size: $font-size-b1-tablet;
        margin-bottom: 30px;
      }
      .line {
        width: 100%;
        height: 1px;
        border-bottom: 0.8px solid
          rgba(map-get(map-get($palette, primary), 2), 0.2);
        margin-top: -0.5rem;
        margin-bottom: 0.5rem;
      }
      &__details {
        .total-amt {
          font-size: $font-size-h4-desktop;
          @media screen and (max-width: map-get($breakpoints,medium)) {
            font-size: $font-size-h4-tablet;
          }
          @media screen and (max-width: map-get($breakpoints,small)) {
            font-size: $font-size-h4-mobile;
          }
          font-weight: $bold;
        }

        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;
        &:not(.confirm-checkbox):not(.verification):not(.linqto-bucks) {
          flex-direction: row;
          align-items: center;
          height: 25px;
          color: map-get(map-get($palette, primary), 2);

          span,
          div {
            font-size: $font-size-b1-tablet;
            @media screen and (max-width: map-get($breakpoints,medium)) {
              font-size: $font-size-b1-tablet;
            }
            @media screen and (max-width: map-get($breakpoints,small)) {
              font-size: $font-size-b1-mobile;
            }
            small {
              font-size: $font-size-b3-desktop;
              color: map-get(map-get($palette, secondary), 3);
              font-weight: $semi-bold;
            }
          }
          div > b,
          div.total-amt,
          b.total-amt {
            font-size: $font-size-h4-desktop;
            @media screen and (max-width: map-get($breakpoints,medium)) {
              font-size: $font-size-h4-tablet;
            }
            @media screen and (max-width: map-get($breakpoints,small)) {
              font-size: $font-size-h4-mobile;
            }
          }
          span.web-large-content,
          b.web-large-content {
            font-size: $font-size-h4-desktop;
            @media screen and (max-width: map-get($breakpoints,medium)) {
              font-size: $font-size-h4-tablet;
            }
            @media screen and (max-width: map-get($breakpoints,small)) {
              font-size: $font-size-h4-mobile;
            }
          }
        }

        .input-group.checkbox.confirm-checkbox {
          .checkbox-label {
            .children {
              color: map-get(map-get($palette, primary), 2);
              font-weight: normal;
              font-size: $font-size-b2-desktop;
              line-height: $line-height;

              a {
                font-size: $font-size-b2-desktop;
              }
            }
          }
        }

        .linqto-bucks.input-group {
          .children {
            color: map-get(map-get($palette, primary), 2);
            font-size: $font-size-b1-tablet;
            @media screen and (max-width: map-get($breakpoints,medium)) {
              font-size: $font-size-b1-tablet;
            }
            @media screen and (max-width: map-get($breakpoints,small)) {
              font-size: $font-size-b1-mobile;
            }

            small {
              color: map-get(map-get($palette, secondary), 3);
              font-size: $font-size-b3-desktop;
            }
          }
        }

        .slider-btn {
          cursor: pointer;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          -webkit-tap-highlight-color: transparent;
          &:focus {
            outline: none;
          }
          img {
            width: 30px;
          }
        }
        .slider {
          margin: 0 2rem;
        }
      }
      .entity-form {
        min-height: 41px;
        label {
          font-size: $font-size-b1-tablet;
          line-height: $line-height;
        }
        .checkbox-label {
          span {
            margin-left: 0.5rem;
          }
        }
      }
    }

    .confirm-checkbox {
      margin-top: 0;
    }
  }
}

@media only screen and (max-width: map-get($breakpoints,small)) {
  .ui.stackable.grid > .column:not(.row),
  .ui.stackable.grid > .wide.column {
    padding: 0 !important;
  }
}

.render-location-dropdown {
  width: 100%;
  &.error {
    border: 1px solid $err-red;
  }
}

.render-birth-date-dropdown {
  height: 100%;
  width: 100%;
  &.error {
    border: 1px solid $err-red;
  }
}

.full-width {
  width: 100%;
}

.signup-error .modal-inner-content {
  min-height: 140px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  overflow: visible;
}

.ui.grid > .column.column-padding:not(.row),
.ui.grid > .row > .column.column-padding {
  padding-right: 0;
  padding-left: 0;
}

@media screen and (max-width: map-get($breakpoints, medium)) {
  .ui.grid > .eight.wide.column.tablet_sixteen {
    width: 100% !important;
  }

  .ui.grid > .four.wide.column.tablet_eight,
  .ui.grid > .row > .four.wide.column.tablet_eight,
  .ui.grid > .four.eight.column.tablet_eight,
  .ui.grid > .row > .eight.wide.column.tablet_eight {
    width: 50% !important;
  }
  .ui.grid > .row > .three.wide.column {
    width: 25% !important;
  }
}

@media screen and (max-width: map-get($breakpoints, small)) {
  .ui.grid > .four.wide.column.tablet_eight,
  .ui.grid > .six.wide.column.tablet_eight,
  .ui.grid > .eight.wide.column.tablet_eight {
    width: 100% !important;
  }

  .error-container {
    width: 100%;
    margin-left: 0;
  }
  .error.message {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
  .box .box-content {
    padding: 0px;
  }
  .box .box-title.top-border {
    padding-top: 15px;
  }

  .box .box-title {
    color: rgba(map-get(map-get($palette, primary), 2), 0.87);
    font-weight: $bold;
    font-size: $font-size-b1-desktop;
    @media screen and (max-width: map-get($breakpoints,medium)) {
      font-size: $font-size-b1-tablet;
    }
    @media screen and (max-width: map-get($breakpoints,small)) {
      font-size: $font-size-b1-mobile;
    }
  }
}

.contact-success-container {
  display: flex;
  flex-direction: column;
  .contact-success-header {
    & > h2 {
      font-weight: $semi-bold;
      font-size: $font-size-h1-desktop;
      line-height: $title-line-height;
      margin: 0 0 24px 0;
      width: 80%;
    }
    & > span {
      font-weight: $regular;
      font-size: $font-size-b1-desktop;
      color: map-get(map-get($palette, secondary), 2);
    }
    @media screen and (max-width: map-get($breakpoints,medium)) {
      & > h2 {
        width: 100%;
        font-size: $font-size-h1-tablet;
      }
      & > span {
        font-size: $font-size-b1-tablet;
      }
    }
    @media screen and (max-width: map-get($breakpoints,small)) {
      & > h2 {
        font-size: $font-size-h1-mobile;
      }
      & > span {
        font-size: $font-size-b1-mobile;
      }
    }
  }
  .contact-success-content {
    display: flex;
    margin: 24px 0;
    align-items: center;
    & > span {
      font-weight: $semi-bold;
      font-size: $font-size-h4-desktop;
      line-height: $title-line-height;
      color: map-get(map-get($palette, primary), 1);
      margin: 0 100px 0 0;
      cursor: pointer;
    }
    @media screen and (max-width: map-get($breakpoints,medium)) {
      & > span {
        font-size: $font-size-h4-tablet;
        margin: 0 50px 0 0;
      }
    }
    @media screen and (max-width: map-get($breakpoints,small)) {
      flex-direction: column-reverse;
      justify-content: center;
      text-align: center;
      & > span {
        margin: 10px 0;
        font-size: $font-size-h4-mobile;
      }
    }
  }
}

.shrink-container {
  min-height: 30vh;
}

.d-flex-col-50 {
  display: flex;
  flex-direction: column;
  width: 50%;
  @media screen and (max-width: map-get($breakpoints, small)) {
    width: 100%;
  }
}

.small-space {
  height: 16px;
}

.medium-space {
  height: 32px;
}

.inline-text-link {
  font-weight: $semi-bold;
  color: map-get(map-get($palette, primary), 1);
  cursor: pointer;
  display: inline-block;
  min-height: 48px;
  min-width: 48px;
}

.text-decoration-none {
  text-decoration: none;
}

.link {
  font-size: $btn-font-size-web;
  font-weight: $semi-bold;
  line-height: 48px;
  min-height: 48px;
  display: inline-block;
  vertical-align: middle;
  &.primary {
    color: map-get(map-get($palette, primary), 1);
  }
  &:hover {
    color: map-get(map-get($palette, secondary), 3);
  }
  @media screen and (max-width: map-get($breakpoints, small)) {
    display: block;
    width: 100%;
    text-align: center;
  }
}

.tablet-content,
.mobile-content {
  display: none !important;
}
.desktop-content {
  display: flex;
}
@media screen and (max-width: map-get($breakpoints, small)) {
  .tablet-content,
  .desktop-content {
    display: none;
  }
  .mobile-content {
    display: flex !important;
  }
}

@media screen and (max-width: map-get($breakpoints, medium)) {
  .mobile-content,
  .desktop-content {
    display: none;
  }
  .tablet-content {
    display: flex;
  }
}
.notification-banner {
  height: 48px;
  width: 100%;
  color: map-get(map-get($palette, primary), 3);
  background: radial-gradient(
    122.27% 198.92% at -22.27% -27.38%,
    map-get(map-get($palette, secondary), 5) 0%,
    map-get(map-get($palette, primary), 1) 100%
  );
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: $font-size-b1-desktop;
  @media screen and (max-width: map-get($breakpoints, medium)) {
    font-size: $font-size-b1-tablet;
  }
  @media screen and (max-width: map-get($breakpoints, small)) {
    font-size: $font-size-b1-mobile;
  }

  &.notification-banner-down {
    transform: translateY(-50px);
    animation: slideOutDown 1s 80ms;
    animation-fill-mode: forwards;
  }
  &.notification-banner-up {
    transform: translateY(0px);
    animation: slideOutUp 1s 80ms;
    animation-fill-mode: forwards;
  }
}

@keyframes slideOutDown {
  0% {
    transform: translateY(-50px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes slideOutUp {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(-50px);
  }
}

.uphold-container {
  padding: 0px 24px 24px;
}
.documents-list {
  margin-bottom: 32px;
  .upload-container-new {
    margin-bottom: 8px;
  }
}
.upload-container-new {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  .upload-err-msg {
    margin-top: -8px;
    line-height: $line-height;
    font-size: $font-size-input-desktop;
    font-weight: $semi-bold;
    color: $err-red;
    text-align: left;
  }
  .error-type {
    font-weight: $regular;
    color: map-get(map-get($palette, secondary), 2);
  }
  @media screen and (max-width: map-get($breakpoints, small)) {
    margin-bottom: 16px;
  }
}

.dropzone-new {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid map-get(map-get($palette, secondary), 3);
  width: 408px;
  border-radius: 8px;
  background-color: map-get(map-get($palette, primary), 3);
  height: 48px;
  padding-left: 16px;
  @media screen and (max-width: map-get($breakpoints, small)) {
    width: 100%;
  }
  &:hover,
  &.dragging {
    border: 1px solid map-get(map-get($palette, primary), 1);
  }
  .dropzone-text {
    color: map-get(map-get($palette, secondary), 3);
    font-size: $font-size-input-desktop;
    line-height: $line-height;
    cursor: default;
  }
  .browse-button {
    padding: 8px 16px;
    height: 100%;
    background-color: map-get(map-get($palette, secondary), 4);
    border: none;
    color: map-get(map-get($palette, secondary), 2);
    font-size: $font-size-input-desktop;
    border-top-right-radius: 8px;
    line-height: $line-height;
    border-bottom-right-radius: 8px;
    cursor: pointer;
  }
  .left-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 90%;
    .doc-title {
      padding: 0 16px;
      color: map-get(map-get($palette, secondary), 2);
      font-weight: $semi-bold;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .upload-img {
      width: 23px;
    }
  }
  .delete-icon {
    padding: auto 0;
    cursor: pointer;
    margin-right: 16px;
    svg {
      height: 100%;
      padding-top: 4px;
      stroke: map-get(map-get($palette, secondary), 3);
      &:hover {
        stroke: map-get(map-get($palette, primary), 1);
      }
    }
  }

  &.dropzone-loading {
    background-color: map-get(map-get($palette, secondary), 4);
    .doc-title {
      padding-left: 0;
      font-weight: $regular;
      color: map-get(map-get($palette, secondary), 3);
    }
  }
  &.dropzone-error {
    border: 1px solid map-get(map-get($palette, error), 1);
    .doc-title {
      padding-left: 0;
    }
  }
  &.click-to-view {
    &:hover {
      cursor: pointer;
    }
  }
}

.upload-progress-bar {
  height: 2px;
  background-color: map-get(map-get($palette, primary), 1);
}

hr {
  margin-top: 48px;
  margin-bottom: 48px;
}
label {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.tooltip {
  position: relative;
  display: inline-block;
}
.tooltip-icon {
  height: 13px;
  width: 13px;
  margin-left: 8px;
}
.tooltip-box {
  width: 256px;
  padding: 24px;
  background-color: map-get(map-get($palette, primary), 3);
  // position tool tip
  position: fixed;
  z-index: 1;
  color: map-get(map-get($palette, secondary), 2);
  line-height: $line-height;
  & > .tooltip-icon {
    position: absolute;
    top: 16px;
    right: 16px;
  }
}

.hidden {
  visibility: hidden;
}
.stackable-btns {
  display: flex;
  flex-direction: column;
  align-items: center;
  button:first-child {
    margin-top: 24px;
    @media screen and (max-width: map-get($breakpoints, small)) {
      margin-top: 16px;
    }
  }
  & > button {
    width: 100%;
    margin: 8px 0;
  }
}

.toggle-switch-container {
  display: flex;
  &-label {
    margin-right: 8px;
    color: map-get(map-get($palette, secondary), 2);
    font-size: $font-size-b1-desktop;
    @media screen and (max-width: map-get($breakpoints, small)) {
      font-size: $font-size-b1-mobile;
    }
  }
}

.toggle-switch {
  position: relative;
  height: 31px;
  width: 51px;
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-align: left;
  background-color: transparent;
  &-checkbox {
    display: none;
  }
  &-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06);
    border-radius: 20px;
    margin: 0;
  }
  &-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.2s ease-in 0s;
    &:before,
    &:after {
      display: block;
      float: left;
      width: 50%;
      height: 34px;
      padding: 0;
      line-height: 34px;
      font-size: 14px;
      color: white;
      font-weight: bold;
      box-sizing: border-box;
    }
    &:before {
      content: attr(data-yes);
      text-transform: uppercase;
      padding-left: 10px;
      background-color: map-get(map-get($palette, primary), 1);
      color: #fff;
    }
  }
  &-disabled {
    background-color: #ddd;
    cursor: not-allowed;
    &:before {
      background-color: #ddd;
      cursor: not-allowed;
    }
  }
  &-inner:after {
    content: attr(data-no);
    text-transform: uppercase;
    padding-right: 10px;
    background-color: map-get(map-get($palette, secondary), 3);
    color: #fff;
    text-align: right;
  }
  &-switch {
    display: block;
    height: 27px;
    width: 27px;
    margin: 3px;
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 20px;
    border: 0 solid #bbb;
    border-radius: 20px;
    transition: all 0.2s ease-in 0s;
  }
  &-checkbox:checked + &-label {
    .toggle-switch-inner {
      margin-left: 0;
    }
    .toggle-switch-switch {
      right: -1px;
    }
  }
  &.small-switch {
    width: 40px;
    .toggle-switch-inner {
      &:after,
      &:before {
        content: '';
        height: 20px;
        line-height: 20px;
      }
    }
    .toggle-switch-switch {
      width: 16px;
      right: 20px;
      margin: 2px;
    }
  }
  @media screen and (max-width: map-get($breakpoints, small)) {
    transform: scale(0.8);
  }
}

.teal-link {
  cursor: pointer;
  color: map-get(map-get($palette, primary), 1);
  font-weight: $semi-bold;
}
