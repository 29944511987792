.account-banner-container {
  background: map-get(map-get($palette, secondary), 4);
  border-left: 8px solid map-get(map-get($palette, primary), 1);
  padding: 16px;
  margin-top: -80px;

  .account-banner-header,
  .account-banner-body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .account-banner-body {
    margin-top: 8px;
    @media screen and (max-width: map-get($breakpoints,small)) {
      flex-direction: column;
    }
    a {
      font-weight: $semi-bold;
      color: map-get(map-get($palette, primary), 1);
      cursor: pointer;
      @media screen and (max-width: map-get($breakpoints,small)) {
        margin-left: auto;
      }
    }
    .account-banner-content {
      font-size: $font-size-b1-mobile;
      line-height: $line-height;
    }
  }

  .account-banner-title {
    font-size: $font-size-h3-mobile;
    font-weight: $semi-bold;
    line-height: $title-line-height;
  }

  .account-banner-close {
    width: 48px;
    height: 48px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -16px;
    margin-right: -16px;
  }
  @media screen and (max-width: map-get($breakpoints,small)) {
    margin-top: -48px;
  }
}
