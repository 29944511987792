.activate-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: auto;
  margin: auto;
  height: 60vh;
  img {
    height: 81px;
    width: 78px;
  }
  @media screen and (max-width: map-get($breakpoints, medium)) {
    width: 100%;
    img {
      height: 25px;
      width: 24px;
    }
  }
  .content {
    font-size: $font-size-b1-desktop;
    margin: 50px 0;
  }
}
