.signin {
  margin: 30px 25vw 150px;
  min-height: 70vh;
  @media screen and (max-width: map-get($breakpoints,large)) {
    margin: 30px 13vw 50px;
  }
  @media screen and (max-width: map-get($breakpoints,medium)) {
    margin: 30px 5vw;
    .signin-container {
      .signin-header {
        font-size: $font-size-h3-tablet;
      }
    }
  }
  @media screen and (max-width: map-get($breakpoints,small)) {
    margin: 30px 0px;
  }
  .signin-container {
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    margin: 0 auto;
    .signin-header {
      margin-bottom: 2rem;
      font-size: $font-size-h4-desktop;
      font-weight: $semi-bold;
      text-align: left;
      @media screen and (max-width: map-get($breakpoints, medium)) {
        font-size: $font-size-h4-tablet;
      }
      @media screen and (max-width: map-get($breakpoints, small)) {
        font-size: $font-size-h4-mobile;
      }
    }
    .reset-content {
      display: inline-block;
      font-size: $font-size-b2-desktop;
      cursor: pointer;
      text-decoration: underline;
      margin-top: 1rem;
      margin-left: 5px;
      &:hover {
        color: map-get(map-get($palette, secondary), 3);
      }
      @media screen and (max-width: map-get($breakpoints, medium)) {
        margin-top: 0.5rem;
      }
    }
    .ui.grid {
      @media screen and (max-width: map-get($breakpoints, small)) {
        margin-left: 0rem;
        margin-right: 0rem;
      }
    }
    @media screen and (max-width: map-get($breakpoints,small)) {
      padding: 20px 24px;
    }
  }
}

form.ui.form input {
  background-color: map-get(map-get($palette, primary), 3) !important;
  border: none !important;
  &:focus {
    border-color: map-get(map-get($palette, primary), 1) !important;
  }
}

.ui.form .field.field input:-webkit-autofill {
  background-color: map-get(map-get($palette, primary), 3) !important;
  &:focus {
    border-color: map-get(map-get($palette, primary), 1) !important;
    background-color: map-get(map-get($palette, primary), 3) !important;
  }
}

.reset-modal {
  .success-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    .success-icon {
      height: 30px;
    }
    p {
      padding: 10px;
    }
  }
}

.reset-password-modal {
  @media screen and (max-width: map-get($breakpoints,small)) {
    margin: 1rem 0 0 0;
  }
}

.reset-password-container {
  margin: 30px 25vw 150px;
  min-height: 50vh;
  padding-bottom: 100px;
  @media screen and (max-width: map-get($breakpoints,medium)) {
    margin: 30px 5vw 100px;
  }
  @media screen and (max-width: map-get($breakpoints,small)) {
    margin: 30px 10px;
  }
  form {
    display: flex;
    flex-direction: column;
    .modal-title {
      text-align: center;
    }
  }
  .reset-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    .reset-icon {
      height: 100px;
    }
    p {
      font-size: $font-size-b1-desktop;
      padding: 15px 0;
      @media screen and (max-width: map-get($breakpoints,medium)) {
        font-size: $font-size-b1-tablet;
      }
      @media screen and (max-width: map-get($breakpoints,small)) {
        font-size: $font-size-b1-mobile;
      }
    }
  }
}

.mfa-group {
  .item {
    padding: 1rem;
    border: 2px solid map-get(map-get($palette, primary), 1);
    border-radius: 20px;
    margin: 10px;
    cursor: pointer;
    font-size: $font-size-b1-desktop;
    @media screen and (max-width: map-get($breakpoints,medium)) {
      font-size: $font-size-b1-tablet;
    }
    @media screen and (max-width: map-get($breakpoints,small)) {
      font-size: $font-size-b1-mobile;
    }
    &:hover {
      background-color: map-get(map-get($palette, secondary), 4);
    }
  }
}
