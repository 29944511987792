.about-container {
  display: flex;
  flex-direction: column;
  .about-hero {
    width: 100%;
  }
  .top-group {
    display: flex;
    flex-direction: column;
    .header {
      font-size: $font-size-h1-desktop;
      font-weight: $semi-bold;
      line-height: $title-line-height;
      margin: auto 0;
      @media screen and (max-width: map-get($breakpoints,medium)) {
        font-size: $font-size-h1-tablet;
      }
      @media screen and (max-width: map-get($breakpoints,small)) {
        font-size: $font-size-h1-mobile;
      }
    }
    .sub-header {
      font-size: $font-size-h4-desktop;
      font-weight: $semi-bold;
      @media screen and (max-width: map-get($breakpoints,medium)) {
        font-size: $font-size-h4-tablet;
      }
      @media screen and (max-width: map-get($breakpoints,small)) {
        font-size: $font-size-h4-mobile;
      }
    }
  }
  .team-container {
    .team-nav {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin: 3rem 0;
      .item {
        color: map-get(map-get($palette, primary), 1);
        font-size: $font-size-b2-desktop;
        font-weight: $semi-bold;
        margin: 0 3rem;
        padding: 10px;
        letter-spacing: 0.2em;
        cursor: pointer;
        &.active {
          border-bottom: 3px solid map-get(map-get($palette, primary), 1);
        }
        @media screen and (max-width: map-get($breakpoints,medium)) {
          margin: 0 1rem;
          text-align: center;
        }
      }
    }
    .team-group {
      display: grid;
      // grid-template-columns: repeat(auto-fill,4fr);
      grid-template-columns: repeat(4, 1fr);
      column-gap: 10px;
      row-gap: 7rem;
      @media screen and (max-width: map-get($breakpoints,medium)) {
        grid-template-columns: repeat(3, 1fr);
        column-gap: 5px;
        row-gap: 7rem;
      }
      @media screen and (max-width: map-get($breakpoints,small)) {
        grid-template-columns: repeat(2, 1fr);
      }
      .item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .img-container {
          border-radius: 50%;
          width: 160px;
          height: 160px;
          @media screen and (max-width: map-get($breakpoints,medium)) {
            width: 120px;
            height: 120px;
          }
          .headshot {
            width: 100%;
            border-radius: 50%;
            cursor: pointer;
            filter: grayscale(100%);
          }
          &:hover {
            border: 5px solid map-get(map-get($palette, primary), 1);
          }
        }
        .name {
          font-size: $font-size-h4-desktop;
          color: map-get(map-get($palette, primary), 1);
          margin-top: 20px;
          text-align: center;
          @media screen and (max-width: map-get($breakpoints,medium)) {
            font-size: $font-size-h4-tablet;
          }
          @media screen and (max-width: map-get($breakpoints,small)) {
            font-size: $font-size-h4-mobile;
          }
        }
        .job-title {
          font-size: $font-size-b1-mobile;
          color: map-get(map-get($palette, secondary), 2);
          text-align: center;
          margin-top: 10px;
          @media screen and (max-width: map-get($breakpoints,medium)) {
            font-size: $font-size-b1-mobile;
          }
          @media screen and (max-width: map-get($breakpoints,small)) {
            font-size: $font-size-b1-mobile;
          }
        }
      }
    }
  }
  .content {
    color: map-get(map-get($palette, secondary), 2);
  }
  .about-video-container {
    padding: 96px 20.8%;
    .video-title {
      margin-bottom: 0;
    }
    .video-container {
      text-align: center;
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: 56.25%;
      .acc-video {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        @media screen and (max-width: map-get($breakpoints,medium)) {
          width: 100%;
        }
      }
    }
    .content {
      margin-top: 24px;
      margin-bottom: 61px;
    }
    @media screen and (max-width: map-get($breakpoints,medium)) {
      padding: 48px 0%;
      .content {
        margin-top: 22px;
        margin-bottom: 48px;
      }
    }
  }
  .about-card-container {
    background-color: map-get(map-get($palette, secondary), 4);
    padding-top: 70px;
    padding-bottom: 70px;
    .card-inner-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    @media screen and (max-width: map-get($breakpoints,medium)) {
      padding-top: 60px;
      padding-bottom: 60px;
      .card-inner-container {
        padding: 0px 40px;
      }
    }
    @media screen and (max-width: map-get($breakpoints,small)) {
      .card-inner-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0px 24px;
      }
    }
  }
  .leadership-container {
    padding-top: 96px;
    padding-bottom: 96px;
    .section-title {
      margin-bottom: 48px;
      font-weight: $semi-bold;
      font-size: $font-size-h3-desktop;
      &.director {
        margin-top: 64px;
        margin-bottom: 48px;
      }
    }
    .membercards-container {
      display: inline-flex;
      gap: 48px 32px;
      flex-wrap: wrap;
      @media screen and (max-width: map-get($breakpoints,small)) {
        justify-content: center;
        gap: 48px 20px;
        &.director {
          gap: 24px 20px;
        }
      }
      &.director {
        margin-bottom: 32px;
      }
      .membercard-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        max-width: 196px;
        position: relative;
        @media screen and (max-width: map-get($breakpoints, small)) {
          max-width: 45%;
        }
        .overlay {
          position: absolute;
          z-index: 100;
          width: 100%;
          padding-top: 98%;
          background-color: map-get(map-get($palette, secondary), 2);
          opacity: 0.15;
          border-radius: 50%;
          border: 1px solid black; /* Black background with opacity */
        }
        img {
          width: 100%;
          border-radius: 50%;
          z-index: 1;
          filter: grayscale(100%);
        }
        .name {
          font-weight: $semi-bold;
          align-content: center;
          line-height: $line-height;
          &:not(.director) {
            margin-top: 16px;
            text-align: center;
          }
        }
        .title {
          text-align: center;
          line-height: $line-height;
          &.director {
            margin-top: 0px;
          }
        }
        &.director {
          width: 200px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          @media screen and (max-width: map-get($breakpoints,small)) {
            width: 50%;
          }
        }
      }
    }
    @media screen and (max-width: map-get($breakpoints,medium)) {
      padding: 64px 40px;
    }
    @media screen and (max-width: map-get($breakpoints,small)) {
      padding: 64px 24px;
      .section-title {
        font-size: $font-size-h3-mobile;
      }
      .membercards-container .membercard-container .name {
        margin-top: 0;
      }
    }
  }
  .about-gic-container {
    background-color: map-get(map-get($palette, secondary), 4);
    padding-top: 96px;
    padding-bottom: 96px;
    .content {
      margin-top: 24px;
      margin-bottom: 32px;
    }
    @media screen and (max-width: map-get($breakpoints, small)) {
      padding-top: 64px;
      padding-bottom: 64px;
      .content {
        margin-top: 16px;
        margin-bottom: 16px;
      }
      .gic-image {
        margin-top: 40px;
      }
    }
  }
  .about-gic-inner-container,
  .about-hiring-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .half {
      width: 48%;
      img {
        &:not(.linkedin-icon) {
          width: 100%;
          max-width: 550px;
        }
        &.linkedin-icon {
          margin-left: 5px;
          cursor: pointer;
        }
      }
      .linkedin.inline-text-link {
        color: map-get(map-get($palette, secondary), 2);
        font-weight: $regular;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 5px;
      }
      .content.linkedin {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 24px;
        @media screen and (max-width: map-get($breakpoints, small)) {
          margin-bottom: 16px;
        }
      }
    }
    @media screen and (max-width: map-get($breakpoints, medium)) {
      padding: 0px 40px;
    }
    @media screen and (max-width: map-get($breakpoints, small)) {
      flex-direction: column;
      padding: 0px 24px;
      &.about-hiring-container {
        flex-direction: column-reverse;
      }
      .half {
        width: 100%;
      }
    }
  }
  .about-gic-inner-container {
    .section-title {
      font-size: $font-size-h2-desktop;
      line-height: $font-size-h2-desktop;
      font-weight: $semi-bold;
    }
    @media screen and (max-width: map-get($breakpoints, small)) {
      .section-title {
        font-size: $font-size-h2-mobile;
        line-height: $font-size-h2-mobile;
      }
    }
  }
  .about-hiring-container {
    padding-top: 96px;
    padding-bottom: 96px;
    .section-title {
      line-height: $line-height;
      font-size: $font-size-h2-desktop;
      font-weight: $semi-bold;
    }
    @media screen and (max-width: map-get($breakpoints, small)) {
      padding-top: 64px;
      padding-bottom: 64px;
      .section-title {
        font-size: $font-size-h2-mobile;
      }
      .content {
        margin-top: 0px;
      }
      .growing-img {
        margin-top: 16px;
      }
    }
  }
}

.bio-modal {
  &::-webkit-scrollbar-track {
    //full
    background-color: transparent;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    //bar
    opacity: 0;
    background-color: transparent;
    cursor: pointer;
  }

  &::-webkit-scrollbar {
    width: 1px;
  }
  @media screen and (max-width: map-get($breakpoints,small)) {
    padding: 10px;
    padding-right: 0;
  }
  .bio-label {
    background-color: map-get(map-get($palette, primary), 1) !important;
  }
  .top {
    display: flex;
    flex-direction: row;
    align-items: center;

    .headshot {
      width: 160px;
      border-radius: 50%;
      filter: grayscale(100%);
    }
    .right {
      margin-left: 2rem;
      text-align: left;
      .name {
        font-size: $font-size-h4-desktop;
        @media screen and (max-width: map-get($breakpoints,medium)) {
          font-size: $font-size-h4-tablet;
        }
        @media screen and (max-width: map-get($breakpoints,small)) {
          font-size: $font-size-h4-mobile;
        }
      }
      .job-title {
        margin-top: 10px;
        font-size: $font-size-b1-desktop;
        color: map-get(map-get($palette, secondary), 2);
        @media screen and (max-width: map-get($breakpoints,medium)) {
          font-size: $font-size-b1-tablet;
        }
        @media screen and (max-width: map-get($breakpoints,small)) {
          font-size: $font-size-b1-mobile;
        }
      }
    }
    @media screen and (max-width: map-get($breakpoints, small)) {
      flex-direction: column;
      .headshot {
        margin: 1rem 0;
      }
      .right {
        width: 100%;
        margin-left: 0;
        .name,
        .job-title {
          text-align: center;
        }
      }
    }
  }
  .bio {
    text-align: left;
    margin-top: 30px;
  }
}

.about-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 28%;
  .title {
    font-size: $font-size-h4-desktop;
    text-align: left;
    color: map-get(map-get($palette, primary), 2);
    padding: 16px 0;
    font-weight: $semi-bold;
  }
  .description {
    text-align: left;
    font-size: $font-size-b1-desktop;
    line-height: $line-height;
    color: map-get(map-get($palette, secondary), 2);
  }
  .card-img {
    height: 82px;
  }
  @media screen and (max-width: map-get($breakpoints, medium)) {
    padding: 0;
    .title {
      font-size: $font-size-h4-desktop;
      font-weight: $semi-bold;
    }
    .description {
      width: 100%;
    }
  }
  @media screen and (max-width: map-get($breakpoints, small)) {
    width: 100%;
    &:not(:last-child) {
      margin-bottom: 56px;
    }
    padding: 0;
    .card-img {
      height: 38px;
    }
    .title {
      font-size: $font-size-b1-mobile;
      font-weight: $semi-bold;
      padding: 16px 0 24px 0;
    }
    .description {
      font-size: $font-size-b1-mobile;
    }
  }
}
